import { makeStyles } from '@fluentui/react-components'

const useStyles = makeStyles({
  box: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
})

export function AppToolbarLogo(): JSX.Element {
  const s = useStyles()

  return (
    <div className={s.box}>
      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="14" fill="none">
        <path fill="#D0EBFF" d="M13 7a6 6 0 0 0-6 6h6V7Z" />
        <path fill="#208BE6" d="M13 1H7L1 13h6a6 6 0 0 1 6-6V1Z" />
        <path fill="#73C0FC" d="M13 1h6v12h-6z" />
        <path stroke="#000" d="M7 1h12M7 1 1 13M7 1h6m6 0v12m0-12h-6m6 12H1m18 0H7m12 0h-6M1 13h6m6-12v12m0-12v6m0 6V7m-6 6a6 6 0 0 1 6-6" />
      </svg>
    </div>
  )
}
