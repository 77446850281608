import type { ReactElement } from 'react'
import { track, useEditor } from 'tldraw'
import { isPredictSampleShape } from '../../../../predict/sample/shape'
import { PredictSampleTool } from '../../../../predict/sample/tool'
import { t } from '../../../../util/intl/t'
import { HelpCanvas } from '../../../card/canvas'
import { HelpCard } from '../../../card/card'
import { HelpText } from '../../../card/text'
import { HelpFireHeadStatic } from './head-static'
import { HelpFireHeadScale } from './scale'
import { HelpFireHeadTranslate } from './translate'

export const FireSampleHeadHelp = track((): ReactElement | null => {
  const editor = useEditor()

  const shouldShow = false
    || editor.getCurrentToolId() === PredictSampleTool.id
    || editor.getCurrentPageShapes().some(isPredictSampleShape)

  if (!shouldShow)
    return null

  return (
    <HelpCard>
      <HelpText>
        {t('predict.fire-help.1')}
      </HelpText>
      <HelpText>
        {t('predict.fire-help.2')}
      </HelpText>
      <HelpCanvas>
        <HelpFireHeadStatic />
        <HelpFireHeadScale />
        <HelpFireHeadTranslate />
      </HelpCanvas>
      <HelpText>
        {t('predict.fire-help.3')}
      </HelpText>
    </HelpCard>
  )
})
