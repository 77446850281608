import type { PredictFetchOutput } from './type'

export function mergePredictFetchOutput(
  outputs: PredictFetchOutput[],
): PredictFetchOutput {
  return outputs.reduce<PredictFetchOutput>((acc, curr) => ({
    attrs: { ...acc.attrs, ...curr.attrs },
    shapes: [...acc.shapes, ...curr.shapes],
    aiPredictionRemaining: acc.aiPredictionRemaining !== 0
      ? Math.min(acc.aiPredictionRemaining, curr.aiPredictionRemaining)
      : curr.aiPredictionRemaining,
  }), { attrs: {}, shapes: [], aiPredictionRemaining: Number.POSITIVE_INFINITY })
}
