import type { ReactElement, ReactNode } from 'react'
import { EditorShapeHighlight } from '../../../editor/shape/highlight'
import type { PieceCircleShape } from './shape'

export function PieceCircleComponent(props: {
  original: ReactNode
  circle: PieceCircleShape
}): ReactElement {
  const { original, circle } = props

  return (
    <EditorShapeHighlight shape={circle}>
      {original}
    </EditorShapeHighlight>
  )
}
