import { z } from 'zod'

export const editorDocumentMetaSchema = z.object({
  // Even though we always have "scale" defined before entering our app
  // (see "page/provider"), we still need to make it optional here,
  // because sync-ing scale to editor is a side effect (see "editor/scale"),
  // so initially document.meta.scale is expected to be undefined.
  scale: z.number().optional(),
})

export type EditorDocumentMeta = z.infer<typeof editorDocumentMetaSchema>
