import { toServerPiece } from '../annot/piece/server'
import { isPieceShape } from '../annot/piece/shape'
import { toServerSegment } from '../annot/segment/server'
import { isSegmentShape } from '../annot/segment/shape'
import type { AnnotShape } from '../annot/shape/shape'
import { isFirePieceEquip } from '../attr/field/equip/value'
import { ATTR_FIRE_PIPE_METADATA_KEY } from '../attr/field/fire-pipe/value'
import { toServerShape } from '../attr/field/shape/value'
import type { AttrRecord, AttrValue } from '../attr/state/context'
import type { InsulAttrRecord } from '../insul/context'
import { toInsulServer } from '../insul/server'
import { INSUL_ATTR_DEFAULT, type InsulAttrValue } from '../insul/value'
import type { AnnotationCreate, AnnotationData, Insulation, KeyValueString } from '../util/data/server'
import { ApiError, server } from '../util/data/server'
import { t } from '../util/intl/t'
import { getHeadStrict } from '../util/web/array'
import { groupBy } from '../util/web/object'
import { getStrict } from '../util/web/primitive'
import { PAGE_ID } from './state/id'

function toCreate(props: {
  attr: AttrValue
  insul: InsulAttrValue
  shapes: AnnotShape[]
}): AnnotationCreate {
  const { attr, shapes, insul } = props

  const head = getHeadStrict(shapes)

  // @TODO: Move to "attr"
  const partialData = {
    colorName: head.props.color,
    constructionArea: [attr.cArea],
    material: [attr.material1, attr.material2],
    shape: toServerShape(attr.shape),
    uuid: head.meta.group,
    // We have no plan to use these 2 fields in the near future
    attributes: undefined,
    materialExtra: undefined,
  } satisfies Partial<AnnotationData>

  const data: AnnotationData = (() => {
    switch (head.meta.annot) {
      case 'piece': {
        return {
          ...partialData,
          pieces: shapes.filter(isPieceShape).map(toServerPiece),
        }
      }
      case 'segment': {
        const firePipe: KeyValueString = {
          key: ATTR_FIRE_PIPE_METADATA_KEY,
          value: attr.firePipe,
        }
        return {
          ...partialData,
          lineSegments: shapes.filter(isSegmentShape).map(toServerSegment),
          metadata: { any: [firePipe] },
        }
      }
    }
  })()

  const insulation: Insulation | undefined = (() => {
    if (isFirePieceEquip(attr.equip))
      return undefined
    return toInsulServer({ client: insul, shapes }) ?? undefined
  })()

  // @TODO: Move to "attr"
  const partialCreate = {
    pageID: PAGE_ID,
    data,
    equipmentClass: attr.equip,
    equipmentType: attr.type,
    insulation,
    moduleID: attr.cModule,
    equipmentClassOther: '', // @TODO: Support "other" class
    equipmentMechanicalID: undefined, // @TODO: Support mechanical ID
  } satisfies Partial<AnnotationCreate>

  const create: AnnotationCreate = (() => {
    switch (head.meta.annot) {
      case 'piece':
        return { ...partialCreate, dataType: 'Pieces' }
      case 'segment':
        return { ...partialCreate, dataType: 'Pipes' }
    }
  })()

  return create
}

type Result = { status: 'ok' }
  | { status: 'failed', error: ApiError | string }

async function syncAnnotations(annots: AnnotationCreate[]): Promise<Result> {
  const sync = async () => {
    return server.syncAnnotations({
      annotations: annots,
      pageID: PAGE_ID,
    })
  }

  try {
    await sync()
    return { status: 'ok' }
  }
  catch (error) {
    await new Promise(resolve => setTimeout(resolve, 2000))
    try {
      await sync()
      return { status: 'ok' }
    }
    catch (error) {
      if (error instanceof ApiError)
        return { status: 'failed', error }
      return {
        status: 'failed',
        error: t('annot.sync.failed'),
      }
    }
  }
}

export async function savePageAnnots(props: {
  shapes: AnnotShape[]
  attrs: AttrRecord
  insuls: InsulAttrRecord
}): Promise<Result> {
  const { shapes: allShapes, attrs, insuls } = props

  const groups = groupBy({
    array: allShapes,
    getKey: shape => shape.meta.group,
  })

  const annots = Object
    .entries(groups)
    .map((entry) => {
      const [id, shapes] = entry
      const attr = getStrict(attrs[id])
      const insul = insuls[id] ?? INSUL_ATTR_DEFAULT
      return toCreate({ attr, insul, shapes })
    })
    .filter((annot): annot is AnnotationCreate => annot !== null)

  return syncAnnotations(annots)
}
