import { Button, Text, makeStyles, tokens } from '@fluentui/react-components'
import type { ReactElement } from 'react'
import { ApiError } from '../util/data/server'
import { t } from '../util/intl/t'

const WEB_DOMAIN = import.meta.env.VITE_WEB_DOMAIN

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    rowGap: tokens.spacingHorizontalXXL,
  },
  description: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
})

function ErrorDetail(props: { error: Error }): ReactElement {
  const { error } = props

  const s = useStyles()

  if (error instanceof ApiError) {
    switch (error.status) {
      case 401:
        return (
          <div className={s.container}>
            <div className={s.description}>
              <Text>{t('app.error.unauthorized-desc.1')}</Text>
              <Text>{t('app.error.unauthorized-desc.2')}</Text>
            </div>
            <Button appearance="primary" onClick={() => window.location.href = `//${WEB_DOMAIN}/sign-in`}>Login</Button>

          </div>
        )
      case 403:
        return (
          <div className={s.container}>
            <div className={s.description}>
              <Text>{t('app.error.forbidden-desc.1')}</Text>
              <Text>{t('app.error.forbidden-desc.2')}</Text>
            </div>
          </div>
        )
      case 429:
        return (
          <div className={s.container}>
            <div className={s.description}>
              <Text>{t('app.error.limit')}</Text>
            </div>
          </div>
        )
    }
  }
  return <Text>{error.message}</Text>
}

export function AppError(props: {
  error: Error
}): ReactElement {
  const { error } = props

  const s = useStyles()

  return (
    // Use AppFrame?
    <div className={s.container}>
      <ErrorDetail error={error} />
    </div>
  )
}
