export type Option<Value extends string = string> = {
  label: string
  value: Value
}

export type OptionGroup<Value extends string = string> = {
  label: string
  options: Option<Value>[]
}

export function toOptionLiteral(value: string): Option {
  return { label: value, value }
}
