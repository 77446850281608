import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import type { ZodSchema } from 'zod'

export function useDataLocal<Data extends object>(props: {
  queryKey: string[]
  localKey: string
  fallback: Data
  schema: ZodSchema<Data>
}): [
    Data,
    (update: Partial<Data>) => void,
  ] {
  const { queryKey, localKey, fallback, schema } = props

  const queryClient = useQueryClient()

  const query = useQuery({
    queryKey,
    queryFn: async (): Promise<Data> => {
      // Use try-catch instead of "safe parse"
      // because "JSON parse" could also throw an error
      try {
        const text = window.localStorage.getItem(localKey) ?? '{}'
        return schema.parse(JSON.parse(text))
      }
      catch {
        return fallback
      }
    },
  })

  const mutation = useMutation({
    mutationFn: async (update: Partial<Data>) => {
      if (query.data === undefined)
        throw new Error('Cannot mutate when data is not ready')
      const full: Data = { ...query.data, ...update }
      const text = JSON.stringify(full)
      window.localStorage.setItem(localKey, text)
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey })
    },
  })

  return [
    query.data ?? fallback,
    mutation.mutate,
  ]
}
