import { ToolbarButton } from '@fluentui/react-components'
import { Group20Regular } from '@fluentui/react-icons'
import type { TLShape } from 'tldraw'
import { track, useEditor } from 'tldraw'
import type { AttrRecord } from '../../attr/state/context'
import { useAttrs } from '../../attr/state/context'
import { useToast } from '../../ui/toast'
import { ToolbarTooltip } from '../../ui/toolbar/tooltip'
import { t } from '../../util/intl/t'
import { getStrict } from '../../util/web/primitive'
import { isPieceShape } from '../piece/shape'
import { isSegmentShape } from '../segment/shape'
import { randomAnnotShapeColor } from '../shape/color'
import type { AnnotShape } from '../shape/shape'
import { isAnnotShape } from '../shape/shape'

type ExtractProps = {
  attrs: AttrRecord
  shapes: AnnotShape[]
}

type ExtractResult = {
  shapes: TLShape[]
  attrs: AttrRecord
}

function extractGroup<Shape extends AnnotShape>(props: ExtractProps & {
  isShape: (shape: AnnotShape) => shape is Shape
}): ExtractResult {
  const { attrs, shapes: prevShapes, isShape } = props

  const nextGroup = crypto.randomUUID()
  const color = randomAnnotShapeColor()

  const nextShapes: Shape[] = prevShapes
    .filter(isShape)
    .map(shape => ({
      ...shape,
      props: { ...shape.props, color },
      meta: { ...shape.meta, group: nextGroup },
    }))

  // There are brief moments when a "group ID" is defined without its
  // corresponding attribute fields, for example, when a shape is created but
  // its `setAttrs` has not settled yet.
  //
  // However, when handling user events, it's expected that the attribute fields
  // are already settled.
  const prevGroup = getStrict(prevShapes.at(0)).meta.group
  const attr = getStrict(attrs[prevGroup])

  return {
    attrs: { [nextGroup]: attr },
    shapes: nextShapes,
  }
}

function extract(props: ExtractProps): ExtractResult {
  const pieces = extractGroup({ ...props, isShape: isPieceShape })
  const segments = extractGroup({ ...props, isShape: isSegmentShape })

  return {
    attrs: { ...pieces.attrs, ...segments.attrs },
    shapes: [...pieces.shapes, ...segments.shapes],
  }
}

export const AnnotToolbarExtract = track((): JSX.Element => {
  const { toast } = useToast()
  const editor = useEditor()
  const { attrs, setAttrs } = useAttrs()

  const fn = () => {
    const shapes = editor.getSelectedShapes().filter(isAnnotShape)
    if (shapes.length === 0)
      return void toast(t('annot.toolbar.extract-none'))

    const extracted = extract({ attrs, shapes })
    setAttrs(prev => ({ ...prev, ...extracted.attrs }))
    editor.mark()
    editor.updateShapes(extracted.shapes)
  }

  return (
    <ToolbarTooltip relationship="description" content={t('annot.toolbar.extract')}>
      <ToolbarButton
        icon={<Group20Regular />}
        onClick={fn}
      >
        {t('annot.toolbar.extract')}
      </ToolbarButton>
    </ToolbarTooltip>
  )
},
)
