import type { ToolbarProps } from '@fluentui/react-components'
import type { Editor } from 'tldraw'
import { z } from 'zod'

export function useEditorToolToolbar(props: {
  editor: Editor
}): ToolbarProps {
  const { editor } = props

  return {
    checkedValues: {
      tool: [editor.getCurrentToolId()],
    },
    onCheckedValueChange: (_event, data) => {
      z.literal('tool').parse(data.name)
      const tool = z.string().parse(data.checkedItems.at(0))
      editor.setCurrentTool(tool)
    },
  }
}
