import JsPdf from 'jspdf'
import type { Editor } from 'tldraw'
import type { Canvas } from '../../util/web/canvas'
import { getStrict } from '../../util/web/primitive'
import type { PagePdf } from '../provider/pdf'
import { printPageAnnots } from './annot'
import { printPageBackground } from './background'

export async function printPage(props: {
  editor: Editor
  background: PagePdf
}): Promise<void> {
  const { editor, background } = props

  const element = document.createElement('canvas')
  const context = getStrict(element.getContext('2d'))
  const canvas: Canvas = { element, context }

  // Must draw background before annots to:
  // - Set the correct size for the canvas, and
  // - Place annots on top of the background
  printPageBackground({ print: canvas.element, pdf: background })
  await printPageAnnots({ editor, canvas })

  const pdf = new JsPdf({
    orientation: element.width > element.height ? 'landscape' : 'portrait',
    format: [element.width, element.height],
    unit: 'pt',
    compress: true,
  })

  pdf.addImage({
    imageData: element,
    x: 0,
    y: 0,
    width: element.width,
    height: element.height,
    compression: 'MEDIUM',
    format: 'PNG',
  })

  pdf.save('AI Sekisan.pdf')
}
