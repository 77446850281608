import type { TLGeoShape, TLShape } from 'tldraw'
import type { EditorShapePartial } from '../../../editor/shape/base'
import type { PieceMetaBase } from '../shape'
import { isPieceShapeBase } from '../shape'

type Meta = PieceMetaBase & {
  shape: 'circle'
}

export type PieceCircleShape = TLGeoShape & { meta: Meta }

export type PieceCircleShapePartial = EditorShapePartial<TLGeoShape, Meta>

export function isPieceCircleShape(
  shape: TLShape,
): shape is PieceCircleShape {
  if (!isPieceShapeBase(shape))
    return false
  const test = shape as PieceCircleShape
  return test.meta.shape === 'circle'
}
