import { FluentProvider, Toaster, makeStyles, tokens, webDarkTheme, webLightTheme } from '@fluentui/react-components'
import type { ReactNode } from 'react'
import { useSetting } from '../setting/setting'

const useStyles = makeStyles({
  /**
   * IMPORTANT: Fluent wraps portal components inside a provider clone, which
   * has these styles as well. See:
   * - https://react.fluentui.dev/?path=/docs/components-fluentprovider--default#apply-styles-to-portals
   */
  provider: {
    // Global styles for others (including portals) to inherit.
    'userSelect': 'none',
    'scrollbarColor': `${tokens.colorNeutralStroke2} ${tokens.colorNeutralBackground2}`,
    // Typography
    'fontVariantNumeric': 'tabular-nums',
    // https://rsms.me/inter/#usage
    'fontFamily': 'InterVariable, sans-serif',
    'fontFeatureSettings': `"liga" 1, "calt" 1;`,
    // Apply to the provider at root only, not the providers at portal.
    // Note that the selector will automatically add " &", so it will become
    // "#root .something" correctly.
    ':global(#root)': {
      height: '100%',
    },
  },
})

/**
 * FluentProvider cannot be used directly inside App,
 * because it needs "theme" from Preferences,
 * which is inside App as well.
 */
export function WidgetProvider(props: {
  children: ReactNode
}): JSX.Element {
  const { children } = props

  const theme = useSetting().setting.theme
  const s = useStyles()

  return (
    <FluentProvider
      theme={theme === 'dark' ? webDarkTheme : webLightTheme}
      className={s.provider}
    >
      {/* Default Toaster for all toasts */}
      <Toaster
        position="top"
        // Move to top of editor, avoid hiding Toolbar.
        offset={{ top: { vertical: 100 } }}
      />
      {children}
    </FluentProvider>
  )
}
