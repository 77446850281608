import { Dropdown, Option } from '@fluentui/react-components'
import { Note16Filled } from '@fluentui/react-icons'
import type { ReactElement } from 'react'
import type { Editor, TLDefaultColorStyle } from 'tldraw'
import { track, useEditor } from 'tldraw'
import { isAnnotShape } from '../../../annot/shape/shape'
import { useEditorTheme } from '../../../editor/util/theme'
import { Field } from '../../../ui/field'
import type { AttrSelection } from '../../../util/attr/selection'
import { getAttrSelectionValueArray, getAttrSelectionValueWhen } from '../../../util/attr/selection'
import type { AttrTreeValue } from '../../../util/attr/tree/value'
import { t } from '../../../util/intl/t'
import { getStrict } from '../../../util/web/primitive'
import type { AttrTypeOption } from './value'
import { ATTR_TYPE_OPTIONS } from './value'

function TypeOption(props: { option: AttrTypeOption }): ReactElement {
  const { label, value, color: name } = props.option

  // The default value here does not matter.
  // We will not use "color" if "name" is null.
  // However, since this is a hook, we need to provide a value.
  const color = useEditorTheme()[name ?? 'black'].solid

  return (
    <Option
      value={value}
      style={{ color: name !== null ? color : undefined }}
    >
      {label}
    </Option>
  )
}

function setColor(props: {
  editor: Editor
  color: TLDefaultColorStyle | null
}): void {
  const { editor, color } = props

  if (color === null)
    return

  const groups = new Set(editor
    .getSelectedShapes()
    .filter(isAnnotShape)
    .map(annot => annot.meta.group))

  const shapes = editor
    .getCurrentPageShapes()
    .filter(isAnnotShape)
    .filter(annot => groups.has(annot.meta.group))

  const updates = shapes.map(shape => ({
    ...shape,
    props: { ...shape.props, color },
  }))

  editor.updateShapes(updates)
}

export const AttrTypeField = track((props: {
  equip: AttrTreeValue
  type: AttrSelection<string>
  setType: (type: string) => void
}): ReactElement | null => {
  const { equip, type: selection, setType } = props

  const editor = useEditor()

  const options = ATTR_TYPE_OPTIONS[equip] ?? []
  if (options.length === 0)
    return null

  return (
    <Field
      icon={icon => <Note16Filled className={icon.className} />}
      label={t('attr.field.type')}
    >
      {control => (
        <Dropdown
          value={getAttrSelectionValueWhen(selection, {
            mixed: t('attr.type.mixed'),
            same: value => options.find(o => o.value === value)?.label ?? '',
          })}
          selectedOptions={getAttrSelectionValueArray(selection)}
          onOptionSelect={(_event, data) => {
            const type = getStrict(data.optionValue)
            setType(type)
            const option = getStrict(options.find(o => o.value === type))
            setColor({ editor, color: option.color })
          }}
          className={control.className}
          appearance={control.appearance}
        >
          {options.map(option => (
            <TypeOption key={option.label} option={option} />
          ))}
        </Dropdown>
      )}
    </Field>
  )
})
