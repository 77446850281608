import type { ReactElement } from 'react'
import { getDefaultColorTheme } from 'tldraw'
import { getLineShapeEdgeRelative } from '../../../editor/shape/line'
import type { SegmentFlatShape } from './shape'

/**
 * @TODO: Use component instead? Previously tldraw uses SVGElement,
 * so we could not use component here because it uses ReactElement.
 * However, now both places use ReactElement.
 */
export function toSegmentFlatSvg(props: {
  flat: SegmentFlatShape
  original: ReactElement
  scale: number
}): ReactElement {
  const { original, scale, flat } = props

  const mm = scale * getLineShapeEdgeRelative(flat).length
  const m = (mm / 1000).toFixed(2)

  const colors = getDefaultColorTheme({ isDarkMode: false })
  const color = colors[flat.props.color]

  const center = getLineShapeEdgeRelative(flat).center

  return (
    <>
      {/* The original line in SVG */}
      {original}
      {/* Label */}
      <g>
        {/* tldraw supports SVG context to define "defs" but it's much more reliable
            to just use a simple rect than the filter. */}
        <rect
          x={`${center.x - 12}`}
          y={`${center.y - 4}`}
          fill="#fff"
          width="20px"
          height="10px"
        />
        {/* This is much better than trying to re-produce the canvas styling. */}
        <text
          fill={color.solid}
          x={`${center.x - 8}`}
          y={`${center.y + 4}`}
          fontFamily="Arial"
          fontSize="7px"
          strokeWidth="1px"
        >
          {m}
        </text>
      </g>
    </>
  )
}
