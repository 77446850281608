import type { ReactElement } from 'react'
import { SegmentLabelMenu } from '../../annot/segment/label/menu'
import { SegmentSizeMenu } from '../../annot/segment/size/menu'
import { SettingThemeToggle } from '../../setting/theme/toggle'
import { ToolbarSpace } from '../../ui/toolbar/space'

export function AppToolbarView(): ReactElement {
  return (
    <>
      <SegmentLabelMenu />
      <SegmentSizeMenu />
      <ToolbarSpace />
      <SettingThemeToggle />
    </>
  )
}
