import type { TLGeoShape, TLPointerEvent, TLShape } from 'tldraw'
import { Vec, createShapeId } from 'tldraw'
import type { AttrRecord } from '../../../attr/state/context'
import type { SetState } from '../../../util/react/state'
import type { GeoToAnnot } from '../../shape/geo'
import { AnnotGeoTool } from '../../shape/geo'
import type { AnnotShape } from '../../shape/shape'
import { SEGMENT_FLAT_TOOL_ID } from '../flat/tool'
import type { SegmentShape } from '../shape'
import { createSegmentAttr, isSegmentShape } from '../shape'
import { createSegmentVerticalShape } from '../vertical/create'
import { isSegmentVerticalShape } from '../vertical/shape'

export function createSegmentGeoTool(props: {
  id: string
  setAttrs: SetState<AttrRecord>
  toAnnot: GeoToAnnot
  direction?: 'down' | 'up'
}) {
  const { id, setAttrs, toAnnot, direction } = props

  return class SegmentGeoTool extends AnnotGeoTool {
    static override id = id

    override followTool: string = SEGMENT_FLAT_TOOL_ID

    prev: SegmentShape | null = null

    shape: TLShape | null = null

    override isPrev(shape: AnnotShape): boolean {
      return isSegmentShape(shape)
    }

    override createAttr(group: string): void {
      setAttrs(attrs => ({
        ...attrs,
        [group]: createSegmentAttr(),
      }))
    }

    override toAnnot = toAnnot

    override onPointerDown: TLPointerEvent = () => {
      this.prev = this.editor
        .getSelectedShapes()
        .filter(isSegmentVerticalShape)
        .at(0) ?? null

      const { currentPagePoint } = this.editor.inputs

      if (direction === undefined)
        return

      const id = createShapeId()
      const vertical = createSegmentVerticalShape({
        center: new Vec(currentPagePoint.x, currentPagePoint.y),
        direction,
        id,
        color: this.prev?.props.color ?? null,
        group: this.prev?.meta.group ?? null,
        mm: null,
      })

      this.editor.createShape(vertical)

      this.shape = this.editor.getShape(id)!
      this.editor.select(id)

      const annot = this.toAnnot({
        geo: this.shape as TLGeoShape,
        prev: null,
        prevProps: null,
      })

      this.createAttr(annot.meta.group)
      this.editor.updateShape(annot)

      this.editor.setCurrentTool('line-select', {
        from: 'line',
        onInteractionEnd: SEGMENT_FLAT_TOOL_ID,
      })
    }
  }
}
