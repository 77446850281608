import { ToolbarButton } from '@fluentui/react-components'
import { PenSparkle20Filled, PenSparkle20Regular } from '@fluentui/react-icons'
import type { ReactElement } from 'react'
import { track, useEditor } from 'tldraw'
import { useSetting } from '../../setting/setting'
import { t } from '../../util/intl/t'
import { PredictSampleTool } from './tool'

export const PredictSampleButton = track((props: {
  highlight: boolean
}): ReactElement => {
  const { highlight } = props

  const editor = useEditor()
  const { updateSetting } = useSetting()

  return (
    <ToolbarButton
      appearance={highlight
        ? 'primary'
        : 'subtle'}
      icon={highlight
        ? <PenSparkle20Filled />
        : <PenSparkle20Regular />}
      onClick={() => {
        updateSetting({ secondary: 'help' })
        editor.setCurrentTool(PredictSampleTool.id)
      }}
    >
      {t('predict.sample.select')}
    </ToolbarButton>
  )
})
