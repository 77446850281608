export function isPromiseFulfilled<Value>(
  result: PromiseSettledResult<Value>,
): result is PromiseFulfilledResult<Value> {
  return result.status === 'fulfilled'
}

export function isPromiseRejected<Value>(
  result: PromiseSettledResult<Value>,
): result is PromiseRejectedResult {
  return result.status === 'rejected'
}
