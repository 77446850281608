import { PAGE_ID } from '../../page/state/id'
import type { PdfCache } from '../../store/pdf'
import { addItemInPdfCache, getItemInPdfCache, updateItemInPdfCache } from '../../store/pdf'
import { server } from '../../util/data/server'

async function imageUrlToBase64(url: string): Promise<string> {
  try {
    const response = await fetch(url)
    if (!response.ok)
      throw new Error('Failed to fetch PDF from URL')

    const blob = await response.blob()
    const reader = new FileReader()

    return new Promise((resolve, reject) => {
      reader.onloadend = () => resolve(reader.result as string)
      reader.onerror = reject
      reader.readAsDataURL(blob)
    })
  }
  catch (error) {
    console.error('Failed to fetch and convert image:', error)
    throw error
  }
}

async function createPdfStore(signedURL: string, updatedAt: string): Promise<PdfCache> {
  const fileBase64 = await imageUrlToBase64(signedURL)
  const expiresAt = new Date()
  expiresAt.setUTCDate(new Date().getUTCDate() + 1)
  return { id: PAGE_ID, fileBase64, lastUpdated: updatedAt, expiresAt: expiresAt.toISOString() }
}

function isUpdateRequired(exist: PdfCache, updatedAt: string) {
  return exist.lastUpdated !== updatedAt || new Date(exist.expiresAt) < new Date()
}

type PdfCacheStatus = 'add' | 'update' | 'reuse'

export async function getPdfCacheResult(): Promise<{ pdf: PdfCache, status: PdfCacheStatus }> {
  const exist = await getItemInPdfCache()
  const omitPDF = exist !== undefined

  const { signedURL, updatedAt } = await server.getPageDetail(PAGE_ID, omitPDF)

  if (exist === undefined) {
    const pdf = await createPdfStore(signedURL, updatedAt)
    return { pdf, status: 'add' }
  }
  else if (isUpdateRequired(exist, updatedAt)) {
    const { signedURL: url, updatedAt: lastUpdated } = await server.getPageDetail(PAGE_ID, false)
    const newPdf = await createPdfStore(url, lastUpdated)
    return { pdf: newPdf, status: 'update' }
  }
  return { pdf: exist, status: 'reuse' }
}

export async function storePdfCache(props: { pdf: PdfCache, status: PdfCacheStatus }) {
  const { pdf, status } = props
  switch (status) {
    case 'add':
      await addItemInPdfCache(pdf)
      break
    case 'update':
      await updateItemInPdfCache(pdf)
      break
    case 'reuse':
      break
  }
}
