import type { MenuButtonProps } from '@fluentui/react-components'
import { Menu, MenuButton, MenuDivider, MenuGroup, MenuGroupHeader, MenuItemRadio, MenuList, MenuPopover, MenuTrigger } from '@fluentui/react-components'
import { ChevronDown12Filled } from '@fluentui/react-icons'
import { Fragment, type ReactElement } from 'react'
import { z } from 'zod'
import { getStrict } from '../util/web/primitive'
import type { Option, OptionGroup } from './option'

const nameSchema = z.literal('value')

type BaseProps<Value extends string> = {
  value: Value | null
  setValue: (value: Value) => void
  /** We could tie "display fallback" to "value", but it's not worth the effort.  */
  displayFallback: string | null
  parse: (value: unknown) => Value
  button: MenuButtonProps
}

type OptionProps<Value extends string> =
  | { options: Option<Value>[], groups?: never }
  | { options?: never, groups: OptionGroup<Value>[] }

type Props<Value extends string> = BaseProps<Value> & OptionProps<Value>

export function MenuRadio<Value extends string>(
  props: Props<Value>,
): ReactElement {
  const { value: current, setValue } = props
  const { parse, button, displayFallback } = props
  const { options: maybeOptions, groups: maybeGroups } = props

  const options = maybeOptions !== undefined
    ? maybeOptions
    : maybeGroups.flatMap(group => group.options)

  const toItem = (option: Option<Value>): ReactElement => (
    <MenuItemRadio key={option.value} name="value" value={option.value}>
      {option.label}
    </MenuItemRadio>
  )

  const toGroup = (group: OptionGroup<Value>, index: number): ReactElement => (
    <Fragment key={index}>
      {index !== 0 && <MenuDivider />}
      <MenuGroup>
        <MenuGroupHeader>{group.label}</MenuGroupHeader>
      </MenuGroup>
      {group.options.map(toItem)}
    </Fragment>
  )

  return (
    <Menu
      checkedValues={{ value: current !== null ? [current] : [] }}
      onCheckedValueChange={(_event, data) => {
        nameSchema.parse(data.name)
        const next = parse(data.checkedItems.at(0))
        setValue(next)
      }}
      hasCheckmarks
    >
      <MenuTrigger disableButtonEnhancement>
        <MenuButton menuIcon={<ChevronDown12Filled />} {...button}>
          {current === null
            ? displayFallback
            : getStrict(options.find(option => option.value === current)).label}
        </MenuButton>
      </MenuTrigger>
      <MenuPopover>
        <MenuList>
          {maybeOptions !== undefined
            ? maybeOptions.map(toItem)
            : maybeGroups.map(toGroup)}
        </MenuList>
      </MenuPopover>
    </Menu>
  )
}
