import { ToolbarButton } from '@fluentui/react-components'
import { DataSunburst20Filled } from '@fluentui/react-icons'
import { DataSunburst20Regular } from '@fluentui/react-icons/fonts'
import type { ReactElement } from 'react'
import { track, useEditor } from 'tldraw'
import { PIECE_CIRCLE_TOOL_ID } from '../../annot/piece/circle/tool'
import { useSetting } from '../../setting/setting'
import { t } from '../../util/intl/t'

export const AdditionalShapeButton = track((props: {
  highlight: boolean
}): ReactElement => {
  const { highlight } = props

  const editor = useEditor()
  const { updateSetting } = useSetting()

  return (
    <ToolbarButton
      appearance={highlight
        ? 'primary'
        : 'subtle'}
      icon={highlight
        ? <DataSunburst20Filled />
        : <DataSunburst20Regular />}
      onClick={() => {
        updateSetting({ secondary: 'help' })
        editor.setCurrentTool(PIECE_CIRCLE_TOOL_ID)
      }}
    >
      {t('predict.button.add-water-source')}
    </ToolbarButton>
  )
})
