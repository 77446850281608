import { Text } from '@fluentui/react-components'
import type { ReactNode } from 'react'

export function HelpText(props: {
  children: ReactNode
}): JSX.Element {
  const { children } = props

  return <Text size={200}>{children}</Text>
}
