import { EditorMain } from '../editor/main'
import { AppFrame } from './frame'
import { AppPrimary } from './panel/primary'
import { AppSecondary } from './panel/secondary'
import { AppToolbar } from './toolbar/toolbar'

export function AppReady(): JSX.Element {
  return (
    <AppFrame
      toolbar={<AppToolbar />}
      primary={<AppPrimary />}
      editor={<EditorMain />}
      secondary={<AppSecondary />}
    />
  )
}
