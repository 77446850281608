import { makeStyles, tokens } from '@fluentui/react-components'

const useStyles = makeStyles({
  line: {
    stroke: tokens.colorNeutralForeground1,
  },
})

export function HelpPolylineStatic(): JSX.Element {
  const s = useStyles()

  return (
    <svg width="150" height="100" viewBox="0 0 150 100" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M25 75H75V25H125" strokeWidth="2" className={s.line} />
    </svg>
  )
}
