import { Divider, Popover, PopoverSurface, PopoverTrigger, ToolbarButton, makeStyles, shorthands, tokens } from '@fluentui/react-components'
import { Building16Filled, DocumentFolder16Filled, DocumentFolder20Regular, Eye16Filled, LayerDiagonal16Filled, Wand16Filled } from '@fluentui/react-icons'
import { useQuery } from '@tanstack/react-query'
import type { ReactElement } from 'react'
import { Field } from '../../ui/field'
import { q } from '../../util/data/query'
import { server } from '../../util/data/server'
import { t } from '../../util/intl/t'
import { usePage } from '../state/context'

const useStyles = makeStyles({
  content: {
    display: 'flex',
    flexDirection: 'column',
    ...(shorthands.gap(tokens.spacingVerticalM)),
  },
})

export function PageToolbarOverview(): ReactElement {
  const { page, limit } = usePage()

  const category = useQuery({
    queryKey: ['category'],
    queryFn: async () => await server.getCategoryDetail(page.categoryID),
  })

  const construction = useQuery({
    queryKey: ['construction'],
    queryFn: async () => await server.getConstruction(page.constructionID),
  })

  const s = useStyles()

  return (
    <Popover positioning="below-start">
      <PopoverTrigger disableButtonEnhancement>
        <ToolbarButton
          icon={<DocumentFolder20Regular />}
        >
          {t('page.info.prefix')}
          {q(category, c => c.name)}
        </ToolbarButton>
      </PopoverTrigger>
      <PopoverSurface tabIndex={-1}>
        <div className={s.content}>
          <Field
            label={t('page.field.construction')}
            icon={icon => <Building16Filled className={icon.className} />}
          >
            {q(construction, c => c.name)}
          </Field>
          <Field
            label={t('page.field.category')}
            icon={icon => <DocumentFolder16Filled className={icon.className} />}
          >
            {q(category, c => c.name)}
          </Field>
          <Field
            label={t('page.field.name')}
            icon={icon => <LayerDiagonal16Filled className={icon.className} />}
          >
            {page.name}
          </Field>
          <Divider />
          <Field
            label={t('page.field.predict-limit')}
            icon={icon => <Wand16Filled className={icon.className} />}
          >
            {limit.aiPredictionRemaining}
            {t('page.limit.remaining')}
          </Field>
          <Field
            label={t('page.field.view-limit')}
            icon={icon => <Eye16Filled className={icon.className} />}
          >
            {page.downloadRemaining}
            {t('page.limit.remaining')}
          </Field>
        </div>
      </PopoverSurface>
    </Popover>
  )
}
