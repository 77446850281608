import {
  Button,
  Text,
  makeStyles,
  mergeClasses,
  shorthands,
  tokens,
} from '@fluentui/react-components'
import { Eye16Regular, EyeOff16Regular } from '@fluentui/react-icons'
import type { ReactElement } from 'react'
import { track, useEditor } from 'tldraw'
import { usePage } from '../../page/state/context'
import { t } from '../../util/intl/t'
import { isAnnotShape } from '../shape/shape'

const useStyles = makeStyles({
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    ...shorthands.padding(tokens.spacingVerticalXS, 0),
    ...shorthands.borderBottom('solid', '1px', tokens.colorNeutralStroke1),
    alignItems: 'center',
  },
  title: {
    ...shorthands.padding(tokens.spacingHorizontalS, 0),
  },
  noScale: {
    marginBottom: tokens.spacingVerticalS,
  },
})

export const AnnotPanelMenu = track((): ReactElement => {
  const s = useStyles()
  const editor = useEditor()
  const { scale } = usePage().page

  const all = editor.getCurrentPageShapes().filter(isAnnotShape)
  const selectedGroups = new Set(editor.getSelectedShapes().filter(isAnnotShape).map(shape => shape.meta.group))
  const isSelectionMode = selectedGroups.size > 0

  return (
    <div
      className={mergeClasses(s.container, scale === 0 && s.noScale)}
    >
      <Text
        size={200}
        className={s.title}
        weight="semibold"
      >
        {t('annot.panel.action.title')}
      </Text>
      {/* <Button
        appearance="subtle"
        size="small"
        icon={isSelectionMode ? <LockClosed16Regular /> : <LockOpen16Regular />}
        onClick={() => {
          editor.mark()
          if (isSelectionMode) {
            editor.updateShapes(all
              .filter(shape => selectedGroups.has(shape.meta.group))
              .map(shape => ({
                ...shape,
                isLocked: true,
              })),
            )
          }
          else {
            editor.updateShapes(all
              .map(shape => ({
                ...shape,
                isLocked: false,
              })),
            )
          }
        }}
      >
        {isSelectionMode ? t('annot.panel.selection.lock') : t('annot.panel.all.unlock')}
      </Button> */}
      <Button
        appearance="subtle"
        size="small"
        icon={isSelectionMode ? <EyeOff16Regular /> : <Eye16Regular />}
        onClick={() => {
          editor.mark()
          if (isSelectionMode) {
            editor.updateShapes(all
              .filter(shape => selectedGroups.has(shape.meta.group))
              .map(shape => ({
                ...shape,
                isLocked: true,
                opacity: 0,
              })),
            )
          }
          else {
            editor.updateShapes(all
              .map(shape => ({
                ...shape,
                isLocked: false,
                opacity: 1,
              })),
            )
          }
        }}
      >
        {isSelectionMode ? t('annot.panel.selection.hide') : t('annot.panel.all.show')}
      </Button>
    </div>
  )
})
