import { Card, CardHeader, CardPreview, makeStyles, mergeClasses, shorthands, tokens } from '@fluentui/react-components'
import type { ReactElement } from 'react'
import { useEffect, useRef } from 'react'
import type { PageDetail } from '../../util/data/server'
import { getStrict } from '../../util/web/primitive'

const useStyles = makeStyles({
  a: {
    display: 'block',
    ...(shorthands).textDecoration('none'),
  },
  card: {
    backgroundColor: tokens.colorNeutralBackground1,
  },
  img: {
    width: '100%',
    height: 'auto',
    aspectRatio: '4 / 3',
  },
  selected: {
    'boxShadow': tokens.shadow16,
    'color': tokens.colorBrandBackground,
    'fontWeight': '500',
    ':hover': {
      boxShadow: tokens.shadow16,
    },
  },
})

export function PageListItem(props: {
  page: PageDetail
  index: number
  selected: boolean
}): ReactElement {
  const { page, selected, index } = props

  const ref = useRef<HTMLAnchorElement>(null)
  const s = useStyles()

  useEffect(() => {
    // We don't need to wait for the images to load,
    // thank to the aspect ratio CSS.
    if (selected)
      getStrict(ref.current).scrollIntoView()
  }, [selected])

  return (
    <a ref={ref} className={s.a} href={`/?page=${page.id}`}>
      <Card
        className={selected ? mergeClasses(s.card, s.selected) : s.card}
        appearance={selected ? 'filled' : 'outline'}
        selected={selected}
        size="small"
      >
        <CardPreview>
          <img className={s.img} src={page.thumbnailSignedURL} alt={page.name} />
        </CardPreview>
        <CardHeader
          header={page.name}
          action={`#${index + 1}`}
        />
      </Card>
    </a>
  )
}
