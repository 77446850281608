import { Menu, MenuButton, MenuDivider, MenuItem, MenuItemRadio, MenuList, MenuPopover, MenuTrigger } from '@fluentui/react-components'
import { ChevronDown12Filled, Ruler20Regular } from '@fluentui/react-icons'
import type { ReactElement } from 'react'
import { track, useEditor } from 'tldraw'
import { z } from 'zod'
import { useSetting } from '../../setting/setting'
import { t } from '../../util/intl/t'
import { ScaleTool } from './tool'

const Adjust = track((props: {
  adjust: () => void
}): JSX.Element | null => {
  const { adjust } = props

  const editor = useEditor()
  const { updateSetting } = useSetting()

  return (
    <>
      <MenuDivider />
      <MenuItem
        onClick={() => {
          editor.setCurrentTool(ScaleTool.id)
          updateSetting({ secondary: 'help' })
        }}
      >
        {t('scale.menu.measure')}
      </MenuItem>
      <MenuItem onClick={() => void adjust()}>
        {t('scale.menu.adjust')}
      </MenuItem>
    </>
  )
})

export function ScaleMenu(props: {
  display: number
  setDisplay: (display: number) => void
  adjust: false | (() => void)
  pending: boolean
}): ReactElement {
  const { adjust, display, setDisplay, pending } = props

  const text = String(display)

  return (
    <Menu
      checkedValues={{ display: [text] }}
      onCheckedValueChange={(_event, data): void => {
        z.literal('display').parse(data.name)
        const unknown = data.checkedItems.at(0)
        const string = z.string().min(1).parse(unknown)
        const number = z.coerce.number().parse(string)
        setDisplay(number)
      }}
      hasCheckmarks
    >
      <MenuTrigger disableButtonEnhancement>
        <MenuButton
          menuIcon={<ChevronDown12Filled />}
          appearance="subtle"
          icon={<Ruler20Regular />}
          disabled={pending}
        >
          {display === 0
            ? `S=${t('scale.menu.not-set')}`
            : `S=1/${text}`}
        </MenuButton>
      </MenuTrigger>
      <MenuPopover>
        <MenuList>
          {[500, 200, 100, 50, 20, 10].map(option => (
            <MenuItemRadio
              key={option}
              name="display"
              value={String(option)}
            >
              {`1/${option}`}
            </MenuItemRadio>
          ))}
          {adjust ? <Adjust adjust={adjust} /> : null}
        </MenuList>
      </MenuPopover>
    </Menu>
  )
}
