import { Menu, MenuButton, MenuItem, MenuList, MenuPopover, MenuTrigger, makeStyles } from '@fluentui/react-components'
import type { FluentIcon } from '@fluentui/react-icons'
import { ChevronDown12Filled } from '@fluentui/react-icons'
import { track, useEditor } from 'tldraw'
import { useAttrEquipOptions } from '../../../attr/field/equip/value'
import { useAttrs } from '../../../attr/state/context'
import { useEditorTheme } from '../../../editor/util/theme'
import { locale } from '../../../util/intl/locale/type'
import { getHeadStrict } from '../../../util/web/array'
import { isPieceBoxShape } from '../../piece/box/shape'
import { isPieceCircleShape } from '../../piece/circle/shape'
import { isSegmentShape } from '../../segment/shape'
import type { AnnotShapeColorOption } from '../../shape/color'
import { ANNOT_SHAPE_COLOR_OPTIONS } from '../../shape/color'
import { ANNOT_ICONS, type AnnotShape } from '../../shape/shape'

const useStyles = makeStyles({
  button: {
    fontWeight: '700',
    textAlign: 'left',
  },
})

const ColorOption = track((props: {
  shapes: AnnotShape[]
  option: AnnotShapeColorOption
  Icon: FluentIcon
}): JSX.Element => {
  const { option, shapes, Icon } = props

  const editor = useEditor()
  const color = useEditorTheme()[option.value].solid

  return (
    <MenuItem
      key={option.value}
      icon={<Icon style={{ color }} />}
      style={{ color }}
      onClick={() => {
        const updates = shapes.map(shape => ({
          ...shape,
          props: { ...shape.props, color: option.value },
        }))
        editor.updateShapes(updates)
      }}
    >
      {option.label}
    </MenuItem>
  )
})

function getIcon(shape: AnnotShape): FluentIcon {
  if (isSegmentShape(shape))
    return ANNOT_ICONS.segment

  if (isPieceBoxShape(shape))
    return ANNOT_ICONS.piece.box

  if (isPieceCircleShape(shape))
    return ANNOT_ICONS.piece.circle

  return ANNOT_ICONS.piece['check-box']
}

export function AnnotOverviewTitle(props: {
  shapes: AnnotShape[]
}): JSX.Element {
  const { shapes } = props

  const { attrs } = useAttrs()
  const equips = useAttrEquipOptions()

  const s = useStyles()

  const head = getHeadStrict(shapes)
  const equip = attrs[head.meta.group]?.equip ?? null
  const color = useEditorTheme()[head.props.color].solid
  const Icon = getIcon(head)

  // The handling here is relaxed because it's expected that several parts are
  // loading:
  // - The corresponding attribute may not be available yet during some brief
  //   moments at rendering.
  // - Equipment options may not be loaded yet.
  const label = equips
    .find((option) => {
      return true
        && option.equipmentClass === equip
        && option.locale === locale
    })
    // Falling back to an empty string instead of something like "not set"
    // because the label is only unavailable during loading.
    ?.localed ?? ''

  return (
    <Menu>
      <MenuTrigger disableButtonEnhancement>
        <MenuButton
          icon={<Icon style={{ color }} />}
          menuIcon={<ChevronDown12Filled />}
          appearance="subtle"
          size="small"
          style={{ color }}
          className={s.button}
        >
          {label}
        </MenuButton>
      </MenuTrigger>
      <MenuPopover>
        <MenuList>
          {ANNOT_SHAPE_COLOR_OPTIONS.map(option => (
            <ColorOption
              key={option.value}
              shapes={shapes}
              option={option}
              Icon={Icon}
            />
          ))}
        </MenuList>
      </MenuPopover>
    </Menu>
  )
}
