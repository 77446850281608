import { MessageBar, MessageBarBody, MessageBarTitle } from '@fluentui/react-components'
import type { ReactElement } from 'react'
import { usePage } from '../../page/state/context'
import { t } from '../../util/intl/t'

export function AnnotPanelWarning(): ReactElement | null {
  const { scale } = usePage().page

  if (scale === 0) {
    return (
      <MessageBar intent="warning" layout="multiline">
        <MessageBarBody>
          <MessageBarTitle>
            {t('annot.warning.length-title')}
          </MessageBarTitle>
          {t('annot.warning.length-body')}
        </MessageBarBody>
      </MessageBar>
    )
  }

  return null
}
