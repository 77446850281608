import type { TLGeoShape, TLShape } from 'tldraw'
import type { EditorShapePartial } from '../../../editor/shape/base'
import type { PieceMetaBase } from '../shape'
import { isPieceShapeBase } from '../shape'

type Meta = PieceMetaBase & {
  shape: 'check-box'
}

export type PieceCheckShape = TLGeoShape & { meta: Meta }

export type PieceCheckShapePartial = EditorShapePartial<TLGeoShape, Meta>

export function isPieceCheckShape(
  shape: TLShape,
): shape is PieceCheckShape {
  if (!isPieceShapeBase(shape))
    return false
  const test = shape as PieceCheckShape
  return test.meta.shape === 'check-box'
}
