import { ATTR_EQUIP_VALUES } from '../../attr/field/equip/value'
import type { AIOutputDRPipeline, AIOutputLabeledBox, AIOutputSanitaryPipeline } from '../../util/data/server'
import { getMostItem } from '../../util/web/array'
import type { FamilyFriendlyString } from '../../util/web/primitive'

type ServerStringType =
  | AIOutputSanitaryPipeline['type']
  | AIOutputDRPipeline['type']
  | FamilyFriendlyString

/**
 * Map AI's equipment names (string) to our equipment class IDs (number).
 * The IDs are hard-coded,
 * and could be found in "server.listEquipClass" or "attr/equip".
 */
export function parsePredictEquip(
  /**
   * Equipment names come from 2 places in AI, either from the "type" field
   * (enum) of the pipeline, or from the "equipment_class" (string) of the box.
   * Note that a pipeline may not have a "type" field, in which case we will
   * look at the pipeline's "diameters" (box) field.
   */
  raw: ServerStringType | AIOutputLabeledBox[],
): number | null {
  const text: ServerStringType
    = typeof raw === 'string'
      ? raw // The pipeline's "type" field
      : raw.length === 0 // The box's "equipment_class" field
        ? ''
        : getMostItem(raw.map(d => d.label?.equipment_class ?? ''))

  if (text === '')
    return null

  switch (text) {
    case 'Duct':
      return ATTR_EQUIP_VALUES.ROUND_DUCT
    case 'VentCap':
      return ATTR_EQUIP_VALUES.VENT_CAP
    case 'Refrigerant':
      return ATTR_EQUIP_VALUES.REFRIGERANT_PIPE
    case 'DR':
      return ATTR_EQUIP_VALUES.DRAIN_PIPE
    case 'Drain':
      return ATTR_EQUIP_VALUES.DRAIN_PIPE
    case 'Sanitary':
      return ATTR_EQUIP_VALUES.SANITARY_PIPE
    case 'Tatekan':
      return ATTR_EQUIP_VALUES.SANITARY_PIPE
    default:
      return null
  }
}
