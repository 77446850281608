import type { ReactElement } from 'react'
import { track, useEditor } from 'tldraw'
import { isPieceCircleShape } from '../annot/piece/circle/shape'
import { useSetting } from '../setting/setting'
import { ToolbarArrow } from '../ui/toolbar/arrow'
import { AdditionalShapeButton } from './additional-shape/button'
import { PredictAreaButton } from './area/button'
import { isPredictAreaShape } from './area/shape'
import { PredictFetchButton } from './fetch/button'
import { PREDICT_MODELS } from './model/option'
import { PredictModelSelect } from './model/select'
import { PredictSampleButton } from './sample/button'
import { isPredictSampleShape } from './sample/shape'

type Step = 'model' | 'sample' | 'additional_shape' | 'area' | 'fetch'

export const PredictToolbar = track((): ReactElement => {
  const editor = useEditor()

  const model = useSetting().setting.predictModel
  const detail = PREDICT_MODELS.find(m => m.value === model) ?? null

  const step: Step = (() => {
    if (model === null)
      return 'model'

    if (detail === null)
      throw new Error(`Invalid predict model value: ${model}`)

    const shapes = editor.getCurrentPageShapes()
    if (detail.sample && !shapes.some(isPredictSampleShape))
      return 'sample'

    if (detail.additionalShape && !shapes.some(isPieceCircleShape))
      return 'additional_shape'

    if (!shapes.some(isPredictAreaShape))
      return 'area'

    return 'fetch'
  })()

  return (
    <>
      <PredictModelSelect highlight={step === 'model'} />
      {detail?.sample && (
        <>
          <ToolbarArrow />
          <PredictSampleButton highlight={step === 'sample'} />
        </>
      )}

      {detail?.additionalShape && (
        <>
          <ToolbarArrow />
          <AdditionalShapeButton highlight={step === 'additional_shape'} />
        </>
      )}
      <ToolbarArrow />
      <PredictAreaButton highlight={step === 'area'} />
      <ToolbarArrow />
      <PredictFetchButton highlight={step === 'fetch'} />
    </>
  )
})
