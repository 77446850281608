import { Fragment, type ReactElement } from 'react'
import { track, useEditor } from 'tldraw'
import { isPieceCircleShape } from '../../../../annot/piece/circle/shape'
import { PIECE_CIRCLE_TOOL_ID } from '../../../../annot/piece/circle/tool'
import { t } from '../../../../util/intl/t'
import { HelpCanvas } from '../../../card/canvas'
import { HelpCard } from '../../../card/card'
import { HelpText } from '../../../card/text'
import { HelpFirePipeStatic } from '../pipe/pipe-static'
import { HelpFireHeadPoint } from '../pipe/scale'
import { HelpFireSizeScale } from '../size/scale'
import { HelpFireSizeStatic } from '../size/size-static'
import { HelpFireSizeTranslate } from '../size/translate'
import { HelpWatersourcePoint } from './point'
import { HelpWaterSourceStatic } from './static'

export const FireWaterSourceHelp = track((props: { model: 'fire-pipe' | 'fire-pipe-diameter' }): ReactElement | null => {
  const { model } = props
  const editor = useEditor()

  const shouldShow = false
    || editor.getCurrentToolId() === PIECE_CIRCLE_TOOL_ID
    || editor.getCurrentPageShapes().some(isPieceCircleShape)

  if (!shouldShow)
    return null

  const manualHeads = (
    <Fragment>
      <HelpText>
        {t('predict.fire-pipe-help.1')}
      </HelpText>
      <HelpText>
        {t('predict.fire-pipe-help.2')}
      </HelpText>
      <HelpText>
        {t('predict.fire-pipe-help.3')}
      </HelpText>
      <HelpCanvas>
        <HelpFirePipeStatic />
        <HelpFireHeadPoint />
      </HelpCanvas>
      <HelpText>
        {t('predict.fire-pipe-help.4')}
      </HelpText>
    </Fragment>
  )

  const manualSegments = (
    <Fragment>
      <HelpText>
        {t('predict.fire-size-help.1')}
      </HelpText>
      <HelpText>
        {t('predict.fire-size-help.2')}
      </HelpText>
      <HelpText>
        {t('predict.fire-size-help.3')}
      </HelpText>
      <HelpCanvas>
        <HelpFireSizeStatic />
        <HelpFireSizeScale />
        <HelpFireSizeTranslate />
      </HelpCanvas>
    </Fragment>
  )

  return (
    <HelpCard>
      {model === 'fire-pipe' ? manualHeads : manualSegments}
      {/* Help for water source */}
      <HelpText>
        {t('predict.water-source.help-1')}
      </HelpText>
      <HelpText>
        {t('predict.water-source.help-2')}
      </HelpText>
      <HelpCanvas>
        <HelpWaterSourceStatic />
        <HelpWatersourcePoint />
      </HelpCanvas>
      <HelpText>
        {t('predict.water-source.help-3')}
      </HelpText>
    </HelpCard>
  )
})
