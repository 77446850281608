import { useEffect } from 'react'
import type { Editor } from 'tldraw'
import { useSetting } from '../../setting/setting'

export function useEditorSyncTheme(props: { editor: Editor }): void {
  const { editor } = props

  const isDarkMode = useSetting().setting.theme === 'dark'
  useEffect(() => {
    editor.user.updateUserPreferences({ isDarkMode })
  }, [editor, isDarkMode])
}
