import type { SpinButtonOnChangeData } from '@fluentui/react-components'
import { z } from 'zod'

const number = z.coerce.number()

export function onSpinButtonChange(
  set: (value: number) => void,
) {
  return (
    _event: unknown,
    data: SpinButtonOnChangeData,
  ): void => {
    const value = data.value ?? data.displayValue
    const test = number.safeParse(value)
    if (test.success)
      set(test.data)
  }
}
