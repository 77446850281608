import type { InsulationOption } from '../../../../generated/server'

export type AttrTreeValue = number

export const ATTR_TREE_DEFAULT: AttrTreeValue = -1

export type AttrTreeOption = {
  label: string
  value: number
  /**
   * "insul options" is a special field to control insulation automatically.
   * It is available only in some "construction area" nodes.
   * When it's "undefined", we should fall back to "null" to be explicit.
   *
   * Also note that this "option" type itself does not need this field.
   * However, we are passing the options around in order to calculate other fields,
   * such as "display" and "selected options".
   * Therefore, the "insul options" field is here for convenience,
   * similar to how "label" is used for "display" value.
   */
  insulOptions: InsulationOption[] | null
}
