import { ToolbarButton } from '@fluentui/react-components'
import { ArrowRedo20Regular, ArrowUndo20Regular } from '@fluentui/react-icons'
import type { ReactElement } from 'react'
import { useHotkeys } from 'react-hotkeys-hook'
import { track, useEditor } from 'tldraw'
import { KEY_CMD_SHORT } from '../../ui/key/cmd'
import { useToast } from '../../ui/toast'
import { ToolbarTooltip } from '../../ui/toolbar/tooltip'
import { t } from '../../util/intl/t'

export const EditorToolbarHistory = track((): ReactElement => {
  const editor = useEditor()
  const { toast } = useToast()

  const undo = () => {
    editor.getCanUndo()
      ? editor.undo()
      : toast(t('editor.history.undo-none'))
  }

  const redo = () => {
    editor.getCanRedo()
      ? editor.redo()
      : toast(t('editor.history.redo-none'))
  }

  useHotkeys('mod+z', undo)
  useHotkeys('mod+y', redo)

  return (
    <>
      <ToolbarTooltip kbd={`${KEY_CMD_SHORT} Z`} relationship="label" content={t('editor.history.undo')}>
        <ToolbarButton onClick={undo} icon={<ArrowUndo20Regular />} />
      </ToolbarTooltip>
      <ToolbarTooltip kbd={`${KEY_CMD_SHORT} Y`} relationship="label" content={t('editor.history.redo')}>
        <ToolbarButton onClick={redo} icon={<ArrowRedo20Regular />} />
      </ToolbarTooltip>
    </>
  )
})
