import type { ReactElement, ReactNode } from 'react'
import { EditorShapeHighlight } from '../../../editor/shape/highlight'
import type { PieceBoxShape } from './shape'

export function PieceBoxComponent(props: {
  original: ReactNode
  box: PieceBoxShape
}): ReactElement {
  const { original, box } = props

  return (
    <EditorShapeHighlight shape={box}>
      {original}
    </EditorShapeHighlight>
  )
}
