import { ToolbarButton } from '@fluentui/react-components'
import { Copy20Regular } from '@fluentui/react-icons'
import type { ReactElement } from 'react'
import { useHotkeys } from 'react-hotkeys-hook'
import type { TLShape } from 'tldraw'
import { track, useEditor } from 'tldraw'
import { isPredictAreaShape } from '../../predict/area/shape'
import { isPredictSampleShape } from '../../predict/sample/shape'
import { KEY_CMD_SHORT } from '../../ui/key/cmd'
import { useToast } from '../../ui/toast'
import { ToolbarTooltip } from '../../ui/toolbar/tooltip'
import { t } from '../../util/intl/t'
import { isAnnotShape } from '../shape/shape'

// @TODO: Meta field?
function isDuplicable(shape: TLShape): boolean {
  return [
    isAnnotShape,
    isPredictAreaShape,
    isPredictSampleShape,
  ].some(fn => fn(shape))
}

export const AnnotToolbarDuplicate = track((): ReactElement => {
  const editor = useEditor()

  const { toast } = useToast()

  const fn = () => {
    const prev = editor.getSelectedShapes()
      .filter(isDuplicable)

    if (prev.length === 0)
      return void toast(t('annot.toolbar.duplicate-none'))

    editor.duplicateShapes(prev, { x: 10, y: 10 })
  }

  useHotkeys('mod+d', fn)

  return (
    <ToolbarTooltip
      relationship="description"
      kbd={`${KEY_CMD_SHORT} D`}
      content={t('annot.toolbar.duplicate')}
    >
      <ToolbarButton icon={<Copy20Regular />} onClick={fn}>
        {t('annot.toolbar.duplicate')}
      </ToolbarButton>
    </ToolbarTooltip>
  )
})
