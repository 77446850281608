import { makeStyles, tokens } from '@fluentui/react-components'

const useStyles = makeStyles({
  group: {
    stroke: tokens.colorNeutralForeground1,
  },
})

export function HelpFireHeadStatic(): JSX.Element {
  const s = useStyles()

  return (
    <svg height="80" width="120" xmlns="http://www.w3.org/2000/svg">
      <g className={s.group}>
        <circle r="5" cx="10" cy="10" fill="none" />
        <line x1="15" y1="10" x2="55" y2="10" />
        <circle r="5" cx="60" cy="10" fill="none" />
        <line x1="65" y1="10" x2="105" y2="10" />
        <circle r="5" cx="110" cy="10" fill="none" />
        <line x1="60" y1="15" x2="60" y2="55" />
      </g>
      <g className={s.group}>
        <circle r="5" cx="10" cy="60" fill="none" />
        <line x1="15" y1="60" x2="55" y2="60" />
        <circle r="5" cx="60" cy="60" fill="none" />
        <line x1="65" y1="60" x2="105" y2="60" />
        <circle r="5" cx="110" cy="60" fill="none" />
      </g>
    </svg>
  )
}
