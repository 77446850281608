import { Text, makeStyles, tokens } from '@fluentui/react-components'
import { track, useEditor } from 'tldraw'
import { t } from '../../util/intl/t'
import { isPieceShape } from '../piece/shape'
import { isSegmentShape } from '../segment/shape'
import { isAnnotShape } from '../shape/shape'
import { AnnotPanelMenu } from './menu'
import { AnnotSection } from './section'
import { AnnotPanelWarning } from './warning'

const useStyles = makeStyles({
  container: {
    // For the "click on empty space" to work
    minHeight: '100%',
  },
  empty: {
    color: tokens.colorNeutralForeground3,
  },
  icon: {
    verticalAlign: 'middle',
  },
})

export const AnnotPanel = track((): JSX.Element => {
  const editor = useEditor()
  const s = useStyles()

  const empty = editor.getCurrentPageShapes().filter(isAnnotShape).length === 0

  if (empty) {
    return (
      <Text block className={s.empty} size={200}>
        {t('annot.panel.empty')}
      </Text>
    )
  }

  return (
    <div
      className={s.container}
      // There's stopPropagation at AnnotButton.
      onClick={() => void editor.selectNone()}
    >
      <AnnotPanelMenu />
      <AnnotPanelWarning />
      <AnnotSection isShape={isSegmentShape} />
      <AnnotSection isShape={isPieceShape} />
    </div>
  )
})
