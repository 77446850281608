import { Text, Title3, makeStyles } from '@fluentui/react-components'
import * as Sentry from '@sentry/react'
import type { ReactElement, ReactNode } from 'react'
import { t } from '../util/intl/t'

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
  },
})

export function ErrorBoundary(props: { children: ReactNode }): ReactElement {
  const { children } = props

  const s = useStyles()

  const fallbackComponent = (
    <div className={s.container}>
      <Title3>{t('app.error.title')}</Title3>
      <Text>{t('app.error.help')}</Text>
    </div>
  )

  return (
    <Sentry.ErrorBoundary
      fallback={fallbackComponent}
      onError={(error) => {
        Sentry.captureException(error, { tags: { component: 'ErrorBoundary' } })
      }}
    >
      {children}
    </Sentry.ErrorBoundary>
  )
}
