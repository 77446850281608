import type { ReactElement, ReactNode } from 'react'
import { EditorShapeHighlight } from '../../../editor/shape/highlight'
import type { PieceCheckShape } from './shape'

export function PieceCheckComponent(props: {
  original: ReactNode
  check: PieceCheckShape
}): ReactElement {
  const { original, check } = props

  return (
    <EditorShapeHighlight shape={check}>
      {original}
    </EditorShapeHighlight>
  )
}
