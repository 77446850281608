import type { EquipmentAttributeTree, InsulationThickness, ShapeOptions } from '../../generated/server'
import type { AttrTreeDropdownValue } from '../util/attr/tree/dropdown'
import { findTreeNode } from '../util/web/tree'
import type { InsulAttrValue } from './value'

export function getAutoInsul(props: {
  tree: EquipmentAttributeTree | null
  equip: number
  type: string
  material1: AttrTreeDropdownValue | null
  material2: AttrTreeDropdownValue | null
  cArea: AttrTreeDropdownValue | null
}): Partial<InsulAttrValue> {
  const { tree, equip, type, material1, material2, cArea } = props

  // Case 1: Prioritise conditions that turn off the insulation field
  const disabled: Partial<InsulAttrValue> = { enabled: false }

  // FP Pipes
  if (equip >= 76 && equip <= 83)
    return disabled

  if (['RA', 'OA', 'EA'].includes(type))
    return disabled

  if (type === 'SM' && [
    '屋内露出（一般居室、廊下）',
    '屋内隠ぺい',
    '機械室・書庫・倉庫',
    '浴室・厨房等',
  ].includes(cArea?.display ?? '_'))
    return disabled

  if (material1?.display === 'フレキシブルダクト' && material2?.display === '保温付')
    return disabled

  // Case 2: Enable insulation

  // All optional chains here are expected:
  // - "construction area" may not be chosen or not loaded yet
  // - "insul options" are not available for all "construction areas"
  // - "insul options" may be empty as admin did not fill in yet?
  const insulMaterial = cArea?.insulOptions?.at(0)?.material
  if (insulMaterial !== undefined) {
    // This ensures the unsafe bracket access below
    if (insulMaterial.length !== 2)
      throw new Error('Insulation material must have exactly 2 elements')
    const [material1, material2] = insulMaterial

    if (tree === null)
      return { enabled: true, material1, material2 }

    const server = insulMaterial.reduce((prev, nodeID): ShapeOptions | null => {
      // It's expected that "[]" is a valid value and will override the previous
      // value. We only fall back in "null" and "undefined" values.
      const curr = findTreeNode(tree.tree, nodeID)?.shapeOptions ?? null
      return curr ?? prev
    }, null as (ShapeOptions | null))

    if (server === null || server.kind !== 'InsulationThickness')
      return { enabled: true, material1, material2 }

    const thickness = (server.data as InsulationThickness[])
      .find(option => option.equipmentClass === equip)?.thickness.toString() ?? ''

    return { enabled: true, material1, material2, thickness }
  }

  // Case 3: Do nothing, the automation is not applicable
  return {}
}
