import { makeStyles } from '@fluentui/react-components'
import type { ReactElement, ReactNode } from 'react'
import { EDITOR_ZOOM_VARIABLE } from '../util/zoom'

const useStyles = makeStyles({
  container: {
    '& svg path[stroke]:not([stroke=\'none\'])': {
      strokeWidth: `calc(2 / var(${EDITOR_ZOOM_VARIABLE}))`,
    },
  },
})

export function EditorShapeStroke(props: {
  children: ReactNode
}): ReactElement {
  const { children } = props

  const s = useStyles()

  return <div className={s.container}>{children}</div>
}
