import type { TLGeoShape, TLShape } from 'tldraw'
import type { EditorShapePartial } from '../../../editor/shape/base'
import type { VerticalDirection } from '../../../util/data/server'
import type { SegmentMetaBase } from '../shape'
import { isSegmentShapeBase } from '../shape'

type Meta = SegmentMetaBase & {
  segment: 'vertical'
  mm: number
  direction: VerticalDirection
}

export type SegmentVerticalShape = TLGeoShape & { meta: Meta }

export type SegmentVerticalPartial = EditorShapePartial<TLGeoShape, Meta>

export function isSegmentVerticalShape(
  shape: TLShape,
): shape is SegmentVerticalShape {
  if (!isSegmentShapeBase(shape))
    return false
  const test = shape as SegmentVerticalShape
  return test.meta.segment === 'vertical'
}
