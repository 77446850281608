import type { ReactElement, ReactNode } from 'react'
import { EditorShapeHighlight } from '../../../editor/shape/highlight'
import type { SegmentExtensionShape } from './shape'

export function SegmentExtensionComponent(props: {
  original: ReactNode
  extension: SegmentExtensionShape
}): ReactElement {
  const { original, extension } = props

  return (
    <EditorShapeHighlight shape={extension}>
      {original}
    </EditorShapeHighlight>
  )
}
