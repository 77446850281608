import { Checkbox, Divider, Switch, Text, makeStyles, tokens } from '@fluentui/react-components'
import type { ReactElement } from 'react'
import { useSetting } from '../setting/setting'
import type { AttrSelection } from '../util/attr/selection'
import { getAttrSelectionValue } from '../util/attr/selection'
import { t } from '../util/intl/t'
import type { InsulEnabledValue } from './value'

const useStyles = makeStyles({
  heading: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    // Technically we only need to "fix" the bottom margin,
    // but it's easier to balance the spacing by fixing both.
    // Choosing XS here because it plays nicely with the "enabled" checkbox.
    margin: `calc(-1 * ${tokens.spacingVerticalXS}) 0`,
  },
  help: {
    color: tokens.colorNeutralForeground4,
  },
})

export function InsulPanelHeader(props: {
  enabled: AttrSelection<InsulEnabledValue>
  setEnabled: (enabled: InsulEnabledValue) => void
}): ReactElement {
  const { enabled, setEnabled } = props

  const { setting, updateSetting } = useSetting()

  const s = useStyles()

  return (
    <>
      <div className={s.heading}>
        <Text weight="semibold">
          {t('insul.header.title')}
        </Text>
        <Switch
          checked={setting.autoInsul}
          onChange={(_event, data) => {
            updateSetting({ autoInsul: data.checked })
          }}
          label={t('insul.header.auto-title')}
          labelPosition="before"
        />
      </div>
      <div><Divider /></div>
      {setting.autoInsul && (
        <Text className={s.help} size={200}>
          {t('insul.header.auto-help')}
        </Text>
      )}
      <Checkbox
        checked={getAttrSelectionValue(enabled, 'mixed')}
        onChange={(_event, data) => {
          if (data.checked === 'mixed')
            throw new Error('Should set "enabled" to a boolean')
          setEnabled(data.checked)
        }}
        label={t('insul.header.enabled')}
        size="large"
      />
    </>
  )
}
