import type { TLCameraOptions } from 'tldraw'

const zoomSteps = [0.1, 0.5, 1, 2, 4, 8, 16]

const zoomMax = (() => {
  const max = Math.max(...zoomSteps)
  if (Number.isFinite(max) === false)
    throw new Error('Zoom steps is empty')
  return max
})()

const zoomMin = (() => {
  const min = Math.min(...zoomSteps)
  if (Number.isFinite(min) === false)
    throw new Error('Zoom steps is empty')
  return min
})()

const options: Partial<TLCameraOptions> = {
  wheelBehavior: 'zoom',
  zoomSteps,
}

export const EDITOR_CAMERA = {
  options,
  zoomSteps,
  zoomMax,
  zoomMin,
}
