import { tokens } from '@fluentui/react-components'
import type { TLBrushProps } from 'tldraw'
import { DefaultBrush } from 'tldraw'

export function EditorBrush(props: TLBrushProps): JSX.Element {
  return (
    <DefaultBrush
      {...props}
      color={tokens.colorNeutralForeground1}
      opacity={0.5}
    />
  )
}
