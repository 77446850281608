import { Button } from '@fluentui/react-components'
import { Info20Regular } from '@fluentui/react-icons'
import { t } from '../../util/intl/t'

export function AppToolbarHelp(): JSX.Element {
  return (
    <Button
      appearance="transparent"
      icon={<Info20Regular />}
      as="a"
      href="https://docs.aisekisan.com"
      target="_blank"
    >
      {t('app.toolbar.help')}
    </Button>
  )
}
