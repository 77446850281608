/**
 * AttrSelection works by comparing values using "===", so it only supports
 * basic values. Adding support for more advanced or custom comparisons is not
 * currently worth the effort.
 */
export type AttrSelectionValue = string | number | boolean

/**
 * We don't have the "none" type (no selection) to keep things simple.
 * It's not needed yet because we only show attribute fields when there's a
 * selection.
 */
export type AttrSelection<Value extends AttrSelectionValue> =
  | { type: 'same', value: Value }
  | { type: 'mixed', value: null }

/**
 * Return a selection based on a list of selected values.
 */
export function createAttrSelection<
  Value extends AttrSelectionValue,
>(values: Value[]): AttrSelection<Value> {
  type Selection = AttrSelection<Value>

  const [head, ...tail] = values

  // See the comment at "AttrSelection".
  if (head === undefined)
    throw new Error('Values must have at least one item.')

  const initial: Selection = { type: 'same', value: head }

  const selection = tail.reduce((acc: Selection, current): Selection => {
    const shouldStay = false
      || acc.type === 'mixed' // Already "mixed"
      || (acc.type === 'same' && acc.value === current) // Or still "same"
    return shouldStay ? acc : { type: 'mixed', value: null }
  }, initial)

  return selection
}

/**
 * Get a value from an attribute selection, with a fallback if it's "mixed".
 */
export function getAttrSelectionValue<
  Value extends AttrSelectionValue,
  Mixed = Value,
>(
  selection: AttrSelection<Value>,
  mixed: Mixed,
): Value | Mixed {
  return selection.type === 'mixed'
    ? mixed
    : selection.value
}

/**
 * Get a value from an attribute selection as an array,
 * which is useful for ithe "selected options" prop of input controls.
 */
export function getAttrSelectionValueArray<
  Value extends AttrSelectionValue,
>(
  selection: AttrSelection<Value>,
): Value[] {
  return selection.type === 'mixed'
    ? []
    : [selection.value]
}

/**
 * Get a value from an attribute selection,
 * with custom getters for both "mixed" and "same" cases.
 */
export function getAttrSelectionValueWhen<
  Value extends AttrSelectionValue,
  Mixed,
  Same,
>(
  selection: AttrSelection<Value>,
  options: {
    mixed: Mixed
    same: (value: Value) => Same
  },
): Mixed | Same {
  return selection.type === 'mixed'
    ? options.mixed
    : options.same(selection.value)
}
