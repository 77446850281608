import { Button, makeStyles, mergeClasses, tokens } from '@fluentui/react-components'
import type { ReactNode } from 'react'
import type { TLDefaultColorStyle } from 'tldraw'
import { useEditorTheme } from '../../../editor/util/theme'

const useStyles = makeStyles({
  button: {
    minWidth: 'auto',
    textAlign: 'left',
    padding: tokens.spacingHorizontalXS,
  },
  bold: {
    fontWeight: '700',
  },
})

export function AnnotButton(props: {
  color: TLDefaultColorStyle
  selected: boolean
  children: ReactNode
  onClick: (params: { shift: boolean }) => void
  bold: boolean
}): JSX.Element {
  const { selected, onClick, children, bold, color: colorName } = props

  const s = useStyles()
  const color = useEditorTheme()[colorName]

  return (
    <Button
      appearance="subtle"
      onClick={(event) => {
        onClick({ shift: event.shiftKey })
        event.stopPropagation()
      }}
      className={mergeClasses(s.button, bold ? s.bold : '')}
      size="small"
      style={{
        color: (bold || selected) ? color.solid : undefined,
        ...(selected
          ? { borderColor: color.pattern, backgroundColor: color.semi }
          : {}),
      }}
    >
      {children}
    </Button>
  )
}
