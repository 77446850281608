import { makeStyles, tokens } from '@fluentui/react-components'
import { makeHelpAnimation } from '../../animation'

const useStyles = makeStyles({
  rect: {
    width: '150px',
    height: '100px',
    backgroundColor: tokens.colorBrandForeground1,
    transformOrigin: 'top left',
    ...makeHelpAnimation([{
      states: { off: { opacity: 0 }, on: { opacity: 0.5 } },
      keyframes: [[0, 'on'], [9, 'on'], [10, 'off']],
    }, {
      states: { empty: { transform: 'scale(0)' }, full: { transform: 'scale(1)' } },
      keyframes: [[0, 'empty'], [2, 'empty'], [6, 'full'], [10, 'full']],
    }]),
  },
})

export function HelpFireAreaScale(): JSX.Element {
  const s = useStyles()

  return <div className={s.rect} />
}
