import { Button } from '@fluentui/react-components'
import { Eye16Regular, EyeOff16Regular } from '@fluentui/react-icons'
import type { ReactElement } from 'react'
import { useEditor } from 'tldraw'
import type { AnnotShape } from '../../shape/shape'

function getShowHideProps(shapes: AnnotShape[]): { opacity: number, isLocked: boolean } {
  const shape = shapes[0]

  // Check if shape is unlocked and visible
  if (!shape.isLocked && shape.opacity > 0) {
    return {
      opacity: 0,
      isLocked: true,
    }
  }

  // Check if shape was locked but visible
  if (shape.opacity > 0 && shape.isLocked) {
    return {
      opacity: 0,
      isLocked: shape.isLocked,
    }
  }

  // Check if shape is hidden and but unlocked
  if (shape.opacity === 0 && !shape.isLocked) {
    return {
      opacity: 1,
      isLocked: false,
    }
  }

  // Check if shape is hidden and locked
  if (shape.opacity === 0 && shape.isLocked) {
    return {
      opacity: 1,
      isLocked: false,
    }
  }

  // Default case
  return {
    opacity: 0,
    isLocked: true,
  }
}

export function AnnotGroupIndicator(props: {
  shapes: AnnotShape[]
}): ReactElement {
  const { shapes } = props
  const shape = shapes[0]
  const editor = useEditor()

  return (
    <>
      {/* <Button
        appearance="subtle"
        size="small"
        icon={shape.isLocked ? <LockClosed16Regular /> : <LockOpen16Regular />}
        onClick={() => {
          editor.updateShapes(
            shapes.map(shape => ({
              ...shape,
              isLocked: !shape.isLocked,
            })),
          )
        }}
      /> */}
      <Button
        appearance="subtle"
        size="small"
        icon={shape.opacity > 0 ? <Eye16Regular /> : <EyeOff16Regular />}
        onClick={() => {
          editor.updateShapes(
            shapes.map(shape => ({
              ...shape,
              opacity: getShowHideProps(shapes).opacity,
              isLocked: getShowHideProps(shapes).isLocked,
            })),
          )
        }}
      />
    </>
  )
}
