import { PieceHelp } from '../annot/piece/help'
import { SegmentFlatHelp } from '../annot/segment/flat/help'
import { ScaleHelp } from '../page/scale/help'
import { PredictHelp } from '../predict/help'

export function HelpPanel(): JSX.Element {
  return (
    <>
      <PieceHelp />
      <SegmentFlatHelp />
      <ScaleHelp />
      <PredictHelp />
    </>
  )
}
