import { Text, makeStyles, mergeClasses, shorthands, tokens } from '@fluentui/react-components'
import type { HTMLAttributes, ReactElement, ReactNode } from 'react'

const useStyles = makeStyles({
  kbd: {
    display: 'block',
    ...(shorthands.border('1px', 'solid', tokens.colorNeutralStroke1)),
    borderBottomWidth: tokens.spacingVerticalXXS,
    ...(shorthands.borderRadius(tokens.borderRadiusMedium)),
    ...(shorthands.padding(0, tokens.spacingHorizontalXS)),
  },
  normal: {
    color: tokens.colorNeutralForeground1,
    backgroundColor: tokens.colorNeutralBackground2,
  },
  inverted: {
    color: tokens.colorNeutralForegroundStaticInverted,
    backgroundColor: tokens.colorNeutralBackgroundStatic,
  },
})

export function Kbd(props: {
  children: ReactNode
  appearance?: 'normal' | 'inverted'
} & HTMLAttributes<HTMLElement>): ReactElement {
  const { children, appearance, className, ...rest } = props

  const s = useStyles()

  return (
    <kbd
      className={mergeClasses(
        s.kbd,
        appearance === 'inverted' ? s.inverted : s.normal,
        className,
      )}
      {...rest}
    >
      <Text size={200}>
        {children}
      </Text>
    </kbd>
  )
}
