import { Cursor20Regular, HandLeft20Regular, Search20Regular } from '@fluentui/react-icons'
import type { ReactElement } from 'react'
import { HandTool, SelectTool, ZoomTool } from 'tldraw'
import { t } from '../../util/intl/t'
import { EditorToolButton } from '../tool/button'

export function EditorToolbarNavigate(): ReactElement {
  return (
    <>
      <EditorToolButton id={SelectTool.id} kbd="1" icon={<Cursor20Regular />} iconOnly>
        {t('editor.navigate.select')}
      </EditorToolButton>
      <EditorToolButton id={HandTool.id} kbd="2" icon={<HandLeft20Regular />} iconOnly>
        {t('editor.navigate.hand')}
      </EditorToolButton>
      <EditorToolButton id={ZoomTool.id} kbd="3" icon={<Search20Regular />} iconOnly>
        {t('editor.navigate.zoom')}
      </EditorToolButton>
    </>
  )
}
