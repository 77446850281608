import { makeStyles } from '@fluentui/react-components'
import type { ReactNode } from 'react'

const useStyles = makeStyles({
  text: {
    transform: 'translateY(-100%)',
  },
})

export function HelpTextStatic(props: {
  children: ReactNode
}): JSX.Element {
  const { children } = props

  const s = useStyles()

  return <div className={s.text}>{children}</div>
}
