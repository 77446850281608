import { z } from 'zod'
import { PredictModelDrain } from './drain'
import { PredictModelDuct } from './duct'
import { PredictModelFireHead } from './fire-head'
import { PredictModelFirePipe } from './fire-pipe'
import { PredictModelFirePipeDiameter } from './fire-pipe-diameter'
import { PredictModelSanitary } from './sanitary'
import type { PredictModelBase } from './type'

export const PREDICT_MODELS = [
  PredictModelDuct,
  PredictModelDrain,
  PredictModelSanitary,
  PredictModelFireHead,
  PredictModelFirePipe,
  PredictModelFirePipeDiameter,
] as const satisfies PredictModelBase[]

export type PredictModel = typeof PREDICT_MODELS[number]

export type PredictModelValue = PredictModel['value']

export const predictModelValueSchema = z.custom<PredictModelValue>((value) => {
  return PREDICT_MODELS.some(m => m.value === value)
})
