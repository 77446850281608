import type { TLExitEventHandler, TLLineShape, TLPointerEvent } from 'tldraw'
import { LineShapeTool } from 'tldraw'
import { z } from 'zod'
import { isLineShape, lineShapeSchema } from '../../editor/shape/line'
import type { ScaleShape } from './shape'
import { isScaleShape } from './shape'

const creatingEvent = z.object({
  info: z.object({
    isCreating: z.literal(true),
    /**
     * LineShapeTool does not use "this.id" (which we overridden to "scale"),
     * but passes a literal "line" here. Note that we will need to update this
     * to "scale" in our onExit handling as well.
     */
    onInteractionEnd: z.literal('line'),
    shape: lineShapeSchema,
  }),
  to: z.literal('select'),
})

/**
 * Update tldraw's "line shape" to our ScaleShape,
 * both visually ("props") and functionally ("meta").
 * Note that this does not create a new shape (i.e., no new shape ID),
 * but return the same shape (i.e., same shape ID) modified.
 */
function updateShape(line: TLLineShape): ScaleShape {
  const props: ScaleShape['props'] = {
    ...line.props,
    color: 'blue',
    dash: 'solid',
    size: 'l',
  }
  return {
    ...line,
    opacity: 0.6,
    props,
    meta: { type: 'scale' },
  }
}

/**
 * In most cases[^1], tldraw's LineShapeTool works by:
 * 1. Creating a LineShape (in its Pointing child), then
 * 2. Passing it to the SelectTool to handle the dragging.
 *
 * In other words, a LineShape is first created as a very short line, and the
 * SelectTool gives the user the perception of dragging to create, while it's
 * actually dragging to position the end handle.
 *
 * [^1]: The exception is when we are creating new handles by holding shift
 * and left clicking on the canvas, which we don't want but also not sure how
 * to turn it off yet as it is inside Pointing, which we have very limited access.
 */
export class ScaleTool extends LineShapeTool {
  static override id = 'scale'

  // @TODO: Ensure tool locked or not. See other tools.
  // @TODO: update shape at onPointerDown? See SegmentTool.

  /**
   * Handle most scale details on exit.
   *
   * As mentioned in the "background" section above, LineShapeTool does not
   * create a LineShape at its onExit, but at its Pointing's onEnter. However:
   * 1. tldraw does not export Pointing, so we cannot extend it.
   * 2. LineTool's onEnter (parent) runs before Pointing's onEnter (children),
   *    so at onEnter here we don't have access to the "info" object yet.
   * Therefore, our best chance to handle is at LineShape's onExit.
   */
  override onExit: TLExitEventHandler = (info: unknown, to) => {
    const creating = creatingEvent.safeParse({ info, to })
    if (!creating.success)
      return

    // Update the shape from "line" to "scale".
    const shape = updateShape(creating.data.info.shape)
    this.editor.updateShape(shape);

    // Because we enforce tool lock globally, SelectTool will return the user
    // to our tool here. However, as mentioned in the schema, we need to update
    // the hard-coded tool ID ("line").
    //
    // We intentionally mutate the original "info" object because:
    // 1. It is passed by reference during tools transition.
    // 2. In parsing, Zod does not return the original object but a copy.
    (info as typeof creating.data.info).onInteractionEnd = 'scale' as 'line'
  }

  /**
   * Clear previous scale shapes. There should be at most 1 scale shape on the
   * canvas at a time, for the follow up calculation to base on, and more
   * important to not confuse our users.
   *
   * In theory, it's likely that we only have 0 or 1 previous scale shape
   * (because of this clean up itself) and the shape is also selected (because
   * of the current default behaviour of ScaleTool/LineShapeTool).
   *
   * However, in practice these assumptions are too weak and there's no
   * practical cost or downside to just clear all scale shapes if any, which is
   * much more reliable.
   */
  override onPointerDown: TLPointerEvent = () => {
    const scales = this.editor.getCurrentPageShapes().filter(isScaleShape)
    this.editor.deleteShapes(scales)
  }

  /**
   * If a user clicks (instead of dragging), we will have a very short line on
   * the canvas that looks like a dot. More important, this line is not updated
   * to be a "scale" shape yet.
   *
   * This is because:
   * 1. As explained in the "background" section, the line is created at
   *    Pointing's onEnter (following LineShape's onPointerDown).
   * 2. However, as explained at the handler, we can only handle the scale
   *    details (e.g. update the shape) at onExit, which is not triggered if
   *    there is no transition to SelectTool, which is the case when the user
   *    clicks instead of dragging.
   *
   * We could carry out the scale details as in onExit here, but the result
   * would be still visually confusing to our users (the distance is a dot).
   * Therefore, it's better to just clear this LineShape right here.
   *
   * We have a small challenge here though: there's not a reliable way to get
   * the shape to delete it, because:
   * 1. It is not updated to become a ScaleShape yet, and
   * 2. It is stored inside Pointing, where ScaleTool/LineShapeTool cannot
   *    access (for good reasons).
   *
   * So we just get the selected shape, and scream strongly (at runtime) if
   * there's anything unexpected (as far as we can check).
   */
  override onPointerUp: TLPointerEvent = () => {
    const shape = this.editor.getOnlySelectedShape()
    if (!shape || !isLineShape(shape) || isScaleShape(shape))
      throw new Error('Could not find a LineShape to delete.')
    this.editor.deleteShape(shape)
  }
}
