import type { Editor } from 'tldraw'
import { getSvgAsImage } from 'tldraw'
import type { Canvas } from '../../util/web/canvas'
import { getStrict } from '../../util/web/primitive'
import { PRINT_SCALE } from './scale'

/**
 * Print the page's annotations onto a canvas.
 */
export async function printPageAnnots(props: {
  canvas: Canvas
  editor: Editor
}): Promise<void> {
  const { canvas, editor } = props

  const bounds = editor.getCurrentPageBounds()
  if (bounds === undefined)
    return

  /** This is actually an object, but we keep tldraw's naming */
  const svgString = await editor.getSvgString(
    [...editor.getCurrentPageShapeIds()],
    // Annotations are always at original scale, so we can use the "scale"
    // directly without worrying about EDITOR_CANVAS.SCALE like in the
    // "background" case.
    { scale: PRINT_SCALE, padding: 0, background: false },
  )

  if (svgString === undefined)
    throw new Error('Failed to get SVG string')

  // We already scaled at "getSvg" so should not scale again here.
  const blob = await getSvgAsImage(editor, svgString.svg, {
    quality: 1,
    scale: 1,
    type: 'png',
    width: svgString.width,
    height: svgString.height,
  })

  const bitmap = await createImageBitmap(getStrict(blob))
  // @TODO Not sure why "bounds.scale(n)" does not work here so we scale the position
  // (x, y) directly.
  const [dx, dy] = [bounds.x * PRINT_SCALE, bounds.y * PRINT_SCALE]
  canvas.context.drawImage(bitmap, dx, dy)
}
