import { Toast, useToastController } from '@fluentui/react-components'

type Dispatch = (message: string) => void

/**
 * Wrap Fluent's toast to:
 * - Use global Toaster,
 * - Support string message only,
 * - Apply default styles (that could not be done at Toaster).
 */
export function useToast(): {
  /**
   * It's intentional that this function identity is NOT stable.
   * It should be used in user events, not in render and side effects.
   */
  toast: Dispatch
} {
  const { dispatchToast } = useToastController()

  const dispatch: Dispatch = (message) => {
    const toast = <Toast appearance="inverted">{message}</Toast>
    dispatchToast(toast)
  }

  return { toast: dispatch }
}
