import type { FluentIcon } from '@fluentui/react-icons'
import { CheckmarkSquare20Regular, Circle20Regular, Line20Regular, Square20Regular } from '@fluentui/react-icons'
import type { TLShape } from 'tldraw'
import type { PieceShape, PieceShapePartial } from '../piece/shape'
import { isPieceShape } from '../piece/shape'
import type { SegmentPartial, SegmentShape } from '../segment/shape'
import { isSegmentShape } from '../segment/shape'

export type AnnotShape =
  | SegmentShape
  | PieceShape

export type AnnotType = AnnotShape['meta']['annot']

export type AnnotTypeIcon = {
  [key in AnnotShape['meta']['annot']]: key extends 'piece' ? {
    'check-box': FluentIcon
    'box': FluentIcon
    'circle': FluentIcon
  } : FluentIcon
}

export type AnnotShapePartial =
  | SegmentPartial
  | PieceShapePartial

export function isAnnotShape(shape: TLShape): shape is AnnotShape {
  return false
    || isSegmentShape(shape)
    || isPieceShape(shape)
}

export const ANNOT_ICONS: AnnotTypeIcon = {
  piece: {
    'check-box': CheckmarkSquare20Regular,
    'box': Square20Regular,
    'circle': Circle20Regular,
  },
  segment: Line20Regular,
}
