import { ArrowRight16Filled } from '@fluentui/react-icons'
import { ToolbarLabel } from './label'

export function ToolbarArrow(): JSX.Element {
  return (
    <ToolbarLabel wide>
      <ArrowRight16Filled />
    </ToolbarLabel>
  )
}
