import { useQuery } from '@tanstack/react-query'
import type { AttrEquipValue } from '../../../attr/field/equip/value'
import { usePage } from '../../../page/state/context'
import type { EquipmentAttributeTree, EquipmentAttributeTreeType } from '../../data/server'
import { server } from '../../data/server'

export function useAttrTreeAll<Value>(props: {
  select: (trees: EquipmentAttributeTree[]) => Value
}): Value | null {
  const { select } = props

  const { constructionID } = usePage().page

  const node = useQuery({
    queryKey: ['attr-tree'],
    queryFn: () => server.listEquipmentAttributeTrees(constructionID),
    select,
  })

  return node.data ?? null
}

export type AttrTreeProps = {
  equip: AttrEquipValue
  type: EquipmentAttributeTreeType
}

export function useAttrTreeSingle(
  props: AttrTreeProps,
): EquipmentAttributeTree | null {
  const { equip, type } = props

  const tree = useAttrTreeAll({
    select: all => all.find((current) => {
      return true
        && current.equipmentClass === equip
        && current.treeType === type
    }) ?? null,
  })

  return tree ?? null
}
