import { Divider, Text, makeStyles, tokens } from '@fluentui/react-components'
import { Fragment, type ReactElement } from 'react'
import { track, useEditor } from 'tldraw'
import { isAnnotShape } from '../annot/shape/shape'
import { t } from '../util/intl/t'
import { AttrForm } from './form'
import type { AttrValue } from './state/context'
import { useAttrs } from './state/context'

const useStyles = makeStyles({
  help: {
    color: tokens.colorNeutralForeground4,
  },
})

export const AttrPanel = track((): ReactElement => {
  const editor = useEditor()
  const all = useAttrs()
  const s = useStyles()

  const shapes = editor
    .getSelectedShapes()
    .filter(isAnnotShape)

  const attrs = shapes
    // We should handle missing attr flexibly here. There are short periods
    // during rendering where a new shape is created, along with its new group
    // ID, but the corresponding "setAttrs" is not settled yet.
    .map(shape => all.attrs[shape.meta.group] ?? null)
    .filter((attr): attr is AttrValue => attr !== null)

  // "Attr form" only works when there's at least one annot selected,
  // so we need to gate keep this and only pass "attrs" when it's not empty.
  // See "attr/selection" to learn more.
  if (attrs.length === 0) {
    return (
      <Text className={s.help} size={200}>
        {t('attr.panel.empty')}
      </Text>
    )
  }

  return (
    <Fragment>
      <Text className={s.help} size={200}>{t('attr.panel.help')}</Text>
      <div>
        <Divider />
      </div>
      <AttrForm attrs={attrs} shapes={shapes} />
    </Fragment>
  )
})
