import { PAGE_ID } from '../page/state/id'

const DB_NAME = 'editor'
const DB_VERSION = 1
const DB_STORE_NAME = 'pdf'

export type PdfCache = {
  id: string
  lastUpdated: string
  expiresAt: string
  fileBase64: string
}

let db: IDBDatabase

export function initPdfCache() {
  return new Promise((resolve, reject) => {
    if (!('indexedDB' in window))
      reject(new Error('Not supported IndexedDB'))

    const req = window.indexedDB.open(DB_NAME, DB_VERSION)
    req.onsuccess = function () {
      db = this.result
      resolve(this)
    }

    req.onerror = function () {
      reject(new Error(`openDb: error ${req.error}`))
    }

    req.onupgradeneeded = function (evt: any) {
      db = evt.currentTarget.result

      if (db) {
        db.onerror = () => {
          console.error('Error loading database.')
        }

        const store = db.createObjectStore(DB_STORE_NAME, { keyPath: 'id' })
        store.createIndex('lastUpdated', 'lastUpdated', { unique: false })
        store.createIndex('expiresAt', 'expiresAt', { unique: false })
        store.createIndex('fileBase64', 'fileBase64', { unique: false })
      }
    }
  })
}

export function addItemInPdfCache(pdf: PdfCache) {
  return new Promise((resolve, reject) => {
    const transaction = db.transaction(DB_STORE_NAME, 'readwrite')
    const store = transaction.objectStore(DB_STORE_NAME)

    store.put(pdf)

    transaction.oncomplete = () => resolve(true)
    transaction.onerror = () => reject(transaction.error)
  })
}

export function getItemInPdfCache(): Promise<PdfCache | undefined> {
  return new Promise((resolve, reject) => {
    const transaction = db.transaction(DB_STORE_NAME)
    const store = transaction.objectStore(DB_STORE_NAME)
    const request = store.get(PAGE_ID)

    request.onsuccess = () => {
      resolve(request.result)
    }

    request.onerror = () => {
      reject(request.error)
    }

    transaction.oncomplete = () => resolve(request.result)
    transaction.onerror = () => reject(transaction.error)
  })
}

export function updateItemInPdfCache(pdf: PdfCache) {
  return new Promise((resolve, reject) => {
    const transaction = db.transaction(DB_STORE_NAME, 'readwrite')
    const store = transaction.objectStore(DB_STORE_NAME)
    const request = store.get(PAGE_ID)

    request.onerror = () => console.error('Failed to get item', request.error)

    request.onsuccess = (event: any) => {
      const data = event.target.result

      if (data) {
        data.lastUpdated = pdf.lastUpdated
        data.expiresAt = pdf.expiresAt
        data.fileBase64 = pdf.fileBase64

        const requestUpdate = store.put(data)

        requestUpdate.onerror = () => console.error('Failed to update item', requestUpdate.error)
      }
      else {
        console.error('Item not found')
      }
    }
    transaction.oncomplete = () => resolve(true)
    transaction.onerror = () => reject(transaction.error)
  })
}
