import type { TLEnterEventHandler, TLExitEventHandler, TLPointerEvent } from 'tldraw'
import { GeoShapeTool } from 'tldraw'
import { z } from 'zod'
import { isGeoShape } from '../../editor/shape/geo'
import { toPredictSampleShape } from './shape'

// Heads up! This is a nearly identical copy of predict/area/tool.
// Please refer to that file for detailed comments.

const creatingEvent = z.object({
  info: z.object({
    isCreating: z.literal(true),
    onInteractionEnd: z.literal('geo'),
  }),
  to: z.literal('select'),
})

export class PredictSampleTool extends GeoShapeTool {
  static override id = 'predict-sample'

  // Returning to "select" is less confusing
  override onEnter: TLEnterEventHandler = () => {
    this.editor.updateInstanceState({ isToolLocked: false })
  }

  // Disable "click to create"
  override onPointerUp: TLPointerEvent = () => {
    this.transition('idle')
  }

  override onExit: TLExitEventHandler = (info: unknown, to) => {
    // Reset to default
    this.editor.updateInstanceState({ isToolLocked: false })

    const creating = creatingEvent.safeParse({ info, to })
    // Not an error, e.g., user may cancel the drawing
    if (!creating.success)
      return

    // Hydrate to sample shape
    const shape = this.editor.getOnlySelectedShape()
    if (shape === null || !isGeoShape(shape))
      throw new Error('Selected shape must be a geo shape')
    const pick = toPredictSampleShape(shape)
    this.editor.updateShape(pick);

    // Update callback tool ID
    (info as typeof creating.data.info).onInteractionEnd = this.id as 'geo'
  }
}
