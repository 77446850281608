import type { ReactElement, ReactNode } from 'react'
import { useState } from 'react'
import { usePage } from '../page/state/context'
import type { InsulAttrRecord } from './context'
import { insulAttrContext } from './context'

export function InsulAttrProvider(props: {
  children: ReactNode
}): ReactElement {
  const { children } = props

  const initial = usePage().annots.insuls
  const [attrs, setAttrs] = useState<InsulAttrRecord>(initial)

  return (
    <insulAttrContext.Provider value={{ attrs, setAttrs }}>
      {children}
    </insulAttrContext.Provider>
  )
}
