import type { TLShape } from 'tldraw'

export type AnnotMetaBase = {
  type: 'annot'
  group: string
}

export type AnnotShapeBase =
  & TLShape
  & { meta: AnnotMetaBase }

export const ANNOT_SHAPE_BASE = {
  opacity: 0.9,
} satisfies Partial<AnnotShapeBase>

export function isAnnotShapeBase(
  shape: TLShape,
): shape is AnnotShapeBase {
  const test = shape as AnnotShapeBase
  return test.meta.type === 'annot'
}
