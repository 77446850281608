import type { TLGeoShape, TLShape } from 'tldraw'
import { isGeoShape } from '../../editor/shape/geo'

export type PredictSampleShapeMeta = {
  type: 'predict-sample'
  id: string
}

export type PredictSampleShape =
  TLGeoShape
  & { meta: PredictSampleShapeMeta }

export function isPredictSampleShape(shape: TLShape): shape is PredictSampleShape {
  const test = shape as PredictSampleShape
  return isGeoShape(test) && test.meta.type === 'predict-sample'
}

export function toPredictSampleShape(geo: TLGeoShape): PredictSampleShape {
  const area: PredictSampleShape = {
    ...geo,
    opacity: 1,
    props: {
      ...geo.props,
      geo: 'rectangle',
      color: 'blue',
      dash: 'solid',
      fill: 'solid',
      size: 's',
    },
    meta: {
      type: 'predict-sample',
      id: crypto.randomUUID(),
    },
  }

  return area
}
