import { makeStyles, tokens } from '@fluentui/react-components'

const useStyles = makeStyles({
  line: {
    width: '150px',
    height: tokens.spacingVerticalXXS,
    backgroundColor: tokens.colorNeutralForeground1,
  },
})

export function HelpLineStatic(): JSX.Element {
  const s = useStyles()

  return <div className={s.line} />
}
