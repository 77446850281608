import type { TLDefaultColorStyle, TLShapeId, VecLike } from 'tldraw'
import { Box, createShapeId } from 'tldraw'
import { ANNOT_SHAPE_BASE } from '../../shape/base'
import { randomAnnotShapeColor } from '../../shape/color'
import type { SegmentExtensionPartial } from './shape'

export function createSegmentExtensionShape(props: {
  center: VecLike
  mm: number | null
  //
  id: TLShapeId | null
  color: TLDefaultColorStyle | null
  group: string | null
}): SegmentExtensionPartial {
  const { center, mm, color, group, id } = props

  const [w, h] = [10, 10]
  const { x, y } = Box.FromCenter(center, { x: w, y: h })

  const extension: SegmentExtensionPartial = {
    ...(ANNOT_SHAPE_BASE),
    type: 'geo',
    id: id ?? createShapeId(),
    x,
    y,
    props: {
      geo: 'rectangle',
      dash: 'solid',
      size: 's',
      color: color ?? randomAnnotShapeColor(),
      w,
      h,
    },
    meta: {
      type: 'annot',
      group: group ?? crypto.randomUUID(),
      annot: 'segment',
      segment: 'extension',
      mm: mm ?? 0,
    },
  }

  return extension
}
