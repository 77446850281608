import type { TLGeoShape, TLShape } from 'tldraw'
import type { EditorShapePartial } from '../../../editor/shape/base'
import type { SegmentMetaBase } from '../shape'
import { isSegmentShapeBase } from '../shape'

type Meta = SegmentMetaBase & {
  segment: 'extension'
  mm: number
}

export type SegmentExtensionShape = TLGeoShape & { meta: Meta }

export type SegmentExtensionPartial = EditorShapePartial<TLGeoShape, Meta>

export function isSegmentExtensionShape(
  shape: TLShape,
): shape is SegmentExtensionShape {
  if (!isSegmentShapeBase(shape))
    return false
  const test = shape as SegmentExtensionShape
  return test.meta.segment === 'extension'
}
