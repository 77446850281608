import { makeStyles } from '@fluentui/react-components'
import type { ReactElement, ReactNode } from 'react'
import { useEditorTheme } from '../../editor/util/theme'

// https://github.com/frenic/csstype?tab=readme-ov-file#what-should-i-do-when-i-get-type-errors
declare module 'react' {
  // eslint-disable-next-line ts/consistent-type-definitions
  interface CSSProperties {
    '--psc-fill'?: string
  }
}

const useStyles = makeStyles({
  container: {
    '& svg path[fill]:not([fill=\'none\'])': {
      opacity: 0.5,
      // Use "solid" instead of the default "semi" colour.
      // See "area/component" for more details.
      fill: 'var(--psc-fill)',
    },
    '& svg path[stroke]:not([stroke=\'none\'])': {
      opacity: 0,
    },
  },
})

export function PredictSampleComponent(props: {
  original: ReactNode
}): ReactElement {
  const { original } = props

  const s = useStyles()
  const theme = useEditorTheme()

  return (
    <div
      className={s.container}
      style={{ '--psc-fill': theme.blue.solid }}
    >
      {original}
    </div>
  )
}
