import { ToolbarButton } from '@fluentui/react-components'
import { Delete20Regular } from '@fluentui/react-icons'
import type { ReactElement } from 'react'
import { useHotkeys } from 'react-hotkeys-hook'
import { track, useEditor } from 'tldraw'
import { Key } from 'ts-key-enum'
import { useToast } from '../../ui/toast'
import { ToolbarTooltip } from '../../ui/toolbar/tooltip'
import { t } from '../../util/intl/t'

export const AnnotToolbarDelete = track((): ReactElement => {
  const { toast } = useToast()
  const editor = useEditor()

  const fn = () => {
    // Heads up: This intentionally deletes all types of shapes.
    // It includes not only annotations but also predict shapes.
    // @TODO: Move this to somewhere more generic?
    const shapes = editor.getSelectedShapes()
    if (shapes.length === 0)
      return toast(t('annot.toolbar.delete-none'))
    editor.mark()
    editor.deleteShapes(shapes)
  }

  useHotkeys([Key.Delete, Key.Backspace], fn)

  return (
    <ToolbarTooltip
      relationship="description"
      kbd="⌫"
      content={t('annot.toolbar.delete')}
    >
      <ToolbarButton icon={<Delete20Regular />} onClick={fn}>
        {t('annot.toolbar.delete')}
      </ToolbarButton>
    </ToolbarTooltip>
  )
})
