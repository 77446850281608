import { makeStyles, shorthands, tokens } from '@fluentui/react-components'

const useStyles = makeStyles({
  space: {
    ...(shorthands.flex(1, 0, 'auto')),
    width: tokens.spacingHorizontalXXXL,
  },
})

export function ToolbarSpace(): JSX.Element {
  const s = useStyles()

  return <div className={s.space} />
}
