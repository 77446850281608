import { ToolbarRadioButton, makeStyles } from '@fluentui/react-components'
import type { ReactElement, ReactNode } from 'react'
import { useHotkeys } from 'react-hotkeys-hook'
import { track, useEditor } from 'tldraw'
import { ToolbarTooltip } from '../../ui/toolbar/tooltip'

const useStyles = makeStyles({
  icon: {
    // Reset the brand colour at rest so it looks like other toolbar buttons.
    // ToolbarRadioButton is built on top of ToggleButton,
    // which has a brand colour by default (at rest) that we don't want here.
    // See https://github.com/microsoft/fluentui/pull/30775
    color: 'inherit',
  },
})

export const EditorToolButton = track((props: {
  id: string
  children: string
  icon: ReactNode
  kbd?: string
  iconOnly?: boolean
}): ReactElement => {
  const { kbd, id, children, icon, iconOnly } = props

  const editor = useEditor()
  const s = useStyles()

  useHotkeys(
    // Hooks cannot be called conditionally...
    kbd ?? 'never',
    () => editor.setCurrentTool(id),
    // ...so this is the actual condition.
    { enabled: kbd !== undefined },
  )

  return (
    <ToolbarTooltip
      relationship={iconOnly ? 'label' : 'description'}
      content={children}
      kbd={kbd ? kbd.toUpperCase() : undefined}
    >
      <ToolbarRadioButton
        name="tool"
        value={id}
        appearance="subtle"
        size="medium"
        icon={{ className: s.icon, children: icon }}
      >
        {iconOnly ? null : children}
      </ToolbarRadioButton>
    </ToolbarTooltip>
  )
})
