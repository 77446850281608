import { ToolbarGroup, makeStyles, shorthands, tokens } from '@fluentui/react-components'
import type { ReactElement } from 'react'
import { useState } from 'react'
import { ToolbarLabel } from '../../ui/toolbar/label'
import { t } from '../../util/intl/t'
import { PaperMenu } from '../paper/menu'
import { PaperScale } from '../paper/scale'
import { usePaper } from '../paper/state'
import { useScaleDisplay } from '../scale/display'
import { ScaleMenu } from '../scale/menu'

const useStyles = makeStyles({
  group: {
    display: 'flex',
    alignItems: 'center',
    ...(shorthands.gap(tokens.spacingHorizontalXXS)),
  },
})

export function PageToolbarScale(): ReactElement {
  const [adjust, setAdjust] = useState(false)
  const s = useStyles()
  const { paper, updatePaper } = usePaper()
  const { display, setDisplay, displayPending } = useScaleDisplay()

  return (
    // We use "ToolbarGroup" mainly for semantic and a11y reasons.
    // There's no built-in style there, but there's built-in a11y messages.
    <ToolbarGroup className={s.group}>
      <ToolbarLabel>{t('scale.idle.title')}</ToolbarLabel>
      <PaperScale
        open={adjust}
        setOpen={setAdjust}
      />
      <PaperMenu
        name={paper.name}
        setName={name => updatePaper({ name })}
      />
      <ScaleMenu
        display={display}
        setDisplay={setDisplay}
        adjust={() => setAdjust(true)}
        pending={displayPending}
      />
    </ToolbarGroup>
  )
}
