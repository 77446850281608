import { Card, TabList, makeStyles, shorthands, tokens } from '@fluentui/react-components'
import type { ReactElement } from 'react'
import { AttrPanel } from '../../attr/panel'
import { HelpPanel } from '../../help/panel'
import { useSetting } from '../../setting/setting'
import { makeTabButton } from '../../ui/tab'
import { t } from '../../util/intl/t'
import type { AppSecondaryTab } from './tab'
import { appSecondaryTabSchema } from './tab'

const TabButton = makeTabButton<AppSecondaryTab>()

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    ...(shorthands.gap(tokens.spacingVerticalXS)),
    height: '100%',
  },
  card: {
    height: '100%',
    ...(shorthands.overflow('auto')),
  },
})

export function AppSecondary(): ReactElement {
  const { setting, updateSetting } = useSetting()

  const s = useStyles()

  return (
    <div className={s.container}>
      <TabList
        size="small"
        selectedValue={setting.secondary}
        onTabSelect={(_event, data) => {
          const secondary = appSecondaryTabSchema.parse(data.value)
          updateSetting({ secondary })
        }}
        reserveSelectedTabSpace
      >
        <TabButton value="attr">{t('app.tab.attr')}</TabButton>
        <TabButton value="help">{t('app.tab.help')}</TabButton>
      </TabList>
      <Card appearance="filled" size="small" className={s.card}>
        {setting.secondary === 'attr' ? <AttrPanel /> : null}
        {setting.secondary === 'help' ? <HelpPanel /> : null}
      </Card>
    </div>
  )
}
