import { makeStyles, shorthands, tokens } from '@fluentui/react-components'
import type { ReactNode } from 'react'

const useStyles = makeStyles({
  card: {
    display: 'flex',
    flexDirection: 'column',
    ...shorthands.gap(tokens.spacingVerticalS),
  },
})

export function HelpCard(props: {
  // Enforce to be HelpCanvas and HelpText only?
  children: ReactNode[]
}): JSX.Element {
  const { children } = props

  const s = useStyles()

  return (
    <div className={s.card}>
      {children}
    </div>
  )
}
