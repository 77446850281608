import type { BoxShape, Circle, CustomShape, ElectricPower, InsulationThickness, Rectangle, RefrigerantPipeShape, Shape } from '../../../util/data/server'

export type AttrShapeValue = string

export const ATTR_SHAPE_DEFAULT = ''

type RawValue =
  | { shape: 'box', payload: BoxShape }
  | { shape: 'circle', payload: Circle }
  | { shape: 'custom', payload: CustomShape }
  | { shape: 'electric', payload: ElectricPower }
  | { shape: 'rectangle', payload: Rectangle }
  | { shape: 'refrigerant', payload: RefrigerantPipeShape }
  | { shape: 'insulation', payload: InsulationThickness }

export function parseShapeValue(raw: RawValue): AttrShapeValue {
  const { payload: p, shape } = raw

  // Technically we have several types of shape, but we only use "custom" to
  // store data (see "page/sync"). The rest of them are mainly used for
  // options (see "shape/option").
  switch (shape) {
    // For data storing
    case 'custom': return p.size
    // For options
    case 'box': return `${p.width} ${p.height} ${p.length}`
    case 'circle': return `${p.diameter}`
    case 'electric': return `${p.kilowatt}`
    case 'rectangle': return `${p.width} ${p.length}`
    case 'refrigerant': return `${p.liquidPipeDiameter} ${p.suctionPipeDiameter}`
    case 'insulation': return `${p.thickness}`
  }
}

/**
 * Server supports several types of shape, but to simplify FE we only use
 * specific shapes as suggestions (see "shape/option"), and always use a custom
 * shape (string) for data storing.
 */
export function toServerShape(value: AttrShapeValue): Shape {
  return {
    kind: 'CustomShape',
    data: { size: value } satisfies CustomShape,
  }
}

export function fromServerShape(server: Shape | null): AttrShapeValue {
  if (server === null)
    return ATTR_SHAPE_DEFAULT
  // We only store data as CustomShape. See "toServerShape".
  if (server.kind === 'CustomShape')
    return (server.data as CustomShape).size
  return ATTR_SHAPE_DEFAULT
}
