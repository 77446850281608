import { makeStyles, tokens } from '@fluentui/react-components'
import { makeHelpAnimation } from '../../animation'

const useStyles = makeStyles({
  line: {
    position: 'absolute',
    top: '16px',
    left: '5px',
    width: '40px',
    height: tokens.spacingVerticalS,
    backgroundColor: tokens.colorBrandForeground1,
    transformOrigin: 'center left',
    ...makeHelpAnimation([{
      states: { off: { opacity: 0 }, on: { opacity: 0.5 } },
      keyframes: [[0, 'on'], [9, 'on'], [10, 'off']],
    }, {
      states: { empty: { transform: 'scaleX(0)' }, full: { transform: 'scaleX(1)' } },
      keyframes: [[0, 'empty'], [2, 'empty'], [6, 'full'], [10, 'full']],
    }]),
  },
})

export function HelpFireSizeScale(): JSX.Element {
  const s = useStyles()

  return <div className={s.line} />
}
