import type { BoxLike, TLDefaultColorStyle, TLShapeId } from 'tldraw'
import { createShapeId } from 'tldraw'
import { ATTR_EQUIP_FIRE_PIECES } from '../../../attr/field/equip/value'
import type { AttrValue } from '../../../attr/state/context'
import { ATTR_FIELDS_DEFAULT } from '../../../attr/state/context'
import { ANNOT_SHAPE_BASE } from '../../shape/base'
import { randomAnnotShapeColor } from '../../shape/color'
import type { PieceBoxShapePartial } from './shape'

type PrevShape = {
  w: number
  h: number
  createdAt: string
}

export function createPieceBoxShape(props: {
  box: BoxLike
  //
  id: TLShapeId | null
  color: TLDefaultColorStyle | null
  group: string | null
  prevShape: PrevShape | null
}): PieceBoxShapePartial {
  const { box, id, group, color, prevShape } = props

  const { x, y, w, h } = box

  const piece: PieceBoxShapePartial = {
    ...(ANNOT_SHAPE_BASE),
    type: 'geo',
    id: id ?? createShapeId(),
    x,
    y,
    opacity: 1,
    props: {
      geo: 'rectangle',
      dash: 'solid',
      fill: 'none',
      size: 's',
      color: color ?? randomAnnotShapeColor(),
      w: prevShape?.w ?? w,
      h: prevShape?.h ?? h,
    },
    meta: {
      type: 'annot',
      group: group ?? crypto.randomUUID(),
      annot: 'piece',
      shape: 'box',
      createdAt: prevShape?.createdAt ?? new Date().toISOString(),
    },
  }

  return piece
}

export function createPieceBoxAttr(): AttrValue {
  return {
    ...(ATTR_FIELDS_DEFAULT),
    equip: ATTR_EQUIP_FIRE_PIECES[0],
  }
}
