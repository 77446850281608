import type { TLGeoShape, TLShape } from 'tldraw'
import { isGeoShape } from '../../editor/shape/geo'

export type PredictAreaShapeMeta = {
  type: 'predict-area'
  id: string
  busy: boolean
}

export type PredictAreaShape =
  TLGeoShape
  & { meta: PredictAreaShapeMeta }

export function isPredictAreaShape(shape: TLShape): shape is PredictAreaShape {
  const test = shape as PredictAreaShape
  // Practically, PredictShape is not just any "geo" but a "rectangle" one,
  // but we should always rely on "meta" type as it's much more reliable.
  return isGeoShape(test) && test.meta.type === 'predict-area'
}

/**
 * Update tldraw's GeoShape to our PredictShape,
 * both visually ("props") and functionally ("meta").
 * Note that this does not create a new shape (i.e., no new shape ID),
 * but return the same shape modified (i.e., same shape ID).
 */
export function toPredictAreaShape(geo: TLGeoShape): PredictAreaShape {
  const area: PredictAreaShape = {
    ...geo,
    // Opacity 1 + solid fill means the background is not transparent.
    // We will manually control both the fill and border manually via CSS.
    opacity: 1,
    props: {
      ...geo.props,
      geo: 'rectangle',
      color: 'blue',
      dash: 'solid',
      fill: 'solid',
      size: 's',
    },
    meta: {
      type: 'predict-area',
      id: crypto.randomUUID(),
      busy: false,
    },
  }

  return area
}
