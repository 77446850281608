import { z } from 'zod'
import { useDataLocal } from '../../util/data/local'
import { usePage } from '../state/context'
import { PAGE_ID } from '../state/id'
import { paperNameSchema } from './name'
import { getPaperBySize } from './size'

const schema = z.object({
  name: paperNameSchema,
  /** mm per px */
  scale: z.number(),
})

type Paper = z.infer<typeof schema>

const SCALE_DEFAULT: number = (() => {
  const pxPerInch = 72
  const mmPerInch = 25.4
  const mmPerPx = mmPerInch / pxPerInch
  return mmPerPx
})()

export function usePaper(): {
  paper: Paper
  updatePaper: (update: Partial<Paper>) => void
} {
  const { pdf } = usePage()

  const name = getPaperBySize({
    min: Math.min(pdf.width, pdf.height),
    max: Math.max(pdf.width, pdf.height),
  })

  const [paper, updatePaper] = useDataLocal<Paper>({
    queryKey: ['paper'],
    localKey: `paper/${PAGE_ID}`,
    fallback: { name, scale: SCALE_DEFAULT },
    schema,
  })
  return { paper, updatePaper }
}
