import { z } from 'zod'

export const appToolbarTabSchema = z.enum([
  'page',
  'predict',
  'annot',
  'view',
])

export type AppToolbarTab = z.infer<typeof appToolbarTabSchema>

export const appPrimaryTabSchema = z.enum([
  'result',
  'list',
])

export type AppPrimaryTab = z.infer<typeof appPrimaryTabSchema>

export const appSecondaryTabSchema = z.enum([
  'attr',
  'help',
])

export type AppSecondaryTab = z.infer<typeof appSecondaryTabSchema>
