import { makeStyles, tokens } from '@fluentui/react-components'

const useStyles = makeStyles({
  group: {
    stroke: tokens.colorNeutralForeground1,
  },
})

export function HelpWaterSourceStatic(): JSX.Element {
  const s = useStyles()

  return (
    <svg height="100" width="220" xmlns="http://www.w3.org/2000/svg">
      <g className={s.group}>
        <circle r="5" cx="70" cy="20" fill="none" />
        <line x1="75" y1="20" x2="115" y2="20" />
        <circle r="5" cx="120" cy="20" fill="none" />
        <line x1="125" y1="20" x2="165" y2="20" />
        <circle r="5" cx="170" cy="20" fill="none" />
        <line x1="145" y1="20" x2="145" y2="80" />
      </g>
      <g className={s.group}>
        <circle r="5" cx="70" cy="60" fill="none" />
        <line x1="75" y1="60" x2="115" y2="60" />
        <circle r="5" cx="120" cy="60" fill="none" />
        <line x1="125" y1="60" x2="165" y2="60" />
        <circle r="5" cx="170" cy="60" fill="none" />

        <line x1="35" y1="80" x2="145" y2="80" />
        <line x1="35" y1="32" x2="35" y2="80" />

        <circle r="7" cx="35" cy="25" fill="none" />
        <polygon points="35,28, 39,22, 31,22" strokeWidth="1" fill="black" />
      </g>
    </svg>
  )
}
