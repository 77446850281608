import { SpinButton, makeStyles } from '@fluentui/react-components'
import type { ReactElement } from 'react'
import { useState } from 'react'
import { z } from 'zod'
import { useSetting } from '../../../setting/setting'
import { MenuRadio } from '../../../ui/menu'
import type { Option } from '../../../ui/option'
import { ToolbarLabel } from '../../../ui/toolbar/label'
import { t } from '../../../util/intl/t'
import type { SegmentSizeType } from './type'
import { segmentSizeTypeSchema } from './type'

const number = z.coerce.number()

const useStyles = makeStyles({
  button: {
    width: '120px',
  },
  input: {
    width: '50px',
  },
})

export function SegmentSizeMenu(): ReactElement {
  const s = useStyles()
  const { setting, updateSetting } = useSetting()

  const [size, setSize] = useState(setting.segmentSize.type === 'fixed'
    ? setting.segmentSize.px
    : 0)

  const OPTIONS: Option<SegmentSizeType>[] = [
    { label: t('annot.segment.scale'), value: 'scale' },
    { label: t('annot.segment.fixed'), value: 'fixed' },
  ]

  const setType = (type: SegmentSizeType): void => {
    if (type === 'fixed')
      updateSetting({ segmentSize: { type, px: size } })
    else
      updateSetting({ segmentSize: { type } })
  }

  const setPx = (value: string): void => {
    const { success, data: px } = number.safeParse(value)
    if (!success)
      return
    setSize(px)
    updateSetting({ segmentSize: { type: 'fixed', px } })
  }

  return (
    <>
      <ToolbarLabel>
        {t('annot.segment.label-size')}
      </ToolbarLabel>
      <MenuRadio
        value={setting.segmentSize.type}
        setValue={setType}
        displayFallback={null}
        //
        options={OPTIONS}
        parse={segmentSizeTypeSchema.parse}
        button={{
          appearance: 'subtle',
          className: s.button,
        }}
      />
      {setting.segmentSize.type === 'fixed' && (
        <SpinButton
          value={size}
          onChange={(_, data) => {
            if (data.value !== undefined && data.value !== null) {
              setPx(data.value.toString())
            }
            else if (data.displayValue !== undefined) {
              const newValue = Number.parseFloat(data.displayValue)
              if (Number.isNaN(newValue))
                return
              setPx(newValue.toString())
            }
          }}
          appearance="filled-darker"
          input={{ className: s.input }}
          min={0}
          step={0.1}
          autoFocus={size === 0}
        />
      )}
    </>
  )
}
