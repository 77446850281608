import { createContext, useContext } from 'react'
import type { SetState } from '../util/react/state'
import type { InsulAttrValue } from './value'

export type InsulAttrRecord = Record<
  string,
  InsulAttrValue | undefined
>

type InsulAttrContext = {
  attrs: InsulAttrRecord
  setAttrs: SetState<InsulAttrRecord>
}

export const insulAttrContext = createContext<InsulAttrContext>({
  attrs: {},
  setAttrs: () => { throw new Error('InsulAttrProvider is missing') },
})

export function useInsulAttrs(): InsulAttrContext {
  return useContext(insulAttrContext)
}
