import type { ReactElement, ReactNode } from 'react'
import { useState } from 'react'
import { usePage } from '../../page/state/context'
import type { AttrRecord } from './context'
import { attrContext } from './context'

export function AttrProvider(props: {
  children: ReactNode
}): ReactElement {
  const { children } = props

  const initial = usePage().annots.attrs
  const [attrs, setAttrs] = useState<AttrRecord>(initial)

  return (
    <attrContext.Provider value={{ attrs, setAttrs }}>
      {children}
    </attrContext.Provider>
  )
}
