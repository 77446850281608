import { useEffect } from 'react'
import type { Editor } from 'tldraw'

/**
 * Heads up! "select tool" is very (very!) complicated,
 * so we should avoid extending it at all costs.
 * Instead, here we're applying patches to the select tool.
 */
export function useEditorPatchSelect(props: { editor: Editor }): void {
  const { editor } = props

  useEffect(() => {
    try {
      // Disable creating text on double click
      // https://discord.com/channels/859816885297741824/926464446694580275/1125765402828484760
      editor.root.children!.select.children!.idle.onDoubleClick = () => void null
      // Disable adding text to shape. This is triggered by several interactions,
      // so it's better to cancel it by transitioning back to "idle".
      editor.root.children!.select.children!.editing_shape.onEnter = () => {
        editor.root.children!.select.transition('idle')
      }
    }
    catch (error) {
      // We could let it fails without caching,
      // but the erroris would be hard to read due to the nature of the patch.
      // This manual error makes it easier to spot the problem in the future.
      throw new Error('The hack to mute text shape does not work any more.')
    }
  }, [editor])
}
