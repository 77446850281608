import type { UseMutationResult, UseQueryResult } from '@tanstack/react-query'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import type { PageDetail } from '../../util/data/server'
import { server } from '../../util/data/server'
import { PAGE_ID } from '../state/id'

export type PageDetailUpdate = Partial<Parameters<typeof server.updatePage>[1]>

export type UsePageResult = {
  query: UseQueryResult<PageDetail>
  mutation: UseMutationResult<void, Error, PageDetailUpdate>
}

export function usePageDetail(props: { enable: boolean }): UsePageResult {
  const queryClient = useQueryClient()
  const queryKey = ['page']

  const query = useQuery<PageDetail>({
    queryKey,
    queryFn: async () => await server.getPageDetail(PAGE_ID, true),
    enabled: props.enable,
  })

  const mutation = useMutation({
    mutationFn: async (update: PageDetailUpdate) => {
      if (!query.data)
        throw new Error('page sever query is empty')

      await server.updatePage(PAGE_ID, {
        name: query.data.name,
        scale: query.data.scale,
        ...update,
      })
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey })
    },
  })

  return { query, mutation }
}
