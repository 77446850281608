import * as Sentry from '@sentry/react'

export function initError() {
  return Sentry.init({
    dsn: import.meta.env.VITE_SENTRY_DSN,
    environment: import.meta.env.VITE_RUNTIME_ENVIRONMENT,
    // For financial and clarity reasons, at the moment we skip auth and network errors,
    // because they happen quite frequent without practical downsides.
    // @TODO: However, in the future, we should also be aware of these errors.
    ignoreErrors: [/ApiError: Unauthorized/, /TypeError: Failed to fetch/],
    integrations: [
      Sentry.browserTracingIntegration(),
    ],
    tracesSampleRate: 1.0, // Capture 100% of the transactions
    enabled: import.meta.env.VITE_RUNTIME_ENVIRONMENT !== 'development',
    beforeBreadcrumb: (breadcrumb) => {
      if (breadcrumb.category === 'xhr')
        return null
      return breadcrumb
    },
    beforeSendSpan: (span) => {
      if (span.data === undefined)
        return span

      // We don't want to send cache spans to Sentry
      // to eliminate noise and reduce size of event.
      // https://develop.sentry.dev/sdk/envelopes/#size-limits
      if (span.data.type === 'xhr' && typeof span.data.url === 'string' && span.data.url.startsWith('data:application'))
        return null
      return span
    },
  })
}
