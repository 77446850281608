import type { ReactElement } from 'react'
import { track, useEditor } from 'tldraw'
import { HelpPointTranslate } from '../help/actor/point-translate'
import { HelpPolylineStatic } from '../help/actor/polyline-static'
import { HelpRectScale } from '../help/actor/rect-scale'
import { HelpCanvas } from '../help/card/canvas'
import { HelpCard } from '../help/card/card'
import { HelpText } from '../help/card/text'
import { t } from '../util/intl/t'
import { isPredictAreaShape } from './area/shape'
import { PredictAreaTool } from './area/tool'

export const PredictAreaHelp = track((): ReactElement | null => {
  const editor = useEditor()

  const shouldShow = false
    || editor.getCurrentToolId() === PredictAreaTool.id
    || editor.getCurrentPageShapes().some(isPredictAreaShape)

  if (!shouldShow)
    return null

  return (
    <HelpCard>
      <HelpText>
        {t('predict.help.1')}
      </HelpText>
      <HelpText>
        {t('predict.help.2')}
      </HelpText>
      <HelpCanvas>
        <HelpPolylineStatic />
        <HelpRectScale />
        <HelpPointTranslate target="rect" />
      </HelpCanvas>
      <HelpText>
        {t('predict.help.3')}
      </HelpText>
      <HelpText>
        {t('predict.help.4')}
      </HelpText>
    </HelpCard>
  )
})
