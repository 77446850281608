type Node<T> = T & {
  id: string | number
  children: Node<T>[]
}

export function findTreeNode<T>(
  node: Node<T>,
  id: string | number,
): Node<T> | null {
  if (node.id === id)
    return node

  for (const child of node.children) {
    const found = findTreeNode(child, id)
    if (found)
      return found
  }

  return null
}
