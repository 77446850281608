import { Box } from 'tldraw'
import type { AttrRecord } from '../../../attr/state/context'
import type { VerticalDirection } from '../../../util/data/server'
import type { SetState } from '../../../util/react/state'
import type { GeoToAnnot } from '../../shape/geo'
import { createSegmentGeoTool } from '../geo/tool'
import { createSegmentVerticalShape } from './create'

export const SEGMENT_VERTICAL_TOOL_IDS = {
  down: 'segment-vertical-down',
  up: 'segment-vertical-up',
} as const satisfies Record<VerticalDirection, string>

export function createSegmentVerticalTool(props: {
  setAttrs: SetState<AttrRecord>
  direction: VerticalDirection
}) {
  const { setAttrs, direction } = props

  const toAnnot: GeoToAnnot = (props) => {
    const { geo, prev } = props

    const box = new Box(geo.x, geo.y, geo.props.w, geo.props.h)

    const piece = createSegmentVerticalShape({
      center: box.center,
      direction,
      id: geo.id,
      color: prev?.props.color ?? null,
      group: prev?.meta.group ?? null,
      mm: null,
    })

    return piece
  }

  const id = SEGMENT_VERTICAL_TOOL_IDS[direction]
  return createSegmentGeoTool({ id, setAttrs, toAnnot, direction })
}
