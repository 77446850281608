import type { TLGeoShape } from 'tldraw'
import { Box } from 'tldraw'
import type { AttrRecord } from '../../../attr/state/context'
import type { SetState } from '../../../util/react/state'
import type { GeoToAnnot } from '../../shape/geo'
import { AnnotGeoTool } from '../../shape/geo'
import type { AnnotShape } from '../../shape/shape'
import { createPieceBoxAttr, createPieceBoxShape } from './create'
import { isPieceBoxShape } from './shape'

export const PIECE_BOX_TOOL_ID = 'piece-box'

const DEFAULT_PIECE_BOX_SIZE = 3

function getPrevGeo(props: {
  geo: TLGeoShape
  prev: AnnotShape | null
}): Box {
  const { geo, prev } = props

  const geoBox = new Box(geo.x, geo.y, geo.props.w, geo.props.h)
  const center = geoBox.center

  let [w, h] = [DEFAULT_PIECE_BOX_SIZE, DEFAULT_PIECE_BOX_SIZE] // Default size when there's no previous shape
  if (prev) {
    // See the comment at "isPrev" below
    if (!isPieceBoxShape(prev))
      throw new Error('Prev shape must be piece');
    [w, h] = [prev.props.w, prev.props.h]
  }

  return Box.FromCenter(center, { x: w, y: h })
}

export function createPieceBoxTool(props: {
  setAttrs: SetState<AttrRecord>
}) {
  const { setAttrs } = props

  return class HeadTool extends AnnotGeoTool {
    static override id = PIECE_BOX_TOOL_ID

    override followTool = null

    // @TODO: This should enforce prev's type?
    override isPrev(shape: AnnotShape) {
      return isPieceBoxShape(shape)
    }

    override createAttr(group: string): void {
      setAttrs(attrs => ({
        ...attrs,
        [group]: createPieceBoxAttr(),
      }))
    }

    override toAnnot: GeoToAnnot = (props) => {
      const { geo, prev, prevProps } = props

      const prevShape = prevProps ? { w: prevProps.w ?? DEFAULT_PIECE_BOX_SIZE, h: prevProps.h ?? DEFAULT_PIECE_BOX_SIZE, createdAt: new Date().toISOString() } : null

      const box = createPieceBoxShape({
        box: getPrevGeo({ geo, prev }),
        id: geo.id,
        color: prev?.props.color ?? null,
        group: prev?.meta.group ?? null,
        prevShape,
      })
      return box
    }
  }
}
