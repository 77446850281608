import { INTL_DICT_EN } from './dict/en'
import { INTL_DICT_JA } from './dict/ja'
import { locale } from './locale/type'

type DictId = keyof typeof INTL_DICT_EN

export function t(id: DictId) {
  const dict = locale === 'en' ? INTL_DICT_EN : INTL_DICT_JA
  return dict[id]
}
