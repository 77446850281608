import type { PredictModel } from '../model/option'
import { PREDICT_SYSTEMS, type PredictSystem } from './type'

type Group = {
  system: PredictSystem
  models: PredictModel[]
}

export function groupByPredictSystem(
  models: PredictModel[],
): Group[] {
  const groups = models.reduce<Group[]>((acc, model) => {
    const system = acc.find(g => g.system.value === model.system)

    if (system !== undefined) {
      // Found
      system.models.push(model)
    }
    else {
      // Not found
      const detail = PREDICT_SYSTEMS.find(s => s.value === model.system)
      if (detail === undefined)
        throw new Error(`Predict system not found: ${model.system}`)
      acc.push({ system: detail, models: [model] })
    }

    return acc
  }, [])
  return groups
}
