import { TabList } from '@fluentui/react-components'
import type { ReactElement } from 'react'
import { useSetting } from '../../setting/setting'
import { makeTabButton } from '../../ui/tab'
import { t } from '../../util/intl/t'
import type { AppToolbarTab } from '../panel/tab'
import { appToolbarTabSchema } from '../panel/tab'

const TabButton = makeTabButton<AppToolbarTab>()

export function AppToolbarMenu(): ReactElement {
  const { setting, updateSetting } = useSetting()

  return (
    <TabList
      size="small"
      selectedValue={setting.toolbar}
      onTabSelect={(_event, data) => {
        const toolbar = appToolbarTabSchema.parse(data.value)
        updateSetting({ toolbar })
      }}
      reserveSelectedTabSpace
    >
      <TabButton value="page">{t('app.tab.page')}</TabButton>
      <TabButton value="predict">{t('app.tab.predict')}</TabButton>
      <TabButton value="annot">{t('app.tab.annotate')}</TabButton>
      <TabButton value="view">{t('app.tab.view')}</TabButton>
    </TabList>
  )
}
