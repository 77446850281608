import { useEffect } from 'react'
import type { Editor } from 'tldraw'
import { usePage } from '../../page/state/context'
import type { EditorDocumentMeta } from '../util/document'
import { editorDocumentMetaSchema } from '../util/document'

/**
 * Sync page scale to the editor.
 *
 * This is required for our print module.
 * Usually page scale is availale via React context,
 * but tldraw's "to svg" happens outside of React's world,
 * so it can only access the scale from the editor instance.
 *
 * In all other cases, such as displaying segment length on the canvas,
 * we should always use the page scale from the context instead.
 */
export function useEditorSyncScale(props: { editor: Editor }): void {
  const { editor } = props

  const { scale } = usePage().page
  useEffect(() => {
    (window as any).editor = editor
    const raw = editor.getDocumentSettings().meta
    const prev = editorDocumentMetaSchema.parse(raw)
    const next: EditorDocumentMeta = { ...prev, scale }
    editor.updateDocumentSettings({ meta: next })
  }, [editor, scale])
}
