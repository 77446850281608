import { Card, makeStyles, shorthands } from '@fluentui/react-components'
import type { ReactNode } from 'react'

const useStyles = makeStyles({
  canvas: {
    ...shorthands.flex(0, 0, 'auto'),
    aspectRatio: '4 / 3',
    position: 'relative',
  },
  /**
   * Auto center actors by default. This means help's actors must have stable
   * width and height on their own.
   */
  actor: {
    transform: 'translate(-50%, -50%)',
    position: 'absolute',
    top: '50%',
    left: '50%',
    // Enforce child of "block"
    display: 'flex',
  },
})

export function HelpCanvas(props: {
  children: ReactNode
}): JSX.Element {
  const { children } = props

  const s = useStyles()

  if (!Array.isArray(children))
    throw new Error('HelpCanvas\'s children must be an array.')

  return (
    <Card appearance="filled" size="small">
      <div className={s.canvas}>
        {children.map((actor, index) => (
          <div className={s.actor} key={index}>
            {actor}
          </div>
        ))}
      </div>
    </Card>
  )
}
