import type { AnnotationData, PipeLineSegment, PipeLineSegmentExtension, PipeLineSegmentFlat, PipeLineSegmentVertical } from '../../util/data/server'
import { fromServerSegmentExtension, toServerSegmentExtension } from './extension/server'
import { isSegmentExtensionShape } from './extension/shape'
import { fromServerSegmentFlat, toServerSegmentFlat } from './flat/server'
import { isSegmentFlatShape } from './flat/shape'
import type { SegmentShape } from './shape'
import { fromServerSegmentVertical, toServerSegmentVertical } from './vertical/server'
import { isSegmentVerticalShape } from './vertical/shape'

export function fromServerSegment(props: {
  group: AnnotationData
  segment: PipeLineSegment
}) {
  const { segment, group } = props
  switch (segment.type) {
    case 'Flat': {
      const server = segment.inner as PipeLineSegmentFlat
      return fromServerSegmentFlat({ group, segment, server })
    }
    case 'Vertical': {
      const server = segment.inner as PipeLineSegmentVertical
      return fromServerSegmentVertical({ group, segment, server })
    }
    case 'Extension': {
      const server = segment.inner as PipeLineSegmentExtension
      return fromServerSegmentExtension({ group, segment, server })
    }
    case 'Extra':
      throw new Error('@TODO')
  }
}

export function toServerSegment(segment: SegmentShape): PipeLineSegment {
  if (isSegmentFlatShape(segment))
    return toServerSegmentFlat(segment)
  if (isSegmentVerticalShape(segment))
    return toServerSegmentVertical(segment)
  if (isSegmentExtensionShape(segment))
    return toServerSegmentExtension(segment)
  throw new Error('Unknown segment type')
}
