import { makeStyles } from '@fluentui/react-components'
import type { ReactElement, ReactNode } from 'react'
import { EditorShapeStroke } from '../../editor/shape/stroke'
import { useEditorTheme } from '../../editor/util/theme'
import { ANIMATION_TIMINGS } from '../../ui/animation'
import type { PredictAreaShape } from './shape'

// https://github.com/frenic/csstype?tab=readme-ov-file#what-should-i-do-when-i-get-type-errors
declare module 'react' {
  // eslint-disable-next-line ts/consistent-type-definitions
  interface CSSProperties {
    '--pac-fill-1'?: string
    '--pac-fill-2'?: string
  }
}

const segments = [
  'transparent',
  'var(--pac-fill-1)',
  'var(--pac-fill-2)',
  'transparent',
]
  // Create transition "segments".
  //
  // Each segment is a pair of stops,
  // so they fill the user's viewport with a solid background for a moment,
  // and create a smoother start.
  .flatMap(stop => [stop, stop])

const useStyles = makeStyles({
  container: {
    '& svg path[fill]:not([fill=\'none\'])': {
      opacity: 0.25,
      // When the geo fill is "solid",
      // tldraw actually uses the "semi" colour to fill it,
      // which is hard to see with opacity.
      // This uses the "solid" colour instead, like the colour used at stroke.
      fill: 'var(--pac-fill-1)',
    },
  },
  busy: {
    // Background consists of segments, each consisting of 2 stops.
    backgroundImage: `linear-gradient(to bottom, ${segments.join(',')})`,
    // Zoom the background so user's viewport covers only 1 segment at a time.
    backgroundSize: `100% ${(segments.length - 1) * 100}%`,
    // Move the viewport to create the animation. At 100%, the user see the
    // last segment, which is identical to the first segment, creating a
    // seamless loop.
    animationName: {
      '0%': { backgroundPositionY: '0%' },
      '100%': { backgroundPositionY: '100%' },
    },
    animationDuration: `${segments.length / 2}s`,
    animationTimingFunction: ANIMATION_TIMINGS.easeInOutQuart,
    animationIterationCount: 'infinite',
    // Scan moves from top to bottom. We could reverse the stops and the
    // position, but it's easier to understand this way.
    animationDirection: 'reverse',
    opacity: 0.5,
  },
})

export function PredictAreaComponent(props: {
  original: ReactNode
  area: PredictAreaShape
}): ReactElement {
  const { original, area } = props

  const s = useStyles()
  const theme = useEditorTheme()

  return (
    <div
      className={s.container}
      style={{
        '--pac-fill-1': theme.blue.solid,
        '--pac-fill-2': theme['light-blue'].solid,
      }}
    >
      <EditorShapeStroke>
        {original}
      </EditorShapeStroke>
      {area.meta.busy && (
        <div
          className={s.busy}
          // @TODO: Why do we need to set width and height explicitly,
          // Instead of using position CSS?
          style={{
            width: area.props.w,
            height: area.props.h,
          }}
        />
      )}
    </div>
  )
}
