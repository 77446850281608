import { useEffect } from 'react'
import type { Editor } from 'tldraw'
import { SelectTool } from 'tldraw'

/**
 * Set initial settings.
 *
 * We could do this by preparing and passing a "store" at initial,
 * but that would require us to supply full records,
 * while here we only need to set some specific settings.
 */
export function useEditorInitSettings(props: { editor: Editor }): void {
  const { editor } = props

  useEffect(() => {
    editor.setCurrentTool(SelectTool.id)

    editor.user.updateUserPreferences({
      // It's important for us to disable edge scrolling,
      // because our users are expected to move back and forth
      // between the canvas and the panels,
      // sometimes in the middle of an ongoing interaction
      // (e.g., to enter vertical segment length).
      edgeScrollSpeed: 0,
    })

    editor.updateInstanceState({
      isToolLocked: false,
    })
  }, [editor])
}
