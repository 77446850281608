import { makeStyles, shorthands, tokens } from '@fluentui/react-components'
import { makeHelpAnimation } from '../../animation'

const useStyles = makeStyles({
  point: {
    position: 'absolute',
    top: '-35px',
    left: '-85px',
    width: tokens.spacingHorizontalXL,
    aspectRatio: 1,
    backgroundColor: tokens.colorBrandStroke2,
    ...(shorthands.border('2px', 'solid', tokens.colorBrandForeground1)),
    borderRadius: '50%',
    ...(makeHelpAnimation([{
      states: {
        L: { transform: 'scale(1.25)', backgroundColor: tokens.colorBrandStroke2 },
        S: { transform: 'scale(1)', backgroundColor: tokens.colorBrandForeground1 },
      },
      keyframes: [[0, 'L'], [2, 'L'], [3, 'S'], [4, 'L'], [10, 'L']],
    }, {
      states: { on: { opacity: 0.75 }, off: { opacity: 0 } },
      keyframes: [[0, 'off'], [1, 'on'], [5, 'on'], [6, 'off'], [10, 'off']],
    }])),
  },
})

export function HelpWatersourcePoint(): JSX.Element {
  const s = useStyles()

  return <div className={s.point} />
}
