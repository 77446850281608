import { Menu, MenuButton, MenuItemRadio, MenuList, MenuPopover, MenuTrigger } from '@fluentui/react-components'
import { ChevronDown12Filled, DocumentLandscape20Regular } from '@fluentui/react-icons'
import type { ReactElement } from 'react'
import { z } from 'zod'
import type { PaperName } from './name'
import { paperNameSchema } from './name'

const OPTIONS: PaperName[] = ['A0', 'A1', 'A2', 'A3', 'A4']

export function PaperMenu(props: {
  name: PaperName
  setName: (name: PaperName) => void
}): ReactElement {
  const { name, setName } = props

  return (
    <Menu
      checkedValues={{ name: [name] }}
      onCheckedValueChange={(_event, data) => {
        z.literal('name').parse(data.name)
        const raw = data.checkedItems.at(0)
        setName(paperNameSchema.parse(raw))
      }}
      hasCheckmarks
    >
      <MenuTrigger disableButtonEnhancement>
        <MenuButton
          menuIcon={<ChevronDown12Filled />}
          appearance="subtle"
          icon={<DocumentLandscape20Regular />}
        >
          {name}
        </MenuButton>
      </MenuTrigger>
      <MenuPopover>
        <MenuList>
          {OPTIONS.map(option => (
            <MenuItemRadio key={option} name="name" value={option}>
              {option}
            </MenuItemRadio>
          ))}
        </MenuList>
      </MenuPopover>
    </Menu>
  )
}
