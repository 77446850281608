import { makeStyles, tokens } from '@fluentui/react-components'

const useStyles = makeStyles({
  group: {
    stroke: tokens.colorNeutralForeground1,
  },
  rect: {
    stroke: tokens.colorPaletteGreenBorder2,
    strokeWidth: '2',
  },
  line: {
    stroke: tokens.colorPaletteRedBorder2,
    strokeWidth: '2',
  },
})

export function HelpFireSizeStatic(): JSX.Element {
  const s = useStyles()

  return (
    <svg height="80" width="120" xmlns="http://www.w3.org/2000/svg">
      <g className={s.group}>
        <circle r="5" cx="10" cy="10" fill="none" />
        <rect width="14" height="14" fill="none" x="3" y="3" className={s.rect} />
        <line x1="15" y1="10" x2="55" y2="10" />
        <line x1="15" y1="11" x2="55" y2="11" className={s.line} />

        <circle r="5" cx="60" cy="10" fill="none" />
        <rect width="14" height="14" fill="none" x="53" y="3" className={s.rect} />
        <line x1="65" y1="10" x2="105" y2="10" />
        <line x1="65" y1="11" x2="105" y2="11" className={s.line} />

        <circle r="5" cx="110" cy="10" fill="none" />
        <rect width="14" height="14" fill="none" x="103" y="3" className={s.rect} />
        <line x1="61" y1="15" x2="61" y2="55" className={s.line} />
        <line x1="60" y1="15" x2="60" y2="55" />
      </g>
      <g className={s.group}>
        <circle r="5" cx="10" cy="60" fill="none" />
        <rect width="14" height="14" fill="none" x="3" y="53" className={s.rect} />
        <line x1="15" y1="60" x2="55" y2="60" />
        <line x1="15" y1="61" x2="55" y2="61" className={s.line} />

        <circle r="5" cx="60" cy="60" fill="none" />
        <rect width="14" height="14" fill="none" x="53" y="53" className={s.rect} />

        <circle r="5" cx="110" cy="60" fill="none" />
        <rect width="14" height="14" fill="none" x="103" y="53" className={s.rect} />
        <line x1="65" y1="60" x2="105" y2="60" />
      </g>
    </svg>
  )
}
