import type { ReactElement } from 'react'
import { track, useEditor } from 'tldraw'
import { isPredictAreaShape } from '../../../../predict/area/shape'
import { PredictAreaTool } from '../../../../predict/area/tool'
import { t } from '../../../../util/intl/t'
import { HelpCanvas } from '../../../card/canvas'
import { HelpCard } from '../../../card/card'
import { HelpText } from '../../../card/text'
import { HelpPointTranslate } from '../../point-translate'
import { HelpFireAreaScale } from '../area/scale'
import { HelpFireHeadStatic } from '../head/head-static'
import { HelpFireSizeScale } from './scale'
import { HelpFireSizeStatic } from './size-static'
import { HelpFireSizeTranslate } from './translate'

export const FireSizeHelp = track((): ReactElement | null => {
  const editor = useEditor()

  const shouldShow = false
    || editor.getCurrentToolId() === PredictAreaTool.id
    || editor.getCurrentPageShapes().some(isPredictAreaShape)

  if (!shouldShow)
    return null

  return (
    <HelpCard>
      <HelpText>
        {t('predict.fire-size-help.1')}
      </HelpText>
      <HelpText>
        {t('predict.fire-size-help.2')}
      </HelpText>
      <HelpText>
        {t('predict.fire-size-help.3')}
      </HelpText>
      <HelpCanvas>
        <HelpFireSizeStatic />
        <HelpFireSizeScale />
        <HelpFireSizeTranslate />
      </HelpCanvas>
      <HelpText>
        {t('predict.fire-size-help.4')}
      </HelpText>
      <HelpText>
        {t('predict.fire-size-help.5')}
      </HelpText>
      <HelpCanvas>
        <HelpFireHeadStatic />
        <HelpFireAreaScale />
        <HelpPointTranslate target="rect" />
      </HelpCanvas>
      <HelpText>
        {t('predict.fire-size-help.6')}
      </HelpText>
      <HelpText>
        {t('predict.fire-size-help.7')}
      </HelpText>
    </HelpCard>
  )
})
