import { useQuery } from '@tanstack/react-query'
import { usePage } from '../../../page/state/context'
import type { ConstructionModuleDetail } from '../../../util/data/server'
import { server } from '../../../util/data/server'

export type AttrCModuleValue = string

export const ATTR_C_MODULE_DEFAULT: AttrCModuleValue = ''

export type AttrCModuleOption = ConstructionModuleDetail

export function useAttrCModuleOptions(): AttrCModuleOption[] {
  const { constructionID } = usePage().page

  const query = useQuery({
    queryKey: ['attr-c-modules'],
    queryFn: () => server.listConstructionModules(constructionID),
  })

  return query.data ?? []
}
