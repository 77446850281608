import { Combobox, Option } from '@fluentui/react-components'
import { Ruler16Filled } from '@fluentui/react-icons'
import { Field } from '../../../ui/field'
import type { AttrSelection } from '../../../util/attr/selection'
import { getAttrSelectionValue, getAttrSelectionValueArray } from '../../../util/attr/selection'
import type { ShapeType } from '../../../util/data/server'
import { t } from '../../../util/intl/t'
import type { AttrShapeOptionsProps } from './suggestion'
import { useAttrShapeSuggestions } from './suggestion'
import type { AttrShapeValue } from './value'

type Props = AttrShapeOptionsProps & {
  shape: AttrSelection<AttrShapeValue>
  setShape: (shape: AttrShapeValue) => void
  label?: string
}

export function AttrShapeField(props: Props): JSX.Element {
  const { setShape, shape, equip, nodes, label } = props

  // Important: "shape" is a free-text field so we should always render this
  // field even if there's no suggestions.
  //
  // @TODO: When there is no suggestion, we should show a hint about the
  // "material" fields. Be mindful about the wording as the user may already
  // selected a "material" but that material does not have any shape
  // suggestions.
  const { suggestions, type } = useAttrShapeSuggestions({ equip, nodes })

  const SUFFIXES: Record<ShapeType, string> = {
    BoxShape: t('attr.shape.box'),
    Circle: t('attr.shape.circle'),
    CustomShape: t('attr.shape.custom'),
    ElectricPower: t('attr.shape.electric'),
    Rectangle: t('attr.shape.rectangle'),
    RefrigerantPipeShape: t('attr.shape.refrigerant'),
    InsulationThickness: t('attr.shape.insulation'),
  }

  return (
    <Field
      icon={icon => <Ruler16Filled className={icon.className} />}
      // It's ok to compose translated texts here because they are 2 separated
      // terms (main label and the size info). Also, suffix translations already
      // included a localed separator.
      //
      // @TODO: Show "unit".
      label={label ?? `${t('attr.shape.label')}${SUFFIXES[type]}`}
    >
      {control => (
        <Combobox
          value={getAttrSelectionValue(shape, t('attr.shape.mixed'))}
          selectedOptions={getAttrSelectionValueArray(shape)}
          onOptionSelect={(_event, data) => {
            if (data.optionValue)
              setShape(data.optionValue)
          }}
          onInput={(event) => {
            setShape(event.currentTarget.value)
          }}
          className={control.className}
          appearance={control.appearance}
        >
          {suggestions.map(suggestion => (
            <Option key={suggestion} value={suggestion}>
              {suggestion}
            </Option>
          ))}
        </Combobox>
      )}
    </Field>
  )
}
