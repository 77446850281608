import { BotSparkle20Filled, BotSparkle20Regular } from '@fluentui/react-icons'
import type { ReactElement } from 'react'
import { SelectTool, useEditor } from 'tldraw'
import { useSetting } from '../../setting/setting'
import { MenuRadio } from '../../ui/menu'
import { t } from '../../util/intl/t'
import { groupByPredictSystem } from '../system/group'
import type { PredictModelValue } from './option'
import { PREDICT_MODELS, predictModelValueSchema } from './option'

const OPTIONS = groupByPredictSystem(PREDICT_MODELS)

export function PredictModelSelect(props: {
  highlight: boolean
}): ReactElement {
  const { highlight } = props
  const editor = useEditor()
  const { setting, updateSetting } = useSetting()

  return (
    <MenuRadio<PredictModelValue>
      value={setting.predictModel}
      setValue={(model) => {
        updateSetting({ predictModel: model })
        editor.setCurrentTool(SelectTool.id)
      }}
      displayFallback={t('predict.model.action')}
      //
      groups={OPTIONS.map(group => ({
        label: group.system.label,
        options: group.models.map(model => ({
          label: model.label,
          value: model.value,
        })),
      }))}
      parse={predictModelValueSchema.parse}
      button={{
        appearance: highlight ? 'primary' : 'subtle',
        icon: highlight ? <BotSparkle20Filled /> : <BotSparkle20Regular />,
      }}
    />
  )
}
