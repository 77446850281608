import type { ReactElement } from 'react'
import { track, useEditor } from 'tldraw'
import { HelpLineScale } from '../../help/actor/line-scale'
import { HelpLineStatic } from '../../help/actor/line-static'
import { HelpPointTranslate } from '../../help/actor/point-translate'
import { HelpTextStatic } from '../../help/actor/text-static'
import { HelpCanvas } from '../../help/card/canvas'
import { HelpCard } from '../../help/card/card'
import { HelpText } from '../../help/card/text'
import { t } from '../../util/intl/t'
import { isScaleShape } from './shape'
import { ScaleTool } from './tool'

export const ScaleHelp = track((): ReactElement | null => {
  const editor = useEditor()

  const shouldShow = false
    || editor.getCurrentToolId() === ScaleTool.id
    || editor.getCurrentPageShapes().some(isScaleShape)

  if (!shouldShow)
    return null

  return (
    <HelpCard>
      <HelpText>
        {t('scale.help.1')}
      </HelpText>
      <HelpText>
        {t('scale.help.2')}
      </HelpText>
      <HelpCanvas>
        <HelpLineStatic />
        <HelpLineScale />
        <HelpPointTranslate target="line" />
        <HelpTextStatic>1,500</HelpTextStatic>
      </HelpCanvas>
      <HelpText>
        {t('scale.help.3')}
      </HelpText>
      <HelpText>
        {t('scale.help.4')}
      </HelpText>
    </HelpCard>
  )
})
