import {
  Button,
  Menu,
  MenuItem,
  MenuList,
  MenuPopover,
  MenuTrigger,
} from '@fluentui/react-components'
import { MoreVertical16Regular } from '@fluentui/react-icons'
import type { ReactElement } from 'react'
import { useEditor } from 'tldraw'
import type { AttrRecord } from '../../../attr/state/context'
import { t } from '../../../util/intl/t'
import { isAnnotShape } from '../../shape/shape'

export function AnnotGroupMenu(props: {
  group: string
  equip: number
  attrs: AttrRecord
}): ReactElement {
  const { group, equip, attrs } = props
  const editor = useEditor()
  const annots = editor.getCurrentPageShapes().filter(isAnnotShape)

  // `otherGroups` contains the annotations that are not in the current group
  const otherGroups = annots.filter(shape => shape.meta.group !== group)

  // `otherEquipments` contains the equipment types from other groups
  const otherEquipments = otherGroups.filter(
    shape => attrs[shape.meta.group]?.equip !== equip,
  )

  return (
    <Menu>
      <MenuTrigger>
        <Button
          appearance="subtle"
          size="small"
          icon={<MoreVertical16Regular />}
        />
      </MenuTrigger>
      <MenuPopover>
        <MenuList>
          {/* <MenuItem
            onClick={() => {
              editor.updateShapes(
                otherGroups.map(shape => ({
                  ...shape,
                  isLocked: true,
                })),
              )
            }}
          >
            {t('annot.group.menu.lock')}
          </MenuItem> */}
          <MenuItem
            onClick={() => {
              editor.updateShapes(
                otherGroups.map(shape => ({
                  ...shape,
                  opacity: 0,
                  isLocked: true,
                })),
              )
            }}
          >
            {t('annot.group.menu.hide')}
          </MenuItem>
          <MenuItem
            onClick={() => {
              editor.updateShapes(
                otherEquipments.map(shape => ({
                  ...shape,
                  opacity: 0,
                  isLocked: true,
                })),
              )
            }}
          >
            {t('annot.group.menu.hide-equipments')}
          </MenuItem>
        </MenuList>
      </MenuPopover>
    </Menu>
  )
}
