import { closestChild } from '../../util/web/array'
import type { PaperName } from './name'
import { paperNameSchema } from './name'

type Size = {
  min: number
  max: number
}

export const PAPER_SIZES: Record<PaperName, Size> = {
  A0: { min: 2383, max: 3370 },
  A1: { min: 1683, max: 2383 },
  A2: { min: 1190, max: 1683 },
  A3: { min: 841, max: 1190 },
  A4: { min: 595, max: 841 },
}

export function getPaperBySize(target: Size): PaperName {
  const closest = closestChild<[string, Size], Size>({
    array: Object.entries(PAPER_SIZES),
    distance: (paper, target) => {
      const [, size] = paper
      const min = Math.abs(size.min - target.min)
      const max = Math.abs(size.max - target.max)
      return min + max
    },
    target,
  })

  return paperNameSchema.parse(closest.at(0))
}
