export function randomNumber(from: number, to: number): number {
  if (from > to)
    throw new Error(`"from" must be smaller than "to".`)
  return Math.floor(Math.random() * (to - from + 1)) + from
}

export function randomBoolean(): boolean {
  return Math.random() > 0.5
}

export function roundLog(value: number, options: {
  base: number
}): number {
  const { base } = options

  if (value === 0)
    throw new Error('Can\'t calculate the log of zero')

  const magnitude = Math.floor(Math.log10(value))
  const target = base * (10 ** (magnitude - 1))
  const rounded = Math.round(value / target) * target

  return rounded
}
