import type { TLGeoShape, TLShape } from 'tldraw'
import type { AnnotMetaBase } from '../shape/base'
import { isAnnotShapeBase } from '../shape/base'
import type { PieceBoxShape, PieceBoxShapePartial } from './box/shape'
import { isPieceBoxShape } from './box/shape'
import type { PieceCheckShape, PieceCheckShapePartial } from './check/shape'
import { isPieceCheckShape } from './check/shape'
import type { PieceCircleShape, PieceCircleShapePartial } from './circle/shape'
import { isPieceCircleShape } from './circle/shape'

export type PieceMetaBase = AnnotMetaBase & {
  annot: 'piece'
}
export type PieceShapeBase = TLGeoShape & { meta: PieceMetaBase }

export type PieceShape = PieceBoxShape | PieceCheckShape | PieceCircleShape

export type PieceShapePartial = PieceBoxShapePartial | PieceCheckShapePartial | PieceCircleShapePartial

export function isPieceShapeBase(
  shape: TLShape,
): shape is PieceShapeBase {
  if (!isAnnotShapeBase(shape))
    return false
  const test = shape as PieceShapeBase
  return test.meta.annot === 'piece'
}

export function isPieceShape(shape: TLShape): shape is PieceShape {
  return false
    || isPieceBoxShape(shape)
    || isPieceCheckShape(shape)
    || isPieceCircleShape(shape)
}
