import { z } from 'zod'
import type { Option } from '../../../ui/option'
import { t } from '../../../util/intl/t'

const schema = z.enum(['Sub', 'Main'])

// @TODO: This key is too generic. Should consider something more specific.
export const ATTR_FIRE_PIPE_METADATA_KEY = 'type'

export type AttrFirePipeValue = z.infer<typeof schema>

// @TODO: Double check whether the default value should be "null" or "None" instead
export const ATTR_FIRE_PIPE_DEFAULT: AttrFirePipeValue = 'Sub'

export const parseAttrFirePipe = schema.parse

export const ATTR_FIRE_PIPE_OPTIONS: Option<AttrFirePipeValue>[] = [
  { label: t('attr.pipe-type.sub'), value: 'Sub' },
  { label: t('attr.pipe-type.main'), value: 'Main' },
]
