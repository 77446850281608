import { track, useEditor } from 'tldraw'
import { useEditorInitSettings } from './init-settings'
import { useEditorPatchSelect } from './patch-select'
import { useEditorSyncScale } from './sync-scale'
import { useEditorSyncShapes } from './sync-shapes'
import { useEditorSyncTheme } from './sync-theme'

export const EditorEffects = track((): null => {
  const editor = useEditor()

  useEditorInitSettings({ editor })
  useEditorPatchSelect({ editor })

  useEditorSyncShapes({ editor })
  useEditorSyncScale({ editor })
  useEditorSyncTheme({ editor })

  return null
})
