import type { OptionOnSelectData } from '@fluentui/react-components'
import { Dropdown, Option } from '@fluentui/react-components'
import type { ComponentProps, ReactElement } from 'react'
import { Field } from '../../../ui/field'
import { getStrict } from '../../web/primitive'
import type { AttrTreeDropdownValue } from './dropdown'
import type { AttrTreeValue } from './value'

type Props = {
  value: AttrTreeDropdownValue
  setValue: (value: AttrTreeValue) => void
  label: string
  icon: ComponentProps<typeof Field>['icon']
}

/**
 * AttrTreeField may return "null" because AttrTreeValue is an ID (number) that
 * only makes sense when come from a list of options.
 *
 * This is different than AttrShapeField where AttrShapeValue is a text value
 * itself (string), so the options are only suggestions.
 */
export function AttrTreeField(props: Props): ReactElement | null {
  const { value, setValue, icon, label } = props

  const onOptionSelect = (_event: unknown, data: OptionOnSelectData): void => {
    const text = getStrict(data.optionValue)
    const number = Number.parseInt(text)
    setValue(number)
  }

  return (
    <Field icon={icon} label={label}>
      {control => (
        <Dropdown
          value={value.display}
          selectedOptions={value.selectedOptions}
          onOptionSelect={onOptionSelect}
          className={control.className}
          appearance={control.appearance}
        >
          {value.options.map(option => (
            <Option
              key={option.value}
              value={option.value.toString()}
            >
              {option.label}
            </Option>
          ))}
        </Dropdown>
      )}
    </Field>
  )
}
