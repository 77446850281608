import { Card, makeStyles, tokens } from '@fluentui/react-components'
import type { ReactElement } from 'react'
import { DefaultCanvas } from 'tldraw'
import { EditorToolbar } from './toolbar/toolbar'
import { EditorZoomProvider } from './util/zoom'

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    gap: tokens.spacingVerticalXS,
  },
  toolbar: {
    flex: '0 0 auto',
  },
  canvas: {
    flex: '1 1 0px',
    minHeight: 0,
  },
})

export function EditorMain(): ReactElement {
  const s = useStyles()

  // This defines the slot for tldraw to put the editor canvas in.
  // The editor is actually created much sooner, as a provider.
  // To learn more, see "editor/provider".
  const canvas = <DefaultCanvas />

  return (
    <div className={s.container}>
      <div className={s.toolbar}>
        <EditorToolbar />
      </div>
      <Card appearance="filled" size="small" className={s.canvas}>
        <EditorZoomProvider>
          {canvas}
        </EditorZoomProvider>
      </Card>
    </div>
  )
}
