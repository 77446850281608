import { useQuery } from '@tanstack/react-query'
import type { EquipmentClass, LocaledEquipmentClass } from '../../../util/data/server'
import { server } from '../../../util/data/server'

export type AttrEquipValue = EquipmentClass

export type AttrEquipOption = LocaledEquipmentClass

export function useAttrEquipOptions(): AttrEquipOption[] {
  const query = useQuery({
    queryKey: ['attr-equips'],
    queryFn: () => server.listLocaledEquipmentClasses(),
  })

  return query.data ?? []
}

/**
 * "-1" is actually an invalid value (i.e., BE may reject it) but it's a good
 * default value because the user would see an empty dropdown and is encouraged
 * to fill in.
 *
 * We should not default to:
 * - "0", because it's an actual value of "Other", or
 * - Any positive value, because it could be in the wrong annotation type.
 *   (e.g. A "piece" annotation should never have "Duct" as the default).
 */
export const ATTR_EQUIP_DEFAULT: AttrEquipValue = -1

/**
 * This is a _short_ list of very important equipment classes,
 * to be used as constants in our code.
 * In most cases, we should "useAttrEquips".
 *
 * These constants are useful for:
 * - Mapping external services (e.g., AI responses), and
 * - Hard-coded data that are not available on server yet (e.g., attr types).
 */
export const ATTR_EQUIP_VALUES = {
  ROUND_DUCT: 1,
  VENT_CAP: 2,
  INSULATION: 3,
  REFRIGERANT_PIPE: 4,
  DRAIN_PIPE: 5,
  SANITARY_PIPE: 7,
  FIRE_PROTECTION_PIPE: 76,
  SPRINKLER_PIPE: 77,
  FP_DRAIN_PIPE: 78,
  INDOOR_FIRE_HYDRANT_PIPE: 79,
  OUTDOOR_FIRE_HYDRANT_PIPE: 80,
  FIRE_HYDRANT_CONNECTION_PIPE: 81,
  SPRINKLER_CONNECTION_PIPE: 82,
  FOAM_FIRE_EXTINGUISHING_PIPE: 83,
  SPRINKLER_HEAD: 84,
  CX_HEAD_III: 85,
  FOAM_HEAD_YFH59: 86,
  FOAM_HEAD_NORMAL: 87,
  SPRINKLER_HEAD_50: 88,
  FIRE_EXTINGUISHER: 89,
  FIRE_HYDRANT: 90,
  PILLAR_HYDRANTS: 91,
  SENSOR: 92,
  GAS_LEAK_DETECTOR: 93,
  //
  WATER_SOURCE: 105,
} satisfies Record<string, number>

/**
 * Fire-related pieces are pieces under the "fire" system.
 * They should behave like other pieces 80% of the time,
 * but sometimes we need special treatment for them.
 *
 * In the future, we will include the "system" as a field in the equip list,
 * instead of using this hard-coded list.
 */
export const ATTR_EQUIP_FIRE_PIECES = [
  ATTR_EQUIP_VALUES.SPRINKLER_HEAD,
  ATTR_EQUIP_VALUES.CX_HEAD_III,
  ATTR_EQUIP_VALUES.FOAM_HEAD_YFH59,
  ATTR_EQUIP_VALUES.FOAM_HEAD_NORMAL,
  ATTR_EQUIP_VALUES.SPRINKLER_HEAD_50,
  ATTR_EQUIP_VALUES.FIRE_EXTINGUISHER,
  ATTR_EQUIP_VALUES.FIRE_HYDRANT,
  ATTR_EQUIP_VALUES.PILLAR_HYDRANTS,
  ATTR_EQUIP_VALUES.SENSOR,
  ATTR_EQUIP_VALUES.GAS_LEAK_DETECTOR,
]

/**
 * See comments at ATTR_EQUIP_FIRE_PIECES.
 */
export const ATTR_EQUIP_FIRE_PIPES = [
  ATTR_EQUIP_VALUES.FIRE_PROTECTION_PIPE,
  ATTR_EQUIP_VALUES.SPRINKLER_PIPE,
  ATTR_EQUIP_VALUES.FP_DRAIN_PIPE,
  ATTR_EQUIP_VALUES.INDOOR_FIRE_HYDRANT_PIPE,
  ATTR_EQUIP_VALUES.OUTDOOR_FIRE_HYDRANT_PIPE,
  ATTR_EQUIP_VALUES.FIRE_HYDRANT_CONNECTION_PIPE,
  ATTR_EQUIP_VALUES.SPRINKLER_CONNECTION_PIPE,
  ATTR_EQUIP_VALUES.FOAM_FIRE_EXTINGUISHING_PIPE,
]

export function isFirePieceEquip(equip: number) {
  return ATTR_EQUIP_FIRE_PIECES.includes(equip)
}
