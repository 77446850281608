import { getStrict } from './primitive'

export type Canvas = {
  element: HTMLCanvasElement
  context: CanvasRenderingContext2D
}

export function getCanvas(id: string): Canvas {
  const element = document.getElementById(id)
  if ((element instanceof HTMLCanvasElement) === false)
    throw new Error(`There should be a canvas at "#${id}"`)
  const context = getStrict(element.getContext('2d'))
  return { element, context }
}

export function getCanvasBlob(props: {
  canvas: HTMLCanvasElement
  rect: DOMRect
}): Promise<Blob> {
  const { canvas } = props
  const { x, y, width: w, height: h } = props.rect

  const crop = document.createElement('canvas');
  [crop.width, crop.height] = [w, h]

  const context = getStrict(crop.getContext('2d'))
  context.drawImage(canvas, x, y, w, h, 0, 0, w, h)

  const promise = new Promise<Blob>((resolve) => {
    crop.toBlob((blob) => {
      resolve(getStrict(blob))
    })
  })
  return promise
}
