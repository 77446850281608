import { z } from 'zod'

const scaleLabelSchema = z.object({ type: z.literal('scale') })
const fixedLabelSchema = z.object({ type: z.literal('fixed'), px: z.number() })

export const segmentSizeSchema = z.union([
  scaleLabelSchema,
  fixedLabelSchema,
])

export const segmentSizeTypeSchema = z.enum(['scale', 'fixed'])

export type SegmentSize = z.infer<typeof segmentSizeSchema>
export type SegmentSizeType = z.infer<typeof segmentSizeTypeSchema>
