import type { MenuTriggerChildProps } from '@fluentui/react-components'
import { Menu, MenuButton, MenuDivider, MenuItem, MenuList, MenuPopover, MenuTrigger, ToolbarButton } from '@fluentui/react-components'
import { ArrowMaximizeVertical20Regular, ArrowMinimizeVertical20Regular, ChevronDown12Filled, Document10020Regular, DocumentLandscape20Regular, ZoomIn20Regular, ZoomOut20Regular } from '@fluentui/react-icons'
import { Fragment, type ReactElement } from 'react'
import { useHotkeys } from 'react-hotkeys-hook'
import type { TLCameraMoveOptions } from 'tldraw'
import { Box, track, useEditor } from 'tldraw'
import { usePage } from '../../page/state/context'
import { Kbd } from '../../ui/kbd'
import { useToast } from '../../ui/toast'
import { ToolbarTooltip } from '../../ui/toolbar/tooltip'
import { t } from '../../util/intl/t'
import { EDITOR_CAMERA } from '../util/camera'

const animation: TLCameraMoveOptions = {
  animation: {
    duration: 200,
  },
}

export const EditorToolbarZoom = track((): ReactElement => {
  const editor = useEditor()
  const { toast } = useToast()
  const { pdf } = usePage()

  const level = editor.getZoomLevel()

  const zoomIn = () => {
    level < EDITOR_CAMERA.zoomMax
      ? editor.zoomIn(editor.getViewportScreenCenter(), animation)
      : toast(t('editor.level.error-max'))
  }

  const zoomOut = () => {
    level > EDITOR_CAMERA.zoomMin
      ? editor.zoomOut(editor.getViewportScreenCenter(), animation)
      : toast(t('editor.level.error-min'))
  }

  const resetZoom = () => {
    level !== 1
      ? editor.resetZoom(editor.getViewportScreenCenter(), animation)
      : toast(t('editor.level.error-exact'))
  }

  const toSelection = () => {
    editor.getSelectedShapes().length > 0
      ? editor.zoomToSelection(animation)
      : toast(t('editor.target.error-none'))
  }

  const toContent = () => {
    editor.getCurrentPageShapeIds().size > 0
      ? editor.zoomToFit(animation)
      : toast(t('editor.target.error-empty'))
  }

  const toPage = () => {
    const box = new Box(0, 0, pdf.width, pdf.height)
    editor.zoomToBounds(box, animation)
  }

  useHotkeys('9', toPage)
  useHotkeys('8', toContent)
  useHotkeys('7', toSelection)

  useHotkeys('0', resetZoom)
  useHotkeys('minus', zoomOut)
  useHotkeys('equal', zoomIn)

  return (
    <>
      <Menu>
        <MenuTrigger disableButtonEnhancement>
          {(props: MenuTriggerChildProps<'button'>) => (
            <Fragment>
              <ToolbarTooltip relationship="label" content={t('editor.level.out')} kbd="-">
                <ToolbarButton icon={<ZoomOut20Regular />} onClick={zoomOut} />
              </ToolbarTooltip>
              <MenuButton {...props} menuIcon={<ChevronDown12Filled />} appearance="subtle">
                {`${Math.round(level * 100)}%`}
              </MenuButton>
              <ToolbarTooltip relationship="label" content={t('editor.level.in')} kbd="+">
                <ToolbarButton icon={<ZoomIn20Regular />} onClick={zoomIn} />
              </ToolbarTooltip>
            </Fragment>
          )}
        </MenuTrigger>
        <MenuPopover>
          <MenuList>
            <MenuItem
              icon={<Document10020Regular />}
              onClick={resetZoom}
              secondaryContent={<Kbd>0</Kbd>}
            >
              {t('editor.level.reset')}
            </MenuItem>
            <MenuDivider />
            <MenuItem
              icon={<DocumentLandscape20Regular />}
              onClick={toPage}
              secondaryContent={<Kbd>9</Kbd>}
            >
              {t('editor.target.page')}
            </MenuItem>
            <MenuItem
              icon={<ArrowMaximizeVertical20Regular />}
              onClick={toContent}
              secondaryContent={<Kbd>8</Kbd>}
            >
              {t('editor.target.shapes')}
            </MenuItem>
            <MenuItem
              icon={<ArrowMinimizeVertical20Regular />}
              onClick={toSelection}
              secondaryContent={<Kbd>7</Kbd>}
            >
              {t('editor.target.selection')}
            </MenuItem>
          </MenuList>
        </MenuPopover>
      </Menu>
    </>
  )
})
