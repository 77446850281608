import type { TLLineShape, TLShape } from 'tldraw'
import { isLineShape } from '../../editor/shape/line'

type Meta = {
  type: 'scale'
}

export type ScaleShape = TLLineShape & { meta: Meta }

export function isScaleShape(shape: TLShape): shape is ScaleShape {
  const test = shape as ScaleShape
  return isLineShape(test) && test.meta.type === 'scale'
}
