import type { GriffelStyle } from '@fluentui/react-components'
import { ANIMATION_TIMINGS } from '../../ui/animation'

type States = Record<string, GriffelStyle>

// @TODO: Keyframe is not enforced in type system?
type HelpAnimationStyle<S extends States> = {
  states: S
  keyframes: Array<[number, keyof S]>
}

// Not sure how to extract the single object, but it's easier to extract the
// array and get from there.
type Animation = Extract<GriffelStyle['animationName'], Array<any>>[number]

function makeKeyframes<S extends States>(style: HelpAnimationStyle<S>): Animation {
  const { keyframes, states } = style
  const an = keyframes.reduce(
    (acc, keyframe) => {
      const [time, name] = keyframe
      return { ...acc, [`${time * 10}%`]: states[name] }
    }
    , {} satisfies GriffelStyle['animationName'],
  )
  return an
}

export function makeHelpAnimation<S extends States>(
  /**
   * makeHelpAnimation supports an array of styles because it needs to merge
   * different animation into a single GriffelStyle to be spread at the
   * selector. In other words, using multiple makeHelpAnimation at one selector
   * is incorrect.
   */
  styles: HelpAnimationStyle<S>[],
): GriffelStyle {
  return {
    animationIterationCount: 'infinite',
    animationDuration: '5s',
    // https://easings.net/#easeInOutQuart
    animationTimingFunction: ANIMATION_TIMINGS.easeInOutQuart,
    animationName: styles.map(makeKeyframes),
  }
}
