import { Button, Dialog, DialogActions, DialogBody, DialogContent, DialogSurface, DialogTitle, DialogTrigger, Text, ToolbarButton } from '@fluentui/react-components'
import { Wand24Filled, Wand24Regular } from '@fluentui/react-icons'
import { useMutation } from '@tanstack/react-query'
import type { ReactElement } from 'react'
import { useState } from 'react'
import { usePage } from '../../page/state/context'
import { PAGE_ID } from '../../page/state/id'
import { useToast } from '../../ui/toast'
import { ApiError, server } from '../../util/data/server'
import { t } from '../../util/intl/t'
import { useOrgPlan } from './org'

type DialogProps = {
  highlight: boolean
}

type DisplayDialog = {
  type: 'alert' | 'modal'
  title: string
  content: ReactElement
}

function useDisplayDialog(): DisplayDialog | null {
  const { page } = usePage()
  const plan = useOrgPlan({ orgID: page.orgID }).data

  if (plan === undefined)
    return null

  if (plan.canUseFromPagePool) {
    return {
      type: 'alert',
      title: t('predict.pricing-plan.reached-limit'),
      content: <Text>{t('predict.pricing-plan.subtract-page')}</Text>,
    }
  }

  const content = plan.name === 'Trial'
    ? <Text>{t('predict.pricing-plan.contact-owner')}</Text>
    : plan.canUseFromBuffered
      ? <Text>{t('predict.pricing-plan.purchase-overage')}</Text>
      : <Text>{t('predict.pricing-plan.use-next-month')}</Text>

  const modal: DisplayDialog = {
    type: plan.name === 'Standard' && plan.canUseFromBuffered ? 'alert' : 'modal',
    title: t('predict.pricing-plan.reached-limit'),
    content,
  }

  return modal
}

function useExchangePageForAiPrediction() {
  const mutation = useMutation({
    mutationFn: (pageID: string) => server.exchangePageForAiPrediction(pageID),
  })
  return mutation
}

export function PlanExchangeButton(props: DialogProps): ReactElement | null {
  const { highlight } = props

  const [open, setOpen] = useState<boolean>(false)

  const dialog = useDisplayDialog()
  const { setLimit } = usePage()
  const exchange = useExchangePageForAiPrediction()
  const { toast } = useToast()

  if (dialog === null)
    return null

  const { content, title, type } = dialog

  const handleSubmit = () => {
    exchange.mutate(PAGE_ID, {
      onSuccess: async () => {
        setOpen(false)
        toast(t('predict.pricing-plan.exchange-page-successful'))

        await server.getPageDetail(PAGE_ID).then((data) => {
          if (data)
            setLimit({ aiPredictionRemaining: data.aiPredictionRemaining })
        })
      },
      onError: (error) => {
        setOpen(false)
        if (error instanceof ApiError && error.status === 422)
          toast(t('predict.pricing-plan.contact-owner'))
      },
    })
  }
  return (
    <>
      <ToolbarButton
        appearance={highlight ? 'primary' : 'subtle'}
        icon={highlight ? <Wand24Filled /> : <Wand24Regular />}
        onClick={() => setOpen(true)}
      >
        {t('predict.fetch.action')}
      </ToolbarButton>
      <Dialog open={open} onOpenChange={(_event, data) => setOpen(data.open)} modalType={type}>
        <DialogSurface>
          <DialogBody>
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
              {content}
            </DialogContent>
            <DialogActions>
              {type === 'alert' && (
                <Button
                  appearance="primary"
                  onClick={handleSubmit}
                >
                  {t('predict.pricing-plan.agree')}
                </Button>
              )}
              <DialogTrigger disableButtonEnhancement>
                <Button appearance="secondary">{t('predict.pricing-plan.close')}</Button>
              </DialogTrigger>
            </DialogActions>
          </DialogBody>
        </DialogSurface>
      </Dialog>
    </>
  )
}
