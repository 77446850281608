import type { TLLineShape, TLShape } from 'tldraw'
import type { EditorShapePartial } from '../../editor/shape/base'

type AnnotCutMeta = {
  type: 'cut'
}

export type AnnotCutShape = TLLineShape & {
  meta: AnnotCutMeta
}

export type AnnotCutPartial = EditorShapePartial<
  TLLineShape,
  AnnotCutMeta
>

export function isAnnotCutShape(
  shape: TLShape,
): shape is AnnotCutShape {
  const test = shape as AnnotCutShape
  return test.meta.type === 'cut'
}

export function convertToAnnotCutShape(line: TLLineShape): AnnotCutPartial {
  return {
    ...line,
    props: {
      ...line.props,
      // tldraw remembers the last used styles, so it's always best to set them
      // explicitly here.
      size: 's',
      color: 'black',
      dash: 'solid',
    },
    meta: {
      type: 'cut',
    },
  }
}
