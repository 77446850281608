import { Input, makeStyles, mergeClasses, shorthands, tokens } from '@fluentui/react-components'
import type { FormEventHandler } from 'react'
import { useState } from 'react'
import { SelectTool, track, useEditor } from 'tldraw'
import { z } from 'zod'
import { getLineShapeEdgeRelative } from '../../editor/shape/line'
import { useSetting } from '../../setting/setting'
import { ANIMATION_TIMINGS } from '../../ui/animation'
import { useToast } from '../../ui/toast'
import { ToolbarLabel } from '../../ui/toolbar/label'
import { t } from '../../util/intl/t'
import { usePage } from '../state/context'
import { isScaleShape } from './shape'

const useStyles = makeStyles({
  form: {
    display: 'flex',
    alignItems: 'center',
    ...(shorthands.gap(tokens.spacingHorizontalXXS)),
    ...(shorthands.outline(tokens.spacingVerticalS, 'solid', 'transparent')),
    outlineOffset: tokens.spacingVerticalXS,
    ...(shorthands.transition('outline', '0.5s', '0s', ANIMATION_TIMINGS.easeInOutQuart)),
  },
  highlight: {
    outlineWidth: tokens.spacingVerticalXXS,
    outlineColor: tokens.colorBrandStroke1,
  },
  label: {
    ...(shorthands.flex(0, 0, 'auto')),
  },
  input: {
    ...(shorthands.flex(1, 1, '0px')),
    minWidth: 0,
  },
})

export const ScaleForm = track((): JSX.Element => {
  const s = useStyles()
  const editor = useEditor()
  const [mm, setMM] = useState('')
  const { toast } = useToast()
  const { updateSetting } = useSetting()
  const { updatePage } = usePage()

  // Use "get current page" instead of "get selected",
  // because the user may no longer keep the selection on the "scale" shape.
  const shapes = editor.getCurrentPageShapes().filter(isScaleShape)

  const onSubmit: FormEventHandler = (event) => {
    event.preventDefault()

    // There may be more than 1 shape due to coding error,
    // but we should not yell about that here.
    const shape = shapes.at(0)
    if (shape === undefined)
      return void toast(t('scale.error.none'))
    const edge = getLineShapeEdgeRelative(shape)

    const real = z.coerce.number().safeParse(mm)
    if (real.success === false)
      return void toast(t('scale.error.number'))

    // Important: This is the _absolute_ scale, from digital px to life mm
    const scale = real.data / edge.length
    updatePage.mutate({ scale })

    // Clean up
    editor.deleteShapes(shapes)
    editor.setCurrentTool(SelectTool.id)
    updateSetting({ secondary: 'attr' })
  }

  return (
    <form
      onSubmit={onSubmit}
      className={mergeClasses(s.form, shapes.length > 0 ? s.highlight : '')}
    >
      <ToolbarLabel className={s.label}>
        {t('scale.measure.length')}
      </ToolbarLabel>
      <Input
        className={s.input}
        appearance="filled-darker"
        placeholder="1000"
        min={0}
        step={10}
        value={mm}
        onChange={(_event, data) => void setMM(data.value)}
      />
    </form>
  )
})
