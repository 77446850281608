import type { BoxLike, TLDefaultColorStyle, TLShapeId } from 'tldraw'
import { createShapeId } from 'tldraw'
import { ATTR_EQUIP_VALUES } from '../../../attr/field/equip/value'
import type { AttrValue } from '../../../attr/state/context'
import { ATTR_FIELDS_DEFAULT } from '../../../attr/state/context'
import { ANNOT_SHAPE_BASE } from '../../shape/base'
import { randomAnnotShapeColor } from '../../shape/color'
import type { PieceCheckShapePartial } from './shape'

export function createPieceCheckShape(props: {
  box: BoxLike
  //
  id: TLShapeId | null
  color: TLDefaultColorStyle | null
  group: string | null
}): PieceCheckShapePartial {
  const { box, id, group, color } = props

  const { x, y, w, h } = box

  const piece: PieceCheckShapePartial = {
    ...(ANNOT_SHAPE_BASE),
    type: 'geo',
    id: id ?? createShapeId(),
    x,
    y,
    props: {
      geo: 'check-box',
      dash: 'solid',
      fill: 'none',
      size: 's',
      color: color ?? randomAnnotShapeColor(),
      w,
      h,
    },
    meta: {
      type: 'annot',
      group: group ?? crypto.randomUUID(),
      annot: 'piece',
      shape: 'check-box',
    },
  }

  return piece
}

export function createPieceCheckAttr(): AttrValue {
  return {
    ...(ATTR_FIELDS_DEFAULT),
    // @TODO: Should not default to Vent Cap?
    equip: ATTR_EQUIP_VALUES.VENT_CAP,
  }
}
