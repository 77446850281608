const isMac: boolean = (() => {
  let platform: string
  try {
    platform = (navigator as any).userAgentData.platform
  }
  catch (error) {
    platform = navigator.userAgent.toLowerCase().includes('mac') ? 'macOS' : navigator.userAgent
  }
  return platform.toLowerCase().startsWith('mac')
})()

export const KEY_CMD_SHORT: string = isMac ? '⌘' : 'Ctrl'

export const KEY_CMD_LONG: string = isMac ? '⌘ Command' : 'Ctrl'

export const isEventCmdKey: (event: KeyboardEvent) => boolean = (event) => {
  return isMac ? event.metaKey : event.ctrlKey
}
