import { roundLog } from '../../util/web/math'
import type { PaperName } from '../paper/name'
import { PAPER_SIZES } from '../paper/size'
import { usePaper } from '../paper/state'
import { usePage } from '../state/context'

type Result = {
  display: number
  setDisplay: (display: number) => void
  displayPending: boolean
  setPaperScale: (next: {
    display: number
    viewport: PaperName
  }) => void
}

/**
 * "display" scale is the user-friendly number that we display on the toolbar,
 * such as "1:100" or "1:200". This is also on the PDF itself.
 *
 * There are 2 challenges here:
 * 1. We don't store "display" scale, because it's very hard to use.
 *    In our system, we store an "absolute" scale, in real life mm per px.
 * 1. The "display" scale depends on which paper the user wants to print on.
 *    For example, a "1:100" at A3 would be a "1:50" at A1.
 */
export function useScaleDisplay(): Result {
  const { page, updatePage, pdf } = usePage()
  const { paper, updatePaper } = usePaper()

  // real life mm per canvas px
  const absolute = page.scale
  // paper mm per canvas px
  const base = paper.scale
  // file size in px
  const file = Math.min(pdf.width, pdf.height)
  // viewing size in px
  const viewport = PAPER_SIZES[paper.name].min

  // display scale = mm scale * view ratio
  const exact = (absolute / base) * (file / viewport)
  /*
  exact / (file / viewport) = absolute / base
  (exact * viewport) / file = absolute / base
  base / absolute = file / (exact * viewport)
  */
  const rounded = exact === 0 ? 0 : roundLog(exact, { base: 1 })

  const setDisplay: Result['setDisplay'] = (nextDisplay) => {
    const nextAbsolute = (nextDisplay * base * viewport) / file
    updatePage.mutate({ scale: nextAbsolute })
  }

  const setPaperScale: Result['setPaperScale'] = (next) => {
    const viewport = PAPER_SIZES[next.viewport].min
    const nextBase = (file * absolute) / (next.display * viewport)
    updatePaper({ scale: nextBase })
  }

  return {
    display: rounded,
    setDisplay,
    displayPending: updatePage.isPending,
    setPaperScale,
  }
}
