import type { TLEditorComponents } from 'tldraw'
import { EditorBackground } from './background'
import { EditorBrush } from './brush'
import { EditorErrorFallback } from './error-fallback'
import { EditorOnTheCanvas } from './on-the-canvas'
import { EditorSelectionForeground } from './selection-foreground'

export const editorComponents: TLEditorComponents = {
  Background: EditorBackground,
  Brush: EditorBrush,
  ErrorFallback: EditorErrorFallback,
  OnTheCanvas: EditorOnTheCanvas,
  SelectionForeground: EditorSelectionForeground,
}
