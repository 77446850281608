import { useQuery } from '@tanstack/react-query'
import type { OrgStats } from '../../../generated/server/models/OrgStats'
import { server } from '../../util/data/server'

type Plan = {
  name: 'Standard' | 'Trial'
  canUseFromPagePool: boolean
  canUseFromBuffered: boolean
}

function toPlan(stas: OrgStats): Plan {
  return {
    name: stas.numSeat > 0 ? 'Standard' : 'Trial',
    canUseFromPagePool: stas.pagePoolCapacity - stas.pageUsed > 0,
    canUseFromBuffered: stas.maxOverPage - stas.numOverPage > 0,
  }
}

export function useOrgPlan(props: { orgID: string }) {
  const { orgID } = props

  const query = useQuery({
    queryKey: ['org-stats', orgID],
    queryFn: () => server.getOrgStats(orgID),
    select: data => toPlan(data),
  })

  return query
}
