import { makeStyles } from '@fluentui/react-components'
import type { ReactElement, ReactNode } from 'react'
import { EditorShapeHighlight } from '../../../editor/shape/highlight'
import type { SegmentVerticalShape } from './shape'

const useStyles = makeStyles({
  down: {
    // Technically we could rotate the svg container, but it already has a
    // "transform origin" applied to it, so it's safer to rotate the path
    // inside.
    '& svg path': {
      transform: 'rotate(180deg)',
      transformOrigin: 'center',
    },
  },
})

export function SegmentVerticalComponent(props: {
  original: ReactNode
  vertical: SegmentVerticalShape
}): ReactElement {
  const { original, vertical } = props

  const s = useStyles()

  return (
    <div className={vertical.meta.direction === 'down' ? s.down : ''}>
      <EditorShapeHighlight shape={vertical}>
        {original}
      </EditorShapeHighlight>
    </div>
  )
}
