import type { TLLineShape, TLShape } from 'tldraw'
import type { EditorShapePartial } from '../../../editor/shape/base'
import type { SegmentMetaBase } from '../shape'
import { isSegmentShapeBase } from '../shape'

export type SegmentFlatMeta = SegmentMetaBase & {
  segment: 'flat'
}

export type SegmentFlatShape = TLLineShape & {
  meta: SegmentFlatMeta
}

export type SegmentFlatPartial = EditorShapePartial<
  TLLineShape,
  SegmentFlatMeta
>

export function isSegmentFlatShape(
  shape: TLShape,
): shape is SegmentFlatShape {
  if (!isSegmentShapeBase(shape))
    return false
  const test = shape as SegmentFlatShape
  return test.meta.segment === 'flat'
}
