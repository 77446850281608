import type { Option } from '../../../ui/option'
import { toOptionLiteral } from '../../../ui/option'

export type AttrThicknessValue = string

export const ATTR_THICKNESS_DEFAULT: AttrThicknessValue = ''

export type AttrThicknessOption = Option

export const ATTR_THICKNESS_OPTIONS: AttrThicknessOption[] = [
  0,
  20,
  25,
  30,
  40,
  50,
  65,
  75,
  100,
].map(value => toOptionLiteral(value.toFixed(0)))
