import { ATTR_TYPE_DEFAULT } from '../../attr/field/type/value'
import type { AIOutputLabel, AIOutputLabeledBox } from '../../util/data/server'
import { getMostItem } from '../../util/web/array'

// Important, these 3 concepts must never be mixed:
// - ""
// - null and undefined
// - ATTR_TYPE_DEFAULT
// Using them incorrectly could cause subtle bugs that are very difficult to
// troubleshooting, such as an empty value overrides good values.

function toType(label: AIOutputLabel): string | null {
  // More common in pipelines
  if (label.equipment_type !== '')
    return label.equipment_type

  // More common in boxes
  const fromExtra = label.equipment_attributes_extra
    .filter(type => type !== '')
  return fromExtra.length > 0
    ? getMostItem(fromExtra)
    : null
}

export function parsePredictType(props: {
  /**
   * Technically we only need a single AIOutputLabel.
   * However, in practice we always start with a list of "boxes",
   * including in pipelines, so it's easier to cover that here.
   */
  boxes: AIOutputLabeledBox[]
}): string {
  const { boxes } = props

  const types = boxes
    .map(box => box.label ?? null)
    .filter((label): label is AIOutputLabel => label !== null)
    .map(label => toType(label))
    .filter((type): type is string => type !== null)

  return types.length > 0
    ? getMostItem(types)
    : ATTR_TYPE_DEFAULT
}
