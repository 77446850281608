import { makeStyles, mergeClasses } from '@fluentui/react-components'
import type { ReactElement, ReactNode } from 'react'
import { track, useEditor } from 'tldraw'
import type { AnnotShape } from '../../annot/shape/shape'
import { isAnnotShape } from '../../annot/shape/shape'
import { EDITOR_ZOOM_VARIABLE } from '../util/zoom'

const useStyles = makeStyles({
  highlight: {
    '& svg path[stroke]:not([stroke=\'none\'])': {
      strokeWidth: `calc(5 / var(${EDITOR_ZOOM_VARIABLE}))`,
    },
  },
  flash: {
    transitionTimingFunction: 'ease-out',
    animationIterationCount: 'infinite',
    animationDuration: '1.5s',
    animationName:
    {
      '0%': {
        opacity: 0,
      },
      '50%': {
        opacity: 1,
      },
      '100%': {
        opacity: 0,
      },
    },
  },
  default: {
    '& svg path[stroke]:not([stroke=\'none\'])': {
      strokeWidth: `calc(2 / var(${EDITOR_ZOOM_VARIABLE}))`,
    },
  },
})

export const EditorShapeHighlight = track((props: {
  shape: AnnotShape
  children: ReactNode
}): ReactElement | null => {
  const { shape, children } = props

  const editor = useEditor()
  const s = useStyles()

  const selectedShapes = editor.getSelectedShapes()
    .filter(isAnnotShape)

  const shapeIds = selectedShapes.map(shape => shape.id)

  const selectedShape = selectedShapes.at(0)

  // No annotations are selected, return the original shape in SVG
  if (selectedShape === undefined)
    return <div className={s.default}>{children}</div>

  const getClasses = () => {
    if (shapeIds.includes(shape.id))
      return mergeClasses(s.flash, s.highlight)

    if (selectedShape.meta.group === shape.meta.group)
      return s.highlight

    return s.default
  }

  return (
    <div className={getClasses()}>
      {children}
    </div>
  )
})
