/**
 * Convenient utility to use when a value could only be null or undefined due
 * to coding error. This is a better than TypeScript's "!" assertion in most
 * case as we could be wrong about our assertion, especially after a long time.
 *
 * Prefer Zod for primitive values.
 */
export function getStrict<Value>(value: Value): NonNullable<Value> {
  if (value === undefined)
    throw new Error('value must not be undefined')
  if (value === null)
    throw new Error('value must not be null')
  return value
}

/**
 * The counterpart of "getStrict" to be used in "Array.filter".
 */
export function onlyStrict<Value>(
  value: Value | undefined | null,
): value is Value {
  getStrict(value)
  // "strict" means throw error if "false".
  return true
}

/**
 * "string & {}" workaround for auto-complete. See:
 * - https://github.com/microsoft/TypeScript/issues/29729
 */
export type FamilyFriendlyString = (string & NonNullable<unknown>)

export function truncateMiddle(text: string, max: number) {
  if (text.length <= max)
    return text

  // Minus 1 character which is "…".
  const part = Math.floor((max - 1) / 2)
  const start = text.substring(0, part)
  const end = text.substring(text.length - part)
  return `${start}…${end}`
}

// @TODO getNumberStrict (not NaN)
