import { Button, makeStyles } from '@fluentui/react-components'
import type { Editor } from 'tldraw'
import { track, useEditor } from 'tldraw'
import { useEditorTheme } from '../../../editor/util/theme'
import { usePage } from '../../../page/state/context'
import { t } from '../../../util/intl/t'
import { getArraysDifferent, getArraysIntersect, getArraysUnion, getHeadStrict } from '../../../util/web/array'
import { isPieceShape } from '../../piece/shape'
import { getSegmentMmSize, isSegmentShape } from '../../segment/shape'
import type { AnnotShape } from '../../shape/shape'
import { isAnnotShape } from '../../shape/shape'

const useStyles = makeStyles({
  button: {
    minWidth: 'auto',
  },
})

function getValue(shapes: AnnotShape[], options: {
  scale: number
}): string {
  const { scale } = options

  if (shapes.length === 0)
    throw new Error('Shapes must have at least 1 shape.')

  if (shapes.every(isPieceShape))
    return `${shapes.length.toFixed(0)} ${t('annot.total.piece-unit')}`

  if (shapes.every(isSegmentShape)) {
    const mm = shapes.reduce((prev, shape) => {
      return prev + getSegmentMmSize(shape, { scale })
    }, 0)
    return `${(mm / 1000).toFixed(1)} ${t('annot.total.length-unit')}`
  }

  throw new Error('All annotation shapes must be of the same type.')
}

type Selection = {
  selected: boolean
  select: (options: { adding: boolean }) => void
}

function getSelection(props: {
  shapes: AnnotShape[]
  editor: Editor
}): Selection {
  const { shapes, editor } = props

  /** Shapes of this group */
  const groupIds = shapes.map(shape => shape.id)
  /** Selected shapes of the editor */
  const editorIds = editor.getSelectedShapes().filter(isAnnotShape).map(shape => shape.id)
  /** Selected shapes of this group */
  const selectedIds = getArraysIntersect(groupIds, editorIds)

  const some = selectedIds.length > 0
  const all = selectedIds.length === groupIds.length

  const select: Selection['select'] = (options) => {
    const { adding } = options
    if (!adding)
      return void editor.select(...groupIds)

    const ids = all
      ? getArraysDifferent(editorIds, groupIds) // un-select all
      : getArraysUnion([editorIds, groupIds]) // select all
    editor.select(...ids)
  }

  return { selected: some, select }
}

export const AnnotOverviewTotal = track((props: {
  shapes: AnnotShape[]
}): JSX.Element => {
  const { shapes } = props
  const head = getHeadStrict(shapes)

  const editor = useEditor()
  const s = useStyles()
  const color = useEditorTheme()[head.props.color]
  const { scale } = usePage().page

  const { select, selected } = getSelection({ shapes, editor })

  return (
    <Button
      appearance="subtle"
      onClick={(event) => {
        event.stopPropagation()
        select({ adding: event.shiftKey })
      }}
      className={s.button}
      size="small"
      style={{
        borderColor: selected ? color.pattern : undefined,
        backgroundColor: selected ? color.semi : undefined,
      }}
    >
      {getValue(shapes, { scale })}
    </Button>
  )
})
