import { ToolbarDivider } from '@fluentui/react-components'
import type { ReactElement } from 'react'
import { track, useEditor } from 'tldraw'
import { ToolbarSpace } from '../../ui/toolbar/space'
import { PrintButton } from '../print/button'
import { ScaleForm } from '../scale/form'
import { isScaleShape } from '../scale/shape'
import { ScaleTool } from '../scale/tool'
import { PageToolbarOverview } from './overview'
import { PageToolbarScale } from './scale'

export const PageToolbar = track((): ReactElement => {
  const editor = useEditor()

  const measuring = false
    || editor.getCurrentToolId() === ScaleTool.id
    || editor.getCurrentPageShapes().some(isScaleShape)

  return (
    <>
      <PageToolbarOverview />
      <ToolbarDivider />
      {measuring
        ? <ScaleForm />
        : <PageToolbarScale />}
      <ToolbarSpace />
      <PrintButton />
    </>
  )
})
