import type { TLDefaultColorStyle } from 'tldraw'
import type { Option } from '../../../ui/option'
import type { EquipmentClass } from '../../../util/data/server'
import { t } from '../../../util/intl/t'
import { ATTR_EQUIP_VALUES } from '../equip/value'

export type AttrTypeValue = string

export const ATTR_TYPE_DEFAULT: AttrTypeValue = ''

export type AttrTypeOption = Option & {
  // Some attribute types have a default color. When the type is selected,
  // the color of the shape is changed to that default color.
  // This is a one way change.
  // Changing the shape color does not change the type.
  color: null | TLDefaultColorStyle
}

type AttrTypeWithColor = [string, TLDefaultColorStyle]

/**
 * Maybe in the future it's better to accept a "partial" of "attr type option".
 * However, for now we only need either just a label, or a label and a colour.
 */
function toOption(from: | string | AttrTypeWithColor): AttrTypeOption {
  switch (typeof from) {
    case 'string':
      return { label: from, value: from, color: null }
    default: {
      const [label, color] = from
      return { label, value: label, color }
    }
  }
}

type AttrTypeOptions = Record<
  EquipmentClass,
  AttrTypeOption[] | undefined
>

export const ATTR_TYPE_OPTIONS: AttrTypeOptions = {
  [ATTR_EQUIP_VALUES.DRAIN_PIPE]: ([
    ['D', 'red'],
  ] satisfies Array<AttrTypeWithColor>).map(toOption),
  [ATTR_EQUIP_VALUES.REFRIGERANT_PIPE]: ([
    ['R', 'green'],
  ] satisfies Array<AttrTypeWithColor>).map(toOption),
  [ATTR_EQUIP_VALUES.ROUND_DUCT]: ([
    ['OA', 'green'],
    ['EA', 'red'],
    ['RA', 'orange'],
    ['SA', 'blue'],
    ['KEA', 'red'],
    ['SM', 'violet'],
    ['BEA', 'red'],
    ['EOA', 'red'],
    ['EEA', 'red'],
    ['ESA', 'red'],
    ['ERA', 'red'],
  ] satisfies Array<AttrTypeWithColor>).map(toOption),
  [ATTR_EQUIP_VALUES.SANITARY_PIPE]: [
    '給水管',
    '給湯管',
    '排水管',
    '雑排水管',
    '汚水管',
    '通気管',
    'ガス管',
  ].map(toOption),
  [ATTR_EQUIP_VALUES.VENT_CAP]: [
    { label: t('attr.type.NonFD'), value: 'NonFD', color: null },
    { label: t('attr.type.FD'), value: 'FD', color: null },
  ],
}
