import { Toolbar, makeStyles, mergeClasses, tokens } from '@fluentui/react-components'
import { track, useEditor } from 'tldraw'
import { AnnotToolbar } from '../../annot/toolbar/toolbar'
import { useEditorToolToolbar } from '../../editor/tool/toolbar'
import { PageToolbar } from '../../page/toolbar/toolbar'
import { PredictToolbar } from '../../predict/toolbar'
import { useSetting } from '../../setting/setting'
import type { AppToolbarTab } from '../panel/tab'
import { AppToolbarView } from './view'

const useStyles = makeStyles({
  toolbar: {
    // Prefer to show horizontal scrollbar than line wrapping.
    minWidth: 'max-content',
    gap: tokens.spacingHorizontalXXS,
  },
  hide: {
    display: 'none',
  },
  tab: {
    flex: '1 1 0px',
    display: 'flex',
    alignItems: 'center',
    gap: tokens.spacingHorizontalXXS,
  },
})

export const AppToolbarContent = track((): JSX.Element => {
  const editor = useEditor()
  const toolbar = useSetting().setting.toolbar
  const tool = useEditorToolToolbar({ editor })
  const s = useStyles()

  // We should not mount and unmount tabs,
  // as many of them have important state (e.g., predict's run) that should be preserved.
  // This means a bit worse performance (because most tabs "track" the editor) but it's ok.
  const tabClass = (tab: AppToolbarTab) => mergeClasses(s.tab, toolbar !== tab ? s.hide : '')

  return (
    <Toolbar size="small" className={s.toolbar} {...tool}>
      <div className={tabClass('page')}>
        <PageToolbar />
      </div>
      <div className={tabClass('predict')}>
        <PredictToolbar />
      </div>
      <div className={tabClass('annot')}>
        <AnnotToolbar />
      </div>
      <div className={tabClass('view')}>
        <AppToolbarView />
      </div>
    </Toolbar>
  )
})
