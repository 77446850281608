import { Box } from 'tldraw'
import { createSegmentExtensionShape } from '../../annot/segment/extension/create'
import { createSegmentAttr } from '../../annot/segment/shape'
import { randomAnnotShapeColor } from '../../annot/shape/color'
import type { AttrEquipValue } from '../../attr/field/equip/value'
import type { AIOutputBox } from '../../util/data/server'
import type { PredictFetchCropTransform } from '../fetch/crop'
import type { PredictFetchOutput } from '../fetch/type'

export function parsePredictExtensions(props: {
  extensions: AIOutputBox[]
  transform: PredictFetchCropTransform
  fallbackEquip: AttrEquipValue
  aiPredictionRemaining: number
}): PredictFetchOutput {
  const { transform, extensions, fallbackEquip, aiPredictionRemaining } = props

  const output: PredictFetchOutput = { attrs: {}, shapes: [], aiPredictionRemaining }

  extensions.forEach((box) => {
    const group = crypto.randomUUID()
    const color = randomAnnotShapeColor()

    output.attrs[group] = {
      ...(createSegmentAttr()),
      equip: fallbackEquip,
    }

    const tlBox = new Box(box.x, box.y, box.width, box.height)

    const segment = createSegmentExtensionShape({
      center: transform.vec(tlBox.center),
      mm: null, // AI cannot predict mm yet
      //
      color,
      group,
      id: null,
    })

    output.shapes.push(segment)
  })

  return output
}
