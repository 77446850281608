import * as Sentry from '@sentry/react'
import {
  MutationCache,
  QueryCache,
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query'
import type { ReactElement, ReactNode } from 'react'

function captureException(error: Error) {
  Sentry.captureException(error, { tags: { component: 'ServerError' } })
}

// Heads up: Don't default query error to ApiError.
// Rather than just calling "server",
// we often do more things in both "query fn" and "select".
const client = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: Number.POSITIVE_INFINITY,
      retry: 1,
    },
  },
  // Handle errors at the global level
  // Ref: https://tkdodo.eu/blog/react-query-error-handling#putting-it-all-together
  mutationCache: new MutationCache({
    onError: (err) => {
      captureException(err)
    },
  }),
  queryCache: new QueryCache({
    onError: (err) => {
      captureException(err)
    },
  }),
})

export function ServerProvider(props: { children: ReactNode }): ReactElement {
  const { children } = props

  return (
    <QueryClientProvider client={client}>
      {children}
    </QueryClientProvider>
  )
}
