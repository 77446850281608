import { getStrict } from './primitive'

export type ObjectValues<T> = T[keyof T]

export function groupBy<Item>(props: {
  array: Item[]
  getKey: (item: Item) => string
}): Record<string, Item[]> {
  const { array, getKey } = props

  return array.reduce((prev, item) => {
    const key = getKey(item)

    if (!prev[key])
      prev[key] = []
    getStrict(prev[key]).push(item)

    return prev
  }, {} as Record<string, Item[]>)
}

export function assign<T>(
  prev: T,
  key: number | string,
  value: unknown,
): T {
  return { ...prev, [key]: value }
}

export type SimpleObject = {
  [key: string | number]: unknown
}

export function shallowCompare(
  a: SimpleObject,
  b: SimpleObject,
): boolean {
  const [keysA, keysB] = [Object.keys(a), Object.keys(b)]
  if (keysA.length !== keysB.length)
    return false
  for (const key of keysA) {
    if (a[key] !== b[key])
      return false
  }
  return true
}
