import type { TooltipProps } from '@fluentui/react-components'
import { Tooltip, makeStyles, mergeClasses, shorthands, tokens } from '@fluentui/react-components'
import type { ReactElement } from 'react'
import { Kbd } from '../kbd'

const useStyles = makeStyles({
  content: {
    display: 'flex',
    ...(shorthands.gap(tokens.spacingHorizontalS)),
    alignItems: 'center',
  },
  contentCol: {
    flexDirection: 'column',
  },
  kbd: {
    // Avoid increasing the tooltip height
    ...(shorthands.margin('-2px', 0)),
    backgroundColor: tokens.colorNeutralBackgroundInvertedDisabled,
  },
})

export function ToolbarTooltip(props: {
  relationship: TooltipProps['relationship']
  children: ReactElement
  content: string
  kbd?: string
}): ReactElement {
  const { kbd, content, children, relationship } = props

  const s = useStyles()

  // There's a check in "content element" so we don't need to check for
  // undefined kbd here
  const kbdElement = (
    <Kbd className={s.kbd} appearance="inverted">
      {kbd}
    </Kbd>
  )

  // Many kbd are 1 character, e.g., "1" to move or "Q" to add piece.
  // For other kbds, e.g., "Ctrl/⌘ Z" to undo, we need to break line.
  const contentCol = kbd && kbd.length > 1 ? s.contentCol : undefined

  const contentElement = (
    <div className={mergeClasses(s.content, contentCol)}>
      {content}
      {kbd ? kbdElement : null}
    </div>
  )

  return (
    <Tooltip
      relationship={relationship}
      positioning="below"
      appearance="inverted"
      content={contentElement}
    >
      {children}
    </Tooltip>
  )
}
