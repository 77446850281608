import type { TLShape, TLShapePartial, UnknownRecord } from 'tldraw'
import { isShape } from 'tldraw'
import { z } from 'zod'

const recordSchema = z
  .object({
    id: z.string(),
    typeName: z.string(),
  })
  .passthrough()

export const editorShapeBaseSchema = z.custom<TLShape>((value) => {
  const recordTest = recordSchema.safeParse(value)
  if (!recordTest.success)
    return false
  // This is the best we can do, because UnknownRecord.id is typed in a way
  // that would be very difficult for zod to correctly infer.
  const record = recordTest.data as unknown as UnknownRecord
  return isShape(record)
})

/**
 * "Orphaned" is a weak term for tldraw's shapes that are just created but not
 * updated to one of our shapes (e.g., predict areas, annots).
 *
 * In general, there should not be a permanent orphaned shape as they should be
 * immediately updated to one of our shapes the moment after they are created.
 *
 * @TODO: Make this contract stricter? E.g., there should be a "Shape" type
 * that requires a "meta.type" that all of our shapes should based on.
 */
export function isEditorOrphanedShape(shape: TLShape): boolean {
  return shape.meta.type === undefined
}

/**
 * It's intentional that, unlike tldraw's ShapePartial, our wrapper does not
 * allow optional "meta". We are using "meta" for identification purpose so
 * it must always be defined, especially in creating situations. This is similar
 * to how tldraw's "id" and "type" are always required in partial shapes.
 */
export type EditorShapePartial<
  Shape extends TLShape,
  Meta,
> =
  & TLShapePartial<Shape>
  & { meta: Meta }
