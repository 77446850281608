import { ToolbarButton } from '@fluentui/react-components'
import type { ReactElement } from 'react'
import { locale } from './type'

export function IntlLocaleToggle(): ReactElement {
  return (
    <ToolbarButton
      icon={locale === 'ja' ? '🇺🇸' : '🇯🇵'}
      onClick={() => {
        const next = locale === 'ja' ? 'en' : 'ja'
        window.localStorage.setItem('locale', next)
        window.location.reload()
      }}
    >
      {locale === 'ja' ? 'English' : '日本語'}
    </ToolbarButton>
  )
}
