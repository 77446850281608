import { Tab } from '@fluentui/react-components'
import type { ReactNode } from 'react'

/**
 * Simple wrapper of Fluent's Tab to enforce strict typing on "value".
 */
export function makeTabButton<Value extends string,>() {
  const TabButton = (props: {
    value: Value
    children: ReactNode
  }): JSX.Element => {
    const { value, children } = props

    return <Tab value={value}>{children}</Tab>
  }
  return TabButton
}
