import { z } from 'zod'
import { DefaultService, OpenAPI } from '../../../generated/server'

const string = z.string()

OpenAPI.BASE = string.parse(import.meta.env.VITE_AISK_SERVER_BASE)
OpenAPI.WITH_CREDENTIALS = true

export const server = DefaultService

export * from '../../../generated/server'
