import { makeStyles, tokens } from '@fluentui/react-components'
import { CheckmarkCircle16Regular } from '@fluentui/react-icons'
import { makeHelpAnimation } from './animation.ts'

const useStyles = makeStyles({
  checkmark: {
    stroke: tokens.colorBrandStroke1,
    ...(makeHelpAnimation([{
      states: { off: { opacity: 0 }, on: { opacity: 1 } },
      keyframes: [[0, 'off'], [2, 'off'], [3, 'on'], [8, 'on'], [9, 'off'], [10, 'off']],
    }])),
  },
})

export function HelpCheckOpacity(): JSX.Element {
  const s = useStyles()

  // Technically "piece" is a square check, but overall it's better visually to
  // use a circle check.
  return <CheckmarkCircle16Regular className={s.checkmark} />
}
