import type { ReactElement, ReactNode } from 'react'
import { useMemo } from 'react'
import { TldrawEditor } from 'tldraw'
import 'tldraw/tldraw.css'
import { useAttrs } from '../../attr/state/context'
import { editorComponents } from '../component/components'
import { EditorEffects } from '../effect/effects'
import { editorShapeUtils } from '../shape/utils'
import { EDITOR_CAMERA } from '../util/camera'
import './provider.css'
import { createEditorTools } from './tools'

/**
 * When there are children inside, "tldraw editor" works as a provider.
 * It provides an "editor" instance for the whole app.
 * See "editor/canvas" for the canvas itself.
 */
export function EditorProvider(props: {
  children: ReactNode
}): ReactElement {
  const { children } = props

  // IMPORTANT: In practice, editor tools are created only once,
  // because "setAttrs" is stable. Never create editor tools dynamically.
  const { setAttrs } = useAttrs()
  const tools = useMemo(() => {
    return createEditorTools({ setAttrs })
  }, [setAttrs])

  return (
    <TldrawEditor
      // IMPORTANT: tldraw props must be stable,
      // so always define them outside or in useMemo.
      components={editorComponents}
      tools={tools}
      shapeUtils={editorShapeUtils}
      cameraOptions={EDITOR_CAMERA.options}
    >
      <EditorEffects />
      {children}
    </TldrawEditor>
  )
}
