import { Fragment } from 'react'
import { track, useEditor } from 'tldraw'
import type { AnnotShape } from '../shape/shape'
import { isAnnotShape } from '../shape/shape'
import { AnnotGroup } from './group'

type Group = {
  id: string
  shapes: AnnotShape[]
}

function toGroups(groups: Group[], shape: AnnotShape): Group[] {
  const id = shape.meta.group
  const group = groups.find((group) => {
    return group.shapes.some((shape) => {
      return shape.meta.group === id
    })
  })
  group
    ? group.shapes.push(shape)
    : groups.push({ id, shapes: [shape] })
  return groups
}

export const AnnotSection = track((props: {
  isShape: (shape: AnnotShape) => boolean
}): JSX.Element => {
  const { isShape } = props

  const editor = useEditor()

  const groups = editor.getCurrentPageShapes()
    .filter(isAnnotShape)
    .filter(isShape)
    .reduce(toGroups, [])

  return (
    <Fragment>
      {groups.map(group => (
        <AnnotGroup key={group.id} shapes={group.shapes} />
      ))}
    </Fragment>
  )
})
