import { getStrict } from './primitive'

export function randomChild<T>(arr: readonly T[]): T {
  if (arr.length === 0)
    throw new Error('Array must not be empty.')
  const index = Math.floor(Math.random() * arr.length)
  const item = arr.at(index)
  // This should never happen
  if (item === undefined)
    throw new Error('Item must not be undefined') // Coding error
  return item
}

export function isArraysEqual<T>(array1: T[], array2: T[]): boolean {
  return array1.length === array2.length && array1.every((item, index) => item === array2[index])
}

export function toggleChild<T>(arr: T[], target: T): T[] {
  if (arr.includes(target))
    return arr.filter(item => item !== target)
  else
    return [...arr, target]
}

export function getArraysIntersect<T>(array1: T[], array2: T[]): T[] {
  return array1.filter(element => array2.includes(element))
}

export function getArraysDifferent<T>(source: T[], target: T[]): T[] {
  return source.filter(item => !target.includes(item))
}

export function getArrayUnique<T>(array: T[]): T[] {
  return [...new Set(array)]
}

export function getArraysUnion<T>(arrays: T[][]): T[] {
  return getArrayUnique(arrays.flat())
}

export function isSameChild<T>(array: Array<NonNullable<T>>): boolean {
  const first = array.at(0)

  // "every" would return "false" if there's no item, which could be arguably
  // surprising.
  if (first === undefined)
    throw new Error('Array must have at least one item.')

  return array.every(item => item === first)
}

export function closestChild<Child, Target>(props: {
  array: Child[]
  target: Target
  distance: (child: Child, target: Target) => number
}): Child {
  const { array, distance, target } = props

  let closest: Child | null = null
  let smallest = Number.POSITIVE_INFINITY

  array.forEach((child) => {
    const current = distance(child, target)
    if (current > smallest)
      return
    smallest = current
    closest = child
  })

  if (closest === null)
    throw new Error('Array must have at least one item.')

  return closest
}

export function getHeadStrict<Item>(array: Item[]): Item {
  return getStrict(array.at(0))
}

export function getHeadOrElse<Item>(array: Item[], fallback: Item): Item {
  return array.at(0) ?? fallback
}

export function getSameStrict<Item>(array: (NonNullable<Item>)[]): Item {
  if (!isSameChild(array))
    throw new Error('Array should have the same child')
  return getStrict(array.at(0))
}

export function getOnlyStrict<Item>(array: Item[]): Item {
  const item = array.at(0)
  if (array.length > 1 || item === undefined)
    throw new Error(`Expect array to have exactly 1 item, but have ${array.length}`)
  return item
}

export function getMostItem<Item extends string | number>(array: Item[]): Item {
  const head = array.at(0)
  if (head === undefined)
    throw new Error('Array should have at least 1 item')

  const frequency: Record<Item, number> = {} as Record<Item, number>
  let most: Item = head
  let maxCount = 0

  for (const item of array) {
    if (frequency[item])
      frequency[item]++
    else
      frequency[item] = 1

    if (frequency[item] > maxCount) {
      most = item
      maxCount = frequency[item]
    }
  }

  return most
}

export function getMostOrElse<Item extends string | number>(
  array: Item[],
  fallback: Item,
): Item {
  return array.length > 0 ? getMostItem(array) : fallback
}

export function last<T>(arr: readonly T[]): T | undefined {
  return arr[arr.length - 1]
}
