import type { Edge2d, TLEnterEventHandler, TLExitEventHandler, TLPointerEvent, VecLike } from 'tldraw'
import { LineShapeTool, createShapeId, intersectLineSegmentLineSegment } from 'tldraw'
import { z } from 'zod'
import type { AttrRecord } from '../../attr/state/context'
import { isEditorOrphanedShape } from '../../editor/shape/base'
import { getLineShapeEdgeAbsolute, getLineShapeEdgeRelative, lineShapeSchema } from '../../editor/shape/line'
import type { SetState } from '../../util/react/state'
import { getOnlyStrict } from '../../util/web/array'
import { getStrict } from '../../util/web/primitive'
import { SEGMENT_FLAT_INDICES } from '../segment/flat/create'
import type { SegmentFlatShape } from '../segment/flat/shape'
import { isSegmentFlatShape } from '../segment/flat/shape'
import { randomAnnotShapeColor } from '../shape/color'
import { lockEditorTool, resetEditorToolLock } from '../shape/tool'
import { convertToAnnotCutShape, isAnnotCutShape } from './shape'

const exitToSelect = z.object({
  info: z.object({
    isCreating: z.literal(true),
    onInteractionEnd: z.literal('line'),
    shape: lineShapeSchema,
  }),
  to: z.literal('select'),
})

/**
 * We can already extract a cut segment into a new pipeline,
 * but the result is often worse than not doing so.
 * The better UX is to create 2 new pipelines,
 * for not only the impacted segment,
 * but all the segments in the parent pipeline.
 * In other words, the "cut" tool should cut pipelines, not segments.
 * However, this is costly, so we disable the feature for now.
 *
 * @todo https://h2corporation.atlassian.net/browse/HAIS-742
 */
const FEATURE_CREATE_NEW_PIPELINES = false

function makeSegment(data: {
  original: SegmentFlatShape
  vec: VecLike
  index: string
}): SegmentFlatShape {
  const { original, vec, index } = data

  const points = { ...original.props.points }
  const { x, y } = vec
  points[index] = { ...points[index], x, y }

  const cut: SegmentFlatShape = {
    ...original,
    id: createShapeId(),
    props: { ...original.props, points },
    meta: { ...original.meta },
  }

  // When a segment is cut into 2, the smaller one should be moved to a new
  // group (e.g., new pipeline).
  const cutLen = getLineShapeEdgeRelative(cut).length
  const midLen = getLineShapeEdgeRelative(original).length / 2
  if (FEATURE_CREATE_NEW_PIPELINES && cutLen < midLen) {
    cut.props.color = randomAnnotShapeColor()
    // It's usually better to create the attribute outside, but the "cut"
    // tool, like all tools, only has access to "set attrs", not "attrs". So,
    // we have to create the group ID here, and use it to create the attribute
    // later (still before adding the shapes to the editor though).
    cut.meta.group = crypto.randomUUID()
  }

  return cut
}

type CutChange = {
  adds: SegmentFlatShape[]
  remove: SegmentFlatShape
}

function cutSegment(props: {
  segment: SegmentFlatShape
  cutEdge: Edge2d
}): CutChange | null {
  const { cutEdge, segment: original } = props

  const { start: c1, end: c2 } = cutEdge
  const { start: s1, end: s2 } = getLineShapeEdgeAbsolute(original)

  const vec = intersectLineSegmentLineSegment(c1, c2, s1, s2)
  if (vec === null)
    return null
  vec.sub(original)

  const { start, end } = SEGMENT_FLAT_INDICES
  const change: CutChange = {
    adds: [
      makeSegment({ original, vec, index: end }),
      makeSegment({ original, vec, index: start }),
    ],
    remove: original,
  }

  return change
}

function cloneAttrs(props: {
  prev: AttrRecord
  changes: CutChange[]
}): AttrRecord {
  const { prev, changes } = props

  const next = { ...prev }

  changes.forEach((change) => {
    const original = getStrict(prev[change.remove.meta.group])
    // Technically we only need to create new attribute for the smaller new
    // segment, because the bigger one keeps the original attribute. However,
    // in practice the code below works for both cases so we can skip the check.
    change.adds.forEach((add) => {
      next[add.meta.group] = { ...original }
    })
  })

  return next
}

export const ANNOT_CUT_TOOL_ID = 'cut'

export function createAnnotCutTool(props: {
  setAttrs: SetState<AttrRecord>
}) {
  const { setAttrs } = props

  return class AnnotCutTool extends LineShapeTool {
    static override id = ANNOT_CUT_TOOL_ID

    override onEnter: TLEnterEventHandler = () => {
      // There are 2 main cases when "on enter" is called:
      // 1. The user switches to this tool by themselves,
      // 2. Or they are returned by the "select" tool, after creating a line.
      // Interestingly, we can handle them both in the same way.

      // It's important to lock the tool. It's not only a UX improvement. tldraw
      // works by creating a very short line, then hand it to the "select" tool
      // to continue. Without locking, we have no way to "act" on the line after
      // it's done at the "select" tool.
      lockEditorTool(this.editor)

      // Get the "cut" shape. There should be one at most, because we clean up
      // (i.e., delete the shapes) right after the cutting here.
      const cutLines = this.editor
        .getCurrentPageShapes()
        .filter(isAnnotCutShape)
      if (cutLines.length === 0)
        return
      const cutLine = getOnlyStrict(cutLines)
      const cutEdge = getLineShapeEdgeAbsolute(cutLine)

      // Cut the flat segments!
      const changes = this.editor
        .getCurrentPageShapes()
        .filter(isSegmentFlatShape)
        .map(segment => cutSegment({ segment, cutEdge }))
        .filter((c): c is CutChange => c !== null)

      if (changes.length > 0) {
        // Clone the attributes for the new segments
        setAttrs(prev => cloneAttrs({ prev, changes }))

        // Update the shapes on editor
        this.editor.createShapes(changes.flatMap(c => c.adds))
        this.editor.deleteShapes(changes.map(c => c.remove))
      }

      this.editor.deleteShape(cutLine)
    }

    override onExit: TLExitEventHandler = (info: unknown, to) => {
      // "on exit" is called when the user switches to another tool, either by
      // themselves or by our transition.
      const test = exitToSelect.safeParse({ info, to })
      // Only continue if this is a creating event, from our transition to the
      // "select" tool.
      if (!test.success)
        // This acts as both a clean up and an cancellation.
        return void resetEditorToolLock(this.editor)
      // Update the newly created line to our "cut" shape
      const shape = convertToAnnotCutShape(test.data.info.shape)
      this.editor.updateShape(shape)
      // Update the tool ID for "select" to return to. We need to update the
      // original info object since it is passed between the tools.
      const zInfo = info as z.infer<typeof exitToSelect>['info']
      zInfo.onInteractionEnd = 'cut' as 'line'
    }

    override onPointerUp: TLPointerEvent = () => {
      // Clean up a very short line that get orphaned.
      //
      // "Line shape tool" works by creating a very short line on "pointer down",
      // then hand it to the "select" tool on "pointer move". We convert the line
      // to a "cut" shape at "on exit", so if the user "pointer up" before
      // "pointer move", there will be an orphaned line on the canvas forever.
      const orphans = this.editor.getCurrentPageShapes().filter(isEditorOrphanedShape)
      // It's a coding error if there's no or more than 1 orphaned shape here.
      const orphan = getOnlyStrict(orphans)
      this.editor.deleteShape(orphan)
    }
  }
}
