import { Button, Card, Dialog, DialogActions, DialogBody, DialogContent, DialogSurface, DialogTitle, makeStyles, shorthands, tokens } from '@fluentui/react-components'
import type { ReactElement } from 'react'
import { useState } from 'react'
import { ToolbarLabel } from '../../ui/toolbar/label'
import { t } from '../../util/intl/t'
import { useScaleDisplay } from '../scale/display'
import { ScaleMenu } from '../scale/menu'
import { PaperMenu } from './menu'
import type { PaperName } from './name'

const useStyles = makeStyles({
  fields: {
    display: 'flex',
    ...(shorthands.gap(tokens.spacingHorizontalXXS)),
    alignItems: 'center',
  },
})

export function PaperScale(props: {
  open: boolean
  setOpen: (open: boolean) => void
}): ReactElement {
  const { open, setOpen } = props

  const [paper, setPaper] = useState<PaperName>('A3')
  const [scale, setScale] = useState(100)
  const s = useStyles()
  const { setPaperScale } = useScaleDisplay()

  const content = (
    <DialogContent>
      <p>{t('paper.scale.help-1')}</p>
      <p>{t('paper.scale.help-2')}</p>
      <Card appearance="filled-alternative">
        <div className={s.fields}>
          {/* Mimic Toolbar here as PaperMenu and ScaleMenu are optimised for
          toolbar. */}
          <ToolbarLabel>{t('paper.scale.paper')}</ToolbarLabel>
          <PaperMenu
            name={paper}
            setName={setPaper}
          />
          <ToolbarLabel>{t('paper.scale.scale')}</ToolbarLabel>
          <ScaleMenu
            display={scale}
            setDisplay={setScale}
            adjust={false}
            pending={false}
          />
        </div>
      </Card>
    </DialogContent>
  )

  const action = (
    <DialogActions>
      <Button
        type="button"
        onClick={() => setOpen(false)}
        appearance="secondary"
      >
        {t('paper.scale.cancel')}
      </Button>
      <Button
        type="submit"
        appearance="primary"
      >
        {t('paper.scale.submit')}
      </Button>
    </DialogActions>
  )

  const form
    = (
      <form
        onSubmit={(event) => {
          event.preventDefault()
          setPaperScale({ display: scale, viewport: paper })
          setOpen(false)
        }}
      >
        <DialogBody>
          <DialogTitle>{t('paper.scale.title')}</DialogTitle>
          {content}
          {action}
        </DialogBody>
      </form>
    )

  return (
    <Dialog
      open={open}
      onOpenChange={(_event, data) => void setOpen(data.open)}
    >
      <DialogSurface>
        {form}
      </DialogSurface>
    </Dialog>
  )
}
