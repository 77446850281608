import { Text, makeStyles, mergeClasses, tokens } from '@fluentui/react-components'
import type { ReactNode } from 'react'

const useStyles = makeStyles({
  label: {
    display: 'flex',
    color: tokens.colorNeutralForeground4,
    margin: `0 ${tokens.spacingHorizontalS}`,
  },
  wide: {
    margin: `0 ${tokens.spacingHorizontalM}`,
  },
})

export function ToolbarLabel(props: {
  children: ReactNode
  className?: string
  wide?: boolean
}): JSX.Element {
  const { children, className, wide } = props

  const s = useStyles()

  return (
    // Don't use the Label component as this is outside of a form.
    <Text className={mergeClasses(s.label, className, wide ? s.wide : '')}>
      {children}
    </Text>
  )
}
