import type { INTL_DICT_EN } from './en'

export const INTL_DICT_JA: typeof INTL_DICT_EN = {
  'annot.color.blue': '青い',
  'annot.color.green': '緑',
  'annot.color.lightBlue': '薄青',
  'annot.color.lightRed': '薄赤',
  'annot.color.lightViolet': '薄紫',
  'annot.color.orange': 'オレンジ',
  'annot.color.red': '赤い',
  'annot.color.violet': '紫',
  'annot.color.yellow': '黄色',
  'annot.group.menu.hide-equipments': '他の部材非表示',
  'annot.group.menu.hide': '他のグループ非表示',
  'annot.group.menu.lock': '他のグループロック',
  'annot.panel.action.title': '表示/非表示:',
  'annot.panel.all.show': '全部表示',
  'annot.panel.all.unlock': 'ロック解除',
  'annot.panel.empty': '拾いデータなし。AIモード・手動モードで始めましょう。',
  'annot.panel.selection.hide': '非表示',
  'annot.panel.selection.lock': 'ロック',
  'annot.piece.help-1': '個数物の拾出し',
  'annot.piece.help-2': '個数物をキャンバスにクリックして追加します：',
  'annot.piece.help-3': '左側のパネルに選択中のグループに追加されます。',
  'annot.piece.help-4': '新しいグループを作るには、選択中のグループを解除してからキャンバスにクリックしてください。',
  'annot.piece.help-5': '個数物を編集するには、選択ボタンで選択モードに切り替えてから個数物をクリックします。',
  'annot.segment.fixed': '用紙サイズ',
  'annot.segment.help-1': '配管の拾出し',
  'annot.segment.help-2': '配管をキャンバスにドラッグして追加します：',
  'annot.segment.help-3': '左側のパネルに選択中のグループに追加されます。',
  'annot.segment.help-4': '新しいグループを作るには、選択中のグループを解除してからキャンバスにドラッグしてください。',
  'annot.segment.help-5': '配管を編集するには、選択ボタンで選択モードに切り替えてから配管をクリックします。',
  'annot.segment.label-size': 'フォントサイズ:',
  'annot.segment.label': '配管情報表示：',
  'annot.segment.none': 'なし',
  'annot.segment.scale': '実寸サイズ',
  'annot.segment.shape': 'サイズ',
  'annot.segment.size': '長さ',
  'annot.sync.failed': '結果の保存に失敗しました',
  'annot.toolbar.cut': '分割',
  'annot.toolbar.delete-none': '部材が選択されていないため、削除できません',
  'annot.toolbar.delete': '削除',
  'annot.toolbar.duplicate-none': '複製するものが選択されていません',
  'annot.toolbar.duplicate': '複製',
  'annot.toolbar.extension': '余長',
  'annot.toolbar.extract-none': '分割できません。部材を選択してください。',
  'annot.toolbar.extract': '新グループ',
  'annot.toolbar.head': 'ヘッド',
  'annot.toolbar.piece': '個数物',
  'annot.toolbar.segment': '配管',
  'annot.toolbar.vertical-down': '立下り',
  'annot.toolbar.vertical-up': '立上り',
  'annot.total.piece-unit': '個',
  'annot.total.length-unit': 'm',
  'annot.warning.length-body': '正確な長さを測るために、メニューバーの「ページ」で図面の縮尺を再測定してください',
  'annot.warning.length-title': '縮尺未設定',
  'app.error.forbidden-desc.1': 'あなたはこのページにアクセスする権限がありません。',
  'app.error.forbidden-desc.2': '組織または案件のオーナーに連絡して、招待リンクを送ってもらってください。',
  'app.error.help': 'ページを更新してください.',
  'app.error.limit': '拾い図ダウンロード回数が制限されました。',
  'app.error.title': 'ページの読み込み中にエラーが発生しました',
  'app.error.unauthorized-desc.1': 'あなたはこのページを閲覧する権限がありません。',
  'app.error.unauthorized-desc.2': '続行するには、再度ログインしてください。',
  'app.tab.annotate': '手動モード',
  'app.tab.attr': 'プロパティ',
  'app.tab.help': 'マニュアル',
  'app.tab.list': '図面一覧',
  'app.tab.page': 'ページ',
  'app.tab.predict': 'AIモード',
  'app.tab.result': '拾い表',
  'app.tab.view': '表示',
  'app.theme.dark': 'ダークモード',
  'app.toolbar.edit': 'レビュー',
  'app.toolbar.help': 'ヘルプ',
  'attr.c-area.mixed': '（複数）',
  'attr.c-module.mixed': '（複数）',
  'attr.color.mixed': '（複数）',
  'attr.equip.mixed': '（複数）',
  'attr.field.c-area': '施工箇所',
  'attr.field.c-module': '系統',
  'attr.field.color': '色',
  'attr.field.equip': '部材',
  'attr.field.material-1': '品目',
  'attr.field.material-2': '品名',
  'attr.field.pipe-type': '配管部分',
  'attr.field.thickness': 'サイズ（厚み）',
  'attr.field.type': 'タイプ',
  'attr.insul-thickness.mixed': '(複数)',
  'attr.material-1.mixed': '（複数）',
  'attr.material-2.mixed': '（複数）',
  'attr.panel.empty': '部材を選択すると、ここでプロパティが表示されます。',
  'attr.panel.help': 'グループごとにプロパティが統一されています。一部だけプロパティを変更するには、その部分を新しいグループに分離する必要があります。分離しない場合は、グループ全体のプロパティが変更されます。',
  'attr.pipe-type.main': '主管',
  'attr.pipe-type.mixed': '(複数)',
  'attr.pipe-type.sub': '枝管',
  'attr.shape.box': '（幅 / 高さ / 長さ）',
  'attr.shape.circle': '（直径）',
  'attr.shape.custom': '（サイズ）',
  'attr.shape.electric': '（能力）',
  'attr.shape.insulation': ' (厚み)',
  'attr.shape.label': 'サイズ',
  'attr.shape.mixed': '（複数）',
  'attr.shape.rectangle': '（幅 / 長さ）',
  'attr.shape.refrigerant': '（液管 / ガス管）',
  'attr.type.FD': 'FD付き',
  'attr.type.mixed': '（複数）',
  'attr.type.NonFD': 'FDなし',
  'editor.history.redo-none': 'やり直す操作はありません。',
  'editor.history.redo': 'やり直す',
  'editor.history.undo-none': '取り消す操作はありません。',
  'editor.history.undo': '元に戻す',
  'editor.level.error-exact': 'すでに 100%',
  'editor.level.error-max': '最大拡大しました。',
  'editor.level.error-min': '最小縮小しました。',
  'editor.level.in': '拡大',
  'editor.level.out': '縮小',
  'editor.level.reset': '100%にズーム',
  'editor.navigate.hand': 'パン',
  'editor.navigate.select': '選択',
  'editor.navigate.zoom': 'ズーム',
  'editor.target.error-empty': '拾った部材がないため、ズームできません',
  'editor.target.error-none': '範囲指定されていないため、ズームできません',
  'editor.target.page': '全体',
  'editor.target.selection': '指定範囲',
  'editor.target.shapes': '拾った部材',
  'editor.target.title': 'ズームへ',
  'flag': '🇯🇵',
  'help.panel.hide': 'マニュアル非表示',
  'help.panel.show': 'マニュアル表示',
  'insul.field.material-1': '外装材',
  'insul.field.material-2': '保温材',
  'insul.header.auto-help': '「保温材自動選定」性能とは：資材の属性を全部選択すると、自動的に保温材が必要かどうかを判断し、適切な保温材が選択されます。',
  'insul.header.auto-title': '自動選定',
  'insul.header.enabled': '保温あり',
  'insul.header.title': '保温材',
  'insul.material-1.mixed': '（複数）',
  'insul.material-2.mixed': '（複数）',
  'page.field.category': '工事種目',
  'page.field.construction': '案件名称',
  'page.field.name': 'ページ',
  'page.field.predict-limit': '自動拾い制限',
  'page.field.view-limit': '閲覧制限',
  'page.info.prefix': '工事種目：　',
  'page.limit.remaining': '回残っています',
  'page.print.button': '印刷',
  'page.print.in-progress': '印刷中',
  'paper.scale.cancel': 'キャンセル',
  'paper.scale.help-1': '図面サイズ（A1、 A2…等）を変更すると図面の縮尺は自動的に更新されます。',
  'paper.scale.help-2': '図面の縮尺は次のように手動で計算されます：',
  'paper.scale.paper': '図面サイズ：',
  'paper.scale.scale': '縮尺：',
  'paper.scale.submit': '更新',
  'paper.scale.title': '縮尺について',
  'predict.area.select': '範囲選択…',
  'predict.button.add-water-source': '原水点追加',
  'predict.fetch.action': 'スタート',
  'predict.fetch.crop-samples.invalid': '選択した範囲を再確認してください。選択した範囲はサンプルのサイズよりも大きくする必要があります。',
  'predict.fetch.limit': '自動拾い回数が制限されました。ページを複製して、新しいページでやり直してください。',
  'predict.fetch.no-area': '範囲が選択されていないため、自動拾いできません',
  'predict.fetch.no-model': 'AIモデルを選択されていないため、自動拾いが出来ません',
  'predict.fetch.no-sample': 'サンプルが選択されていないため、自動拾いできません',
  'predict.fetch.pending': '自動拾い中',
  'predict.fetch.required-pdf-vector': '選択範囲には配管の自動検出に必要な情報が足りません。選択が正しかったら、CADソフトから出力された高品質なPDFをアップロードして再度お試しください。',
  'predict.fetch.success': '自動拾いが完成しました',
  'predict.fetch.water-source.invalid': 'Please review all the water sources and ensure they belong to the selected area before starting with AI.',
  'predict.fire-area-help.1': 'AIモードを利用しています。',
  'predict.fire-area-help.2': 'ステップ２: 図面上で拾いたい範囲をマウスで選択してください。',
  'predict.fire-area-help.3': '複数の範囲を選択することが出来ます。各範囲に対して、右側のパネルで部材のプロパティを事前に設定してください。',
  'predict.fire-area-help.4': '「スタート」をクリックしてください。図面の難易度によって処理時間が10秒から2分程かかります。',
  'predict.fire-help.1': 'AIモードを利用しています。',
  'predict.fire-help.2': 'ステップ1: 図面上で拾いたいヘッドのサンプルをマウスでサンプルの範囲を選択してください。サンプル範囲はできるだけ小さくしてください。',
  'predict.fire-help.3': 'サンプルを選択したら、「範囲選択」をクリックし、次のステップに進んでください。',
  'predict.fire-pipe-help.1': 'AIモードを利用しています。',
  'predict.fire-pipe-help.2': 'ヘッドの拾いに漏れがないか確認してください。追加や削除が必要な場合は手動モードで修正してください。',
  'predict.fire-pipe-help.3': '拾ったヘッドのグループを選択し、図面上でクリックすることでヘッド箇所を追加します。',
  'predict.fire-pipe-help.4': '同じグループにヘッドが追加されます。',
  'predict.fire-pipe-help.5': '次は図面上で拾いたい配管の範囲をマウスで選択してください。',
  'predict.fire-pipe-help.6': '複数の範囲を選択することが出来ます。各範囲に対して、右側のパネルで部材のプロパティを事前に設定してください。',
  'predict.fire-pipe-help.7': '「スタート」をクリックしてください。図面の難易度によってAIの処理時間が10秒から2分程かかります。',
  'predict.fire-size-help.1': 'AIモードを利用しています。',
  'predict.fire-size-help.2': '配管の拾いに漏れがないか確認してください。追加や削除が必要な場合は手動モードで修正してください。',
  'predict.fire-size-help.3': '拾った配管のグループを選択し、図面上でマウスをドラッグすることで配管を追加します。',
  'predict.fire-size-help.4': '同じグループに配管が追加されます。',
  'predict.fire-size-help.5': '次は図面上で拾いたい配管の範囲をマウスで選択してください。',
  'predict.fire-size-help.6': '複数の範囲を選択することが出来ます。各範囲に対して、右側のパネルで部材のプロパティを事前に設定してください。',
  'predict.fire-size-help.7': '「スタート」をクリックしてください。図面の難易度によって処理時間が10秒から2分ぐらいかかります。',
  'predict.help.1': 'AIモードを利用しています。',
  'predict.help.2': '最初に拾い場でドラッグして広い範囲を選択してください。',
  'predict.help.3': '複数の範囲を選択することが出来ます。各範囲に対して、右側のパネルで部材のプロパティを事前に設定してください。',
  'predict.help.4': '「スタート」をクリックしてください。図面の難易度によって処理時間が10秒から2分ぐらいかかります。',
  'predict.model.action': 'AIモデル',
  'predict.model.drain': '空調配管系',
  'predict.model.duct': 'ダクト系',
  'predict.model.fire-head': '個数物',
  'predict.model.fire-pipe-diameter': '消火配管口径',
  'predict.model.fire-pipe': '消火配管',
  'predict.model.sanitary': '衛生配管系',
  'predict.pricing-plan.agree': '承認する',
  'predict.pricing-plan.close': '戻る',
  'predict.pricing-plan.contact-owner': '貴社がご契約されているページ数の上限を達成しました。追加のページが必要の場合は貴社のご担当者様にお問い合わせください。',
  'predict.pricing-plan.exchange-page-successful': '追加で1枚ページを消化しました。AI を使用する場合は「スタート」ボタンを押してください。',
  'predict.pricing-plan.purchase-overage': '本ページのAI利用上限を達成しました。本ページでAIをご利用続けられる場合は追加で1枚ページが消化されますが、よろしいでしょうか？',
  'predict.pricing-plan.reached-limit': 'AI利用上限を達成しました。',
  'predict.pricing-plan.subtract-page': '本ページのAI利用上限を達成しました。本ページでAIをご利用続けられる場合は追加で1枚ページが消化されますが、よろしいでしょうか？',
  'predict.pricing-plan.use-next-month': '貴社がご契約されているページ数の上限を達成しました。追加のページが必要の場合は貴社のご担当者様にお問い合わせください。',
  'predict.sample.select': 'サンプルを選択',
  'predict.system.fire': '消火設備',
  'predict.system.mechanical': '空調設備',
  'predict.system.plumbing': '衛生設備',
  'predict.water-source.help-1': '次は原水点を追加してください',
  'predict.water-source.help-2': '図面上に左クリックして原水点を追加します。',
  'predict.water-source.help-3': '原水点を追加したら、「範囲選択」をクリックし、次のステップに進んでください。',
  'scale.error.none': '距離が選定されていないため、縮尺が計算できません',
  'scale.error.number': '距離が入力されていないため、縮尺が計算できません',
  'scale.help.1': '図面の距離を逆算して図面の縮尺を計算しています。',
  'scale.help.2': '最初に図面上でドラッグして距離を選定してください。',
  'scale.help.3': '次にツールバーにあるスペースに距離（mm）を入力してください。',
  'scale.help.4': '最後に「Enter」ボタンを押すと縮尺が計算されます。',
  'scale.idle.title': '縮尺：',
  'scale.measure.length': '距離 （mm）:',
  'scale.menu.adjust': '編集…',
  'scale.menu.measure': '測定…',
  'scale.menu.not-set': '未設定',
  'segment.geo.length': '長さ（m）：',
}
