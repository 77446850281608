import { Fragment, type ReactElement } from 'react'
import type { TLHandle, TLLineShape, TLShape } from 'tldraw'
import { Edge2d, LineShapeUtil as TLLineShapeUtil, Vec, sortByIndex } from 'tldraw'
import { z } from 'zod'
import { SegmentFlatComponent } from '../../annot/segment/flat/component'
import { isSegmentFlatShape } from '../../annot/segment/flat/shape'
import { toSegmentFlatSvg } from '../../annot/segment/flat/svg'
import { getStrict } from '../../util/web/primitive'
import { editorDocumentMetaSchema } from '../util/document'
import { editorShapeBaseSchema } from './base'

export class LineShapeUtil extends TLLineShapeUtil {
  override getHandles(shape: TLLineShape): TLHandle[] {
    // Remove the "create from middle" behaviour. There's no shape that we need
    // this behaviour.
    const prev = super.getHandles(shape)
    const next = prev.filter(handle => handle.type !== 'create')
    return next
  }

  /**
   * This could be "undefined" because it returns "super.component", which is
   * handled by tldraw's original "line shape util" and could be "undefined"
   * from there.
   */
  override component(line: TLLineShape): ReactElement {
    // Important: the super component should not be rendered conditionally,
    // meaning that we can only pass the result to our wrappers.
    const original = super.component(line)

    if (isSegmentFlatShape(line))
      return <SegmentFlatComponent flat={line} original={original} />

    return original
  }

  override indicator(): ReactElement {
    return <Fragment />
  }

  override toSvg(shape: TLLineShape): ReactElement {
    const original = super.toSvg(shape)

    if (isSegmentFlatShape(shape)) {
      const raw = this.editor.getDocumentSettings().meta
      const meta = editorDocumentMetaSchema.parse(raw)
      // This is only called in print,
      // so it would be an error if scale is not ready by then
      const scale = getStrict(meta.scale)
      return toSegmentFlatSvg({ flat: shape, original, scale })
    }

    return original
  }
}

export function isLineShape(shape: TLShape): shape is TLLineShape {
  return shape.type === LineShapeUtil.type
}

export const lineShapeSchema = z.custom<TLLineShape>((data) => {
  const shape = editorShapeBaseSchema.safeParse(data)
  return shape.success && isLineShape(shape.data)
})

/**
 * In tldraw, LineShape is actually more like poly-line as they can have more
 * than 2 handles. However, in our app, most "line" shapes are edges with only
 * 2 handles (see ScaleShape and SegmentShape).
 *
 * This is a convenient utility that is similar to LineShapeUtils' getGeometry
 * but instead of a polyline, we return an edge (from the first 2 handles).
 * For practical reasons, we throw an error if there's more than 2 handles,
 * because it's likely we don't support polyline LineShape in the near future.
 *
 * This returns relative coordinates, as TLLineShape's handles are positioned
 * relative to the shape's "x" and "y".
 */
export function getLineShapeEdgeRelative(shape: TLLineShape): Edge2d {
  const handles = Object.values(shape.props.points).toSorted(sortByIndex)
  const [start, end] = [handles.at(0), handles.at(1)]
  if (handles.length !== 2 || !start || !end)
    throw new Error('Line must have exactly 2 handles.')

  const edge = new Edge2d({
    start: Vec.From(start),
    end: Vec.From(end),
  })
  return edge
}

/**
 * This is similar to getLineShapeEdgeRelative but returns absolute coordinates
 * for the edge's points. It means both handles of the edge are positioned
 * relative to the editor, not to the "shape"'s "x" and "y".
 */
export function getLineShapeEdgeAbsolute(shape: TLLineShape): Edge2d {
  const edge = getLineShapeEdgeRelative(shape)
  edge.start.add(shape)
  edge.end.add(shape)
  return edge
}
