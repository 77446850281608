import { makeStyles, tokens } from '@fluentui/react-components'
import { makeHelpAnimation } from '../../animation'

const useStyles = makeStyles({
  rect: {
    position: 'absolute',
    top: '12px',
    left: '-8px',
    width: '16px',
    height: '16px',
    border: `solid 2px ${tokens.colorBrandForeground1}`,
    transformOrigin: 'top left',
    ...makeHelpAnimation([{
      states: { off: { opacity: 0, fill: 'none' }, on: { opacity: 0.5 } },
      keyframes: [[0, 'on'], [9, 'on'], [10, 'off']],
    }, {
      states: { empty: { transform: 'scale(0)' }, full: { transform: 'scale(1)' } },
      keyframes: [[0, 'empty'], [2, 'empty'], [6, 'full'], [10, 'full']],
    }]),
  },
})

export function HelpFireHeadScale(): JSX.Element {
  const s = useStyles()

  return <div className={s.rect} />
}
