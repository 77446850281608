import type { AttrShapeValue } from '../../attr/field/shape/value'
import { ATTR_SHAPE_DEFAULT } from '../../attr/field/shape/value'
import type { AIOutputLabeledBox } from '../../util/data/server'
import { getMostItem } from '../../util/web/array'

export function parsePredictShape(
  // This could be string[] as well, which is easier for newer models like FIRE.
  // However, this is used inside parsePipeline,
  // which requires a box for others fields anyway.
  boxes: AIOutputLabeledBox[],
): AttrShapeValue {
  const diameters = boxes
    // For historical reason, all sizes are stored in AI's "diameter" field,
    // even though they may not be a diameter (i.e., "circle" shape).
    .map(box => box.label?.equipment_diameter ?? null)
    .filter((diameter): diameter is string => diameter !== null)
    .filter(diameter => diameter !== '')

  if (diameters.length === 0)
    return ATTR_SHAPE_DEFAULT
  const diameter = getMostItem(diameters)

  const numbers = diameter
    // "raw" is a string representation of zero or more numbers,
    // using a "+" to separate.
    // Examples of valid values are "", "150", "100+200", and "10+30+20".
    .split('+')
    .map(text => Number(text))
    .filter(number => Number.isNaN(number) === false)

  // @TODO: This must use the same separator (currently `" "`) as when we parse
  // (non AI) server responses. See "shape/value".
  return numbers.join(' ')
}
