import { makeStyles, shorthands, tokens } from '@fluentui/react-components'
import { useQuery } from '@tanstack/react-query'
import type { ReactElement } from 'react'
import { server } from '../../util/data/server'
import { usePage } from '../state/context'
import { PageListItem } from './item'

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    ...shorthands.gap(tokens.spacingVerticalM),
  },
})

export function PageListPanel(): ReactElement {
  const curPage = usePage().page
  const s = useStyles()

  const pages = useQuery({
    queryKey: ['page-list', curPage.categoryID],
    queryFn: () => server.listPages(curPage.categoryID, 0, 200),
    // This is weird.
    //
    // To save cost, pages' thumbnails expire after 2 minutes,
    // so we need to invalidate the cache when they do.
    // However, doing so means we hit the server again,
    // which potentially costs more.
    //
    // In other words, it looks like we are trying to pay less for storage,
    // but ultimately we are paying more for CPU?
    //
    // @todo: Talk to BE
    staleTime: 1000 * 60 * 2,
  }).data ?? []

  return (
    <div className={s.container}>
      {pages.map((page, index) => (
        <PageListItem
          key={page.id}
          page={page}
          index={index}
          selected={curPage.id === page.id}
        />
      ))}
    </div>
  )
}
