import { Card, Skeleton, SkeletonItem, makeStyles, shorthands, tokens } from '@fluentui/react-components'
import type { ReactElement } from 'react'
import { AppFrame } from './frame'
import { AppToolbarLogo } from './toolbar/logo'

const useStyles = makeStyles({
  toolbar: {
    height: '48px',
  },
  tab: {
    height: '32px',
    flexShrink: 0,
    display: 'flex',
    alignItems: 'center',
    ...(shorthands).gap(tokens.spacingHorizontalS),
  },
  text: {
    width: '100px',
  },
  card: {
    height: '100%',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    ...(shorthands).gap(tokens.spacingVerticalXS),
    height: '100%',
  },
})

export function AppPending(): ReactElement {
  const s = useStyles()

  const card = (
    <Card
      className={s.card}
      appearance="filled"
    />
  )

  const text = (
    <Skeleton className={s.text}>
      <SkeletonItem />
    </Skeleton>
  )

  const side = (
    <div className={s.container}>
      <div className={s.tab}>
        {text}
      </div>
      {card}
    </div>
  )

  const toolbar = (
    <div className={s.container}>
      <div className={s.tab}>
        <AppToolbarLogo />
        {text}
      </div>
      <Card className={s.toolbar} appearance="filled" />
    </div>
  )

  return (
    <AppFrame
      toolbar={toolbar}
      primary={side}
      editor={side}
      secondary={side}
    />
  )
}
