import type { TLShape } from 'tldraw'
import { ATTR_EQUIP_VALUES } from '../../attr/field/equip/value'
import type { AttrValue } from '../../attr/state/context'
import { ATTR_FIELDS_DEFAULT } from '../../attr/state/context'
import { getLineShapeEdgeRelative, isLineShape } from '../../editor/shape/line'
import type { AnnotMetaBase, AnnotShapeBase } from '../shape/base'
import { isAnnotShapeBase } from '../shape/base'
import type { SegmentExtensionPartial, SegmentExtensionShape } from './extension/shape'
import { isSegmentExtensionShape } from './extension/shape'
import type { SegmentFlatPartial, SegmentFlatShape } from './flat/shape'
import { isSegmentFlatShape } from './flat/shape'
import type { SegmentVerticalPartial, SegmentVerticalShape } from './vertical/shape'
import { isSegmentVerticalShape } from './vertical/shape'

export type SegmentMetaBase = AnnotMetaBase & {
  annot: 'segment'
}

type ShapeBase = AnnotShapeBase & {
  meta: SegmentMetaBase
}

export function isSegmentShapeBase(
  shape: TLShape,
): shape is ShapeBase {
  if (!isAnnotShapeBase(shape))
    return false
  const test = shape as ShapeBase
  return test.meta.annot === 'segment'
}

export function createSegmentAttr(): AttrValue {
  return {
    ...(ATTR_FIELDS_DEFAULT),
    equip: ATTR_EQUIP_VALUES.ROUND_DUCT,
  }
}

export type SegmentShape =
  | SegmentFlatShape
  | SegmentVerticalShape
  | SegmentExtensionShape

export type SegmentPartial =
  | SegmentFlatPartial
  | SegmentVerticalPartial
  | SegmentExtensionPartial

export function isSegmentShape(shape: TLShape): shape is SegmentShape {
  return false
    || isSegmentFlatShape(shape)
    || isSegmentVerticalShape(shape)
    || isSegmentExtensionShape(shape)
}

export function getSegmentMmSize(segment: SegmentShape, options: {
  scale: number
}): number {
  const { scale } = options

  switch (segment.meta.segment) {
    case 'extension':
    case 'vertical':
      return segment.meta.mm
    case 'flat':
      if (!isLineShape(segment))
        throw new Error('Flat segment should be a line')
      return scale * getLineShapeEdgeRelative(segment).length
  }
}
