import { useAttrTreeSingle } from '../../../util/attr/tree/data'
import type { BoxShape, Circle, CustomShape, ElectricPower, InsulationThickness, Rectangle, RefrigerantPipeShape, ShapeOptions } from '../../../util/data/server'
import { findTreeNode } from '../../../util/web/tree'
import type { AttrEquipValue } from '../equip/value'
import type { AttrShapeValue } from './value'
import { parseShapeValue } from './value'

function parse(raw: ShapeOptions): AttrShapeValue[] {
  const p = parseShapeValue
  switch (raw.kind) {
    case 'BoxShape':
      return raw.data.map(d => p({ shape: 'box', payload: d as BoxShape }))
    case 'Circle':
      return raw.data.map(d => p({ shape: 'circle', payload: d as Circle }))
    case 'CustomShape':
      return raw.data.map(d => p({ shape: 'custom', payload: d as CustomShape }))
    case 'ElectricPower':
      return raw.data.map(d => p({ shape: 'electric', payload: d as ElectricPower }))
    case 'Rectangle':
      return raw.data.map(d => p({ shape: 'rectangle', payload: d as Rectangle }))
    case 'RefrigerantPipeShape':
      return raw.data.map(d => p({ shape: 'refrigerant', payload: d as RefrigerantPipeShape }))
    case 'InsulationThickness':
      return raw.data.map(d => p({ shape: 'insulation', payload: d as InsulationThickness }))
  }
}

export type AttrShapeOptionsProps = {
  equip: AttrEquipValue
  /**
   * shapeOptions could come from a list of nodes (root, material 1, material
   * 2), where the latter wins over the former.
   */
  nodes: number[]
}

export type AttrShapeSuggestions = {
  type: ShapeOptions['kind']
  unit: ShapeOptions['unit']
  suggestions: AttrShapeValue[]
}

const DEFAULT_OPTIONS: AttrShapeSuggestions = {
  suggestions: [],
  type: 'CustomShape',
  unit: undefined,
}

export function useAttrShapeSuggestions(
  props: AttrShapeOptionsProps,
): AttrShapeSuggestions {
  const { equip, nodes: nodeIDs } = props

  const tree = useAttrTreeSingle({ equip, type: 'material' })
  if (tree === null)
    return DEFAULT_OPTIONS

  const server = nodeIDs.reduce((prev, nodeID): ShapeOptions | null => {
    // It's expected that "[]" is a valid value and will override the previous
    // value. We only fall back in "null" and "undefined" values.
    const curr = findTreeNode(tree.tree, nodeID)?.shapeOptions ?? null
    return curr ?? prev
  }, null as (ShapeOptions | null))

  if (server === null)
    return DEFAULT_OPTIONS

  return {
    suggestions: parse(server),
    type: server.kind,
    unit: server.unit,
  }
}
